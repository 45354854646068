import { createAsyncThunk } from "@reduxjs/toolkit";
import { IFacilityDropdownState, IHeaderState } from "../models/commonInterfaces";
import { URL_FACILITY, URL_PATIENT_ALL_FACILITIES, URL_PATIENT_RELATION, URL_RELATIONSHIP, URL_STATE, URL_VERSION } from "../config/apiUrls";
import { get, getWithoutAuth, post, } from "./apiService";

export const fetchStateData = createAsyncThunk(
  'user/getStateData',
  async (form: IHeaderState, thunkAPI) => {
    try {
      const response = get(URL_STATE, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchRelationshipData = createAsyncThunk(
  'user/getRelationshipData',
  async (form: IHeaderState, thunkAPI) => {
    try {
      const response = get(URL_RELATIONSHIP, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchFacilityDropdown = createAsyncThunk(
  'user/fetchFacilityDropdown',
  async (form: IFacilityDropdownState, thunkAPI) => {
    try {
      const response = post(URL_FACILITY, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const fetchFacilityDropdowns = createAsyncThunk(
  'user/fetchFacilityDropdowns',
  async (_, thunkAPI) => {
    try {
      const response = getWithoutAuth(URL_PATIENT_ALL_FACILITIES);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchRelationshipDatas = createAsyncThunk(
  'user/fetchRelationshipDatas',
  async (_, thunkAPI) => {
    try {
      const response = getWithoutAuth(URL_PATIENT_RELATION);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const fetchWebVersion = createAsyncThunk(
  'user/fetchWebVersion',
  async (_, thunkAPI) => {
    try {
      const response = getWithoutAuth(URL_VERSION);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);