import React from 'react'
import family from '../../assets/images/familyRegImg.svg'
import patient from '../../assets/images/patientRegImg.svg'
import nursing from '../../assets/images/nursingStaffRegImg.svg'

interface RegistrationAsideProps {
  parent: 'family' | 'patient' | 'nursing';
}

const RegistrationAside: React.FC<RegistrationAsideProps> = ({
  parent,
}: RegistrationAsideProps) => {
  let backgroundColorClass = '';

  //To change the background color of the side panel coresponding to current registration page
  switch (parent) {
    case 'family':
      backgroundColorClass = 'bg-[var(--color-ed93481a)]';
      break;
    case 'patient':
      backgroundColorClass = 'bg-[var(--color-0096881a)]';
      break;
    case 'nursing':
      backgroundColorClass = 'bg-[var(--color-2196f31a)]';
      break;
    default:
      backgroundColorClass = 'bg-[var(--color-default)]'; // Set a default background color class
  }
  return (
    
    <>
      <aside className={`w-full max-w-[416px] px-9  rounded-xl  flex flex-col items-center justify-between text-center ${backgroundColorClass}`}>

        <h2 className='text-4xl font-medium tracking-[.05px] mt-9 '>
          {parent === 'family'
            ? 'Family/POA Registration'
            : parent === 'patient'
              ? <>Patient<br />Registration</> 
              : <>Facility Staff<br />Registration</>}
        </h2>
        <img src={parent === 'family' ? family : parent === 'patient' ? patient : nursing} alt="" loading='lazy' decoding='async'/>
      </aside>
    </>
  )
}

export default React.memo(RegistrationAside);