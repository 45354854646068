import { createAsyncThunk } from "@reduxjs/toolkit";
import {   postFormDataWithoutAuth, postWithoutAuth } from "./apiService";
import {   URL_NURSING_REG, URL_NURSING_REG_REMOVE_IMG, URL_NURSING_REG_UPLOAD_IMG } from "../config/apiUrls";
import { IImageInputWithoutToken, IRemoveImageWithoutToken } from "../models/commonInterfaces";
import { INursingStaffRegData } from "../models/nursingStaffRegInterface";



// FUNCTION FOR VALIDATE USERNAME AND PASSWOR
export const addNursingStaffReg = createAsyncThunk(
  'client/addNursingStaffReg',
  async (form: INursingStaffRegData, thunkAPI) => {
    try {
      const response = postWithoutAuth(URL_NURSING_REG, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

  
export const addNursingStaffRegImage = createAsyncThunk(
    'user/addNursingStaffRegImage',
    async (form: IImageInputWithoutToken, thunkAPI) => {
        try {
            const response = postFormDataWithoutAuth(URL_NURSING_REG_UPLOAD_IMG, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
  );
  
  export const removeNursingStaffRegImage = createAsyncThunk(
    'user/removeNursingStaffRegImage',
    async (form: IRemoveImageWithoutToken, thunkAPI) => {
        try {
            const response = postWithoutAuth(URL_NURSING_REG_REMOVE_IMG, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
  );


  





