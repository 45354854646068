import {  createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { setNotification } from "../../components/notification/ToastNotification";
import { message } from "../../utilities/locals/messages";
import { resetPasswordAction } from "../../services/resetPasswordServices";
import { LINK_TIME_EXPIRED, VERIFIED_USER } from "../../config/permissions";


export interface IResetPasswordState {
    loading: 'idle' | 'success' |  'loading' | 'error';
    userRole:number;
}

const initialState: IResetPasswordState = {
    loading: 'idle',
    userRole:0,

}

export const resetPasswordSlice = createSlice({
    name: 'resetPasswordSlice',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {

        builder.addCase(resetPasswordAction.pending, (state) => {
            state.loading = 'loading';
            state.userRole=0;
        });
        builder.addCase(resetPasswordAction.fulfilled, (state, { payload }: any) => {
            state.loading = 'success';
            
            state.userRole= payload.group;
            setNotification(message.CFPCareConnect, message.updationSuccessfully, false, "success", "authenticateUserfailed");

        });
        builder.addCase(resetPasswordAction.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            
            if (payload.response.data.message === VERIFIED_USER) {
            setNotification(message.CFPCareConnect, message.userVerified, false, "failed", "authenticateUserfailed");
            } else if (payload.response.data.message === LINK_TIME_EXPIRED) {
            setNotification(message.CFPCareConnect, message.linkExpired, false, "failed", "authenticateUserfailed");
            } else {
            setNotification(message.CFPCareConnect, message.updationFailed, false, "failed", "authenticateUserfailed");
            }
        });
    }
});

export const ResetPasswordSlices = (state: RootState) => state.resetPasswordData;
export const resetPasswordReducer = resetPasswordSlice.reducer;