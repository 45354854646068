import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import logo from '../../assets/images/careConnectIcon.png'
import { Dialog } from '@headlessui/react'
import ButtonType from '../button/ButtonType'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {  ROUTE_LOGIN, ROUTE_LOGIN_PATIENT } from '../../config/routeConfig'



const WebNavbar = () => {
  const navigate = useNavigate()

  const location = useLocation();


  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [navScrolled, setNavScrolled] = useState(false);

  //For checking if the page is scrolled above 112px or not
  useEffect(() => {
    const handleScroll = () => {
      // const isScrolled = window.scrollY > 112;
      // setNavScrolled(isScrolled);
      setNavScrolled(false);
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);

    }
  }, [])

  return (
    <>
      <header className={`${navScrolled ? 'sticky shadow-sm ' : 'absolute'} inset-x-0 top-0 z-50 bg-[var(--color-white)]`}>


        <nav className="flex items-center justify-center w-100 py-[37px] px-[37px] lg:px-[64px] " aria-label="Global">
          <div className='flex items-center justify-between w-full max-w-[1400px]'>
            <div className='flex items-center'>
              <span className="sr-only">CFP Care Connect</span>
              <Link to={"/ "} className='flex items-center gap-4'>
                <img
                  className="h-[57px] w-auto"
                  src={logo}
                  alt=""
                  loading='lazy'
                  decoding='async'
                />
                <h1 className='text-[var(--color-040E25)] text-xl font-medium tracking-[0.05px] '>CFP Care Connect</h1>

              </Link >
            </div>

            <div className="flex lg:hidden">
              <button
                type="button"
                className=" text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <MenuRoundedIcon />
              </button>
            </div>
            <div className="hidden lg:flex gap-6 text-[15px] font-normal tracking-[0.05px]  items-center justify-center">


              {location.pathname === '/' && <div className='flex ps-4 gap-6'>
                <ButtonType
                  type='login'
                  className=' text-[var(--color-212121)] border border-[var(--color-BDBDBD)] '
                  buttonLabel="User Login"
                  onClick={() => navigate(ROUTE_LOGIN)}
                />
                <ButtonType
                  type='login'
                  className=' text-[var(--color-FFF)] border border-[var(--color-253179)] bg-[var(--color-253179)]'
                  buttonLabel="Patient Login"
                  onClick={() => navigate(ROUTE_LOGIN_PATIENT)}
                />
              </div>}
            </div>
          </div>
        </nav>

        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50 " />
          <Dialog.Panel className="fixed border-s-[.9px] inset-y-0 right-0 z-50 w-full bg-[var(--color-white)] p-6 sm:max-w-sm">
            <div className="flex items-center justify-between py-[28px]">
              <div className="">
                <span className="sr-only">CFP Care Connect</span>
                <img
                  className="h-8 w-auto"
                  src={logo}
                  alt=""
                  loading='lazy'
                  decoding='async'
                />
              </div>
              <button
                type="button"
                className=" text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <CloseRoundedIcon />
              </button>
            </div>

            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-white">
                <div className="space-y-2 py-6 ">

                </div>
                {location.pathname === '/' && <div className="py-6 flex flex-col gap-6 w-fit">
                  <ButtonType
                    type='login'
                    className=' text-[var(--color-212121)] border border-[var(--color-BDBDBD)] '
                    buttonLabel="User Login"
                    onClick={() => navigate(ROUTE_LOGIN)}
                  />
                  <ButtonType
                    type='login'
                    className=' text-[var(--color-FFF)] border border-[var(--color-253179)] bg-[var(--color-253179)]'
                    buttonLabel="Patient Login"
                    onClick={() => navigate(ROUTE_LOGIN_PATIENT)}
                  />
                </div>}

              </div>
            </div>

          </Dialog.Panel>
        </Dialog>
      </header>
    </>
  )
}

export default WebNavbar