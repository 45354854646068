import React, { memo, useEffect, useState } from 'react'
import ButtonType from '../../components/button/ButtonType'
import plusIcon from '../../assets/images/plusIcon.svg'
import Table from '../../components/table'
import { facilityListHeader } from '../../components/table/tableHeaders'
import { Box, Modal } from '@mui/material'
import TextField from '../../components/inputFeild/TextFeild'
import PhoneInputFeild from '../../components/inputFeild/PhoneInputFeild'
import SelectField from '../../components/inputFeild/SelectField'
import { useAppDispatch, useAppSelector } from '../../helpers/hook'
import { loginPageSlices } from '../loginPage/loginPageSlice'
import { addFacilityData, addFacilityImageInput, editFacilityData, fetchFacilityList, removeFacilityImageInput, updateFacilityData, updateFacilityStatus } from '../../services/facilityListServices'
import { setNotification } from '../../components/notification/ToastNotification'
import { message } from '../../utilities/locals/messages'
import ImageInput from '../../components/inputFeild/ImageInput'
import { filterDigitsOnly } from '../../helpers/validators'
import { CommonSlices } from '../common/commonSlice'
import { IFacilityData, IFacilityState, IFacilityStatus } from '../../models/facilityListInterfaces'
import { DEFAULT_COUNTRY, DEFAULT_COUNTRY_US, LOGO_UPLOAD_FACILITY, LOGO_UPLOAD_LOGO, P_ACT_ADD_EDIT, ROLE_FACILITY, STATUE_ACTIVE, USER_ROLE_SUPERADMIN, useHasActionPermission, useHasViewPermission } from '../../config/permissions'
import { FacilityListSlices } from './facilityListSlice';
import ConfirmationMessage from '../../components/notification/ConfirmationMessage';
import ModalHeader from '../../components/modal/ModalHeader';
import { IFacilityDropdownState, IImageInput, IRemoveImage } from '../../models/commonInterfaces'
import { fetchFacilityDropdown } from '../../services/commonServices'
import Paginations from '../../components/Pagination'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: '12px',
    overflow: 'hidden',
    boxShadow: 24,
};

const FacilityList = () => {
    const dispatch = useAppDispatch();
    const { token, unid, client, loginUserRole, logedUsername } = useAppSelector(loginPageSlices);
    const { stateData } = useAppSelector(CommonSlices);
    const { loading, paginationTotalCount, facilityListData, facilityData, imageId, uploadedImg } = useAppSelector(FacilityListSlices);
    //Paginaton
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25;
    //PERMISSION
    const checkViewPermission = useHasViewPermission(ROLE_FACILITY);


    const [sortField, setSortField] = useState<string>("full_name");
    const [openCreateFacility, setOpenCreateFacility] = useState<boolean>(false);
    const [viewOnly, setviewOnly] = useState<boolean>(false);
    const [userId, setUserId] = useState<number>(0);
    const [showStatusConfirmation, setShowStatusConfirmation] = useState<boolean>(false);
    const [storeStatus, setStoreStatus] = useState<boolean>(false);
    const [storeStatusData, setStoreStatusData] = useState<any>();

    const [addFacility, setAddFacility] = useState<boolean>(true);
    const [facilityName, setFacilityName] = useState<string>('');
    const [profileImage, setProfileImage] = useState<string>('');
    const [imageProId, setImageProId] = useState<number | null>(null);
    const [streetOne, setStreetOne] = useState<string>('');
    const [streetTwo, setStreetTwo] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [state, setState] = useState<string>('0');
    const [zipCode, setZipCode] = useState<string>('');
    const [mobile, setMobile] = useState<string>('');
    const [alternativeMobile, setAlternativeMobile] = useState<string>('');
    const [note, setNote] = useState<string>('');

    //Filiter status
    const [filterStatus, setFilterStatus] = useState<string>("all");




    //initial fetching the data to display item on table
    useEffect(() => {
        if (checkViewPermission) {
            const req: IFacilityState = {
                unid, token, client, page: currentPage
            };
            dispatch(fetchFacilityList(req));
        }
    }, []);

    //use to reload the table data when dispatch oru other functionality is applied
    useEffect(() => {
        if (loading === 'success' && checkViewPermission) {
            const req: IFacilityState = {
                unid, token, client, page: currentPage
            };
            dispatch(fetchFacilityList(req));
            //reload multiselect facility after adding singal facility
            const reqs: IFacilityDropdownState = {
                unid, token, client: client, group: loginUserRole, user: logedUsername
            };
            if ((loginUserRole !== USER_ROLE_SUPERADMIN)) {
                dispatch(fetchFacilityDropdown(reqs));
            }
            if (openCreateFacility) {
                handleFacilityReset();
            }
        }
    }, [loading])


    //to handle edit facility 
    useEffect(() => {
        if (facilityData.length > 0) {
            const userDetails = facilityData[0]
            setAddFacility(false);
            setFacilityName(userDetails.name);
            setStreetOne(userDetails.street1);
            setStreetTwo(userDetails.street2);
            setCity(userDetails.city);
            setState(userDetails.state.toString());
            setZipCode(userDetails.zip_code);
            setMobile(userDetails.mobile);
            setAlternativeMobile(userDetails.alternate_phone);
            setNote(userDetails.notes);
            setImageProId(userDetails.logo);
            setProfileImage(userDetails.logo_url)
        }
    }, [facilityData])


    useEffect(() => {
        setImageProId(imageId);
        setProfileImage(uploadedImg);
    }, [imageId, uploadedImg])

    //save button function on modal
    const handleSaveFacilityData = (e: any) => {
        e.preventDefault();
        if (facilityName.length > 0 && streetOne.length > 0 && city.length > 0 && (state !== '0' || state !== null) && zipCode.length > 0) {
            if (mobile.length < 2 || mobile.length > 6) {

                const req: IFacilityData = {
                    unid,
                    token,
                    id: userId,
                    client: client,
                    name: facilityName,
                    mobile: mobile,
                    alternate_phone: alternativeMobile,
                    notes: note,
                    logo: imageProId,
                    location: {
                        street1: streetOne,
                        street2: streetTwo,
                        city: city,
                        state: Number(state),
                        country: DEFAULT_COUNTRY,
                        zip_code: zipCode,
                    },
                }
                if (addFacility) {
                    //for add facility
                    dispatch(addFacilityData(req));
                } else {
                    //for update facility
                    dispatch(updateFacilityData(req));
                }
            } else {
                setNotification(message.CFPCareConnect, message.invalidMobile, false, 'failed', 'handleSaveClientData');
            }
        } else {
            setNotification(message.CFPCareConnect, message.fillOutAllMandatoryFields, false, 'failed', 'handleSaveFacilityData');
        }

    }

    //for reset all value in modal
    const handleFacilityReset = () => {
        setFacilityName('');
        setStreetOne('');
        setStreetTwo('');
        setCity('');
        setState('0');
        setZipCode('');
        setMobile('');
        setAlternativeMobile('');
        setProfileImage('');
        setOpenCreateFacility(!openCreateFacility);
        setviewOnly(false);
        setAddFacility(true);
        setUserId(0);
        setNote("");
    }

    //edit click on table
    const handleClickEdit = (data: any) => {
        const client = data.id;
        setUserId(client);
        const req: IFacilityState = {
            unid, token, client
        };
        dispatch(editFacilityData(req));
        setOpenCreateFacility(true);
    }

    //View click on table
    const handleClickView = (data: any) => {
        setviewOnly(true);
        handleClickEdit(data);
    }

    // function handles Deactivate and Activation of client 
    const handleConfiramation = () => {
        const id = storeStatusData.id
        const req: IFacilityStatus = {
            unid, token, id, action: storeStatusData.active_status === STATUE_ACTIVE ? 1 : 2
        };
        dispatch(updateFacilityStatus(req));
        setShowStatusConfirmation(false);
    }

    //confirmation popup 
    const handleClickStatus = (data: any) => {
        setShowStatusConfirmation(true);
        setStoreStatusData(data);
        setStoreStatus(data.active_status === STATUE_ACTIVE)
    }

    //for add profile image
    const handleAddImage = (selectedFile: File) => {
        const req: IImageInput = {
            token, unid, image: selectedFile, type: LOGO_UPLOAD_FACILITY, image_type: LOGO_UPLOAD_LOGO
        }
        dispatch(addFacilityImageInput(req));
    }
    //remove profile image
    const handleRemoveImage = () => {
        if (imageProId && imageProId !== null) {

            const req: IRemoveImage = {
                token, unid, fid: imageProId
            }
            dispatch(removeFacilityImageInput(req));
        }
    }

    //To handle filiter
    const handleFacilityList = (value: any, field: string) => {
        if (checkViewPermission) {
            const req: IFacilityState = {
                unid,
                token,
                client,
                page: (field === 'pagination') ? value : currentPage,
                status_filter: (field === 'status_filter') ? value : filterStatus,
                sort: (field === 'sort_facility') ? value : sortField,
            };
            dispatch(fetchFacilityList(req));
        }
    }

    
    // pagination    
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        if (checkViewPermission) {
            handleFacilityList(page, 'pagination');
        }
    };

    return (
        <div className='w-full'>
            <div className="flex justify-between items-center  pt-4 pb-[45px] pl-12 pr-8">
                <div>
                    <h1 className='text-2xl text-[var(--color-212121)] '>All Facilities</h1>
                </div>
                <div className="flex gap-4 items-center">
                    <div className="flex items-center gap-3" >
                        <div className=' tracking-[0.05px] text-[var(--color-424242)]'>Status&nbsp;Filter: </div>
                        <div>
                            <SelectField
                                className=' select-message py-[9px] rounded border-[var(--color-BDBDBD)]  min-w-[138px]'
                                value={filterStatus}
                                onChange={(e) => {
                                    setFilterStatus(e.target.value);
                                    handleFacilityList(e.target.value, 'status_filter');
                                }}
                                options={[{ name: 'All', id: 'all' }, { name: 'Active', id: 'true' }, { name: 'Inactive', id: 'false' }]}
                            />
                        </div>
                    </div>
                    <div>
                        {useHasActionPermission(ROLE_FACILITY, P_ACT_ADD_EDIT) && <ButtonType type='iconLeft' buttonLabel='Add Facility' icon={plusIcon} onClick={() => handleFacilityReset()} />}
                    </div>
                </div>

            </div>
            <div className='pl-12 pr-8 '>
                <Table
                    parentPage={ROLE_FACILITY}
                    setPermission={true}
                    titles={facilityListHeader}
                    data={facilityListData}
                    currentPage={currentPage}
                    numRowsPerPage={itemsPerPage}
                    sortedFieldName={sortField}
                    handleSort={(sortField: string) => {
                        setSortField(sortField);
                        handleFacilityList(sortField, 'sort_facility');
                    }}
                    onClickEdit={(id: any) => handleClickEdit(id)}
                    onClickView={(id: any) => handleClickView(id)}
                    onClickStatus={(obj: any) => handleClickStatus(obj)}
                    className={Math.ceil(paginationTotalCount / itemsPerPage) > 1 ? '' : ' mb-28 '}
                />
            </div>
            <Paginations
                totalItems={paginationTotalCount}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
            />

            <Modal
                open={openCreateFacility}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='focus:outline-none w-full max-w-[936px]'>

                    <ModalHeader title={addFacility ? 'Add Facility' : viewOnly ? 'Facility' : 'Update Facility'} onClick={() => handleFacilityReset()} />

                    <form className='max-h-[calc(100vh-200px)] custom-scrollBar' onSubmit={(e) => handleSaveFacilityData(e)} >
                        <div className="px-9">


                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <div className="flex flex-col gap-9 w-full">
                                    <TextField
                                        label=' Facility Name'
                                        required={true}
                                        value={facilityName}
                                        disabled={viewOnly}
                                        onChange={(e) => setFacilityName(e.target.value)}
                                        placeholder=' Facility Name' />
                                    <TextField
                                        label=' Street 1'
                                        required={true}
                                        value={streetOne}
                                        disabled={viewOnly}
                                        onChange={e => setStreetOne(e.target.value)}
                                        placeholder=' Street 1' />
                                </div>

                                <ImageInput label='Logo' squareShape={true} profileImage={profileImage}
                                    handleAddImage={handleAddImage}
                                    handleRemoveImage={handleRemoveImage}
                                />

                            </div>
                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <TextField
                                    label=' Street 2'
                                    value={streetTwo}
                                    disabled={viewOnly}
                                    onChange={e => setStreetTwo(e.target.value)}
                                    placeholder=' Street 2' />
                                <TextField
                                    label=' City'
                                    required={true}
                                    value={city}
                                    disabled={viewOnly}
                                    onChange={e => setCity(e.target.value)}
                                    placeholder=' City' />

                            </div>
                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <SelectField
                                    search={true}
                                    // className={`${facility === "0" && 'text-[var(--color-9E9E9E)] !important'}`}
                                    onChange={(e) => setState(e.target.value)}
                                    label='State'
                                    value={state}
                                    disabled={viewOnly}
                                    required={true}
                                    placeHolder={true}
                                    options={stateData}
                                />

                                <TextField
                                    label='ZIP Code'
                                    type='text'
                                    required={true}
                                    value={zipCode}
                                    disabled={viewOnly}
                                    onChange={e => setZipCode(filterDigitsOnly(e.target.value))}
                                    placeholder='ZIP Code'
                                />

                            </div>




                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">

                                <PhoneInputFeild
                                    inputProps={{
                                        name: `mobile`,
                                        autoFocus: false,
                                        disabled: viewOnly,
                                    }}
                                    value={mobile}
                                    defaultCountry={DEFAULT_COUNTRY_US}
                                    label='Mobile'
                                    placeholder='Mobile Number'
                                    onChange={phone => setMobile(phone)} />
                                <div className="w-full"></div>


                            </div>

                            <div className="flex gap-8 w-full my-9 flex-wrap md:flex-nowrap">
                                <label className='flex flex-col gap-1 w-full'>

                                    <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px]'>
                                        Notes
                                    </span>
                                    <textarea value={note}
                                        disabled={viewOnly} onChange={(e) => setNote(e.target.value)} name="" id="" cols={30} rows={3} className='common-input focus:outline-none  resize-none' />
                                </label>
                                <div className="w-full"></div>
                            </div>


                            {!viewOnly && <>
                                <hr className='border-[var(--color-E0E0E0)] ' />
                                <div className="flex gap-4 justify-end items-end my-9">
                                    <ButtonType
                                        type='webCardbtn'
                                        buttonLabel='Cancel'
                                        onClick={() => handleFacilityReset()}
                                        className='px-12 !important bg-[var(--color-white)] !important imp-text-424242  hover:bg-[var(--color-0000000a)]' />
                                    <ButtonType
                                        type='webCardbtn'
                                        buttonLabel='Save'
                                        buttonType='submit'
                                        className='px-12 bg-[var(--color-0048D4)]' />
                                </div></>}
                        </div>
                    </form>
                </Box>
            </Modal>

            <ConfirmationMessage
                title={message.CFPCareConnect}
                description={`Are you sure you want to ${storeStatus ? 'deactivate' : 'activate'} this facility?`}
                open={showStatusConfirmation}
                textType={false}
                type={storeStatus ? 'danger' : 'success'}
                buttonTwoOnClick={() => setShowStatusConfirmation(false)}
                buttonOneOnClick={() => handleConfiramation()}
                buttonLabelOne={storeStatus ? 'Deactivate' : 'Activate'}
                buttonLabelTwo='Cancel'
            />
        </div>
    )
}

export default memo(FacilityList)