import { createAsyncThunk } from "@reduxjs/toolkit";
import { get,  post,  postFormData,  put } from "./apiService";
import { URL_ADD_CLIENT, URL_CLIENT_LIST, URL_EDIT_CLIENT, URL_REMOVE_IMAGE, URL_RESENT_MAIL, URL_STATUS_CLIENT, URL_UPDATE_CLIENT, URL_UPLOAD_IMAGE } from "../config/apiUrls";
import { IClientData, IClientList, IClientStatus } from "../models/clientListInterfaces";
import { IImageInput, IRemoveImage, IResentEmail } from "../models/commonInterfaces";


export const fetchClientList = createAsyncThunk(
  'user/fetchClientList',
  async ({ token, unid,page ,sort}: IClientList, thunkAPI) => {
    const headerData = { token, unid }
    try {
      let url = URL_CLIENT_LIST;
      if (page !== 0) {
        url = url + '?page=' + page
      }
      if (sort && sort !== '') {
          url = url + '&sort=' + sort
      }
      const response = get(url, headerData);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addClientData = createAsyncThunk(
  'user/addClientData',
  async (form: IClientData, thunkAPI) => {    
    try {
      const response = post(URL_ADD_CLIENT, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editClientData = createAsyncThunk(
  'user/editClientData',
  async (form: IClientList, thunkAPI) => {
    try {
      const url = URL_EDIT_CLIENT + form.uuid;
      const response = get(url, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateClientData = createAsyncThunk(
  'user/updateClientData',
  async (form: IClientList, thunkAPI) => {
    try {
      const url = URL_UPDATE_CLIENT + form.uuid;
      const response = put(url, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateStatus = createAsyncThunk(
  'user/updateClientStatus',
  async (form: IClientStatus, thunkAPI) => {
    try {
      const response = put(URL_STATUS_CLIENT, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addImageInput = createAsyncThunk(
  'user/addClientImageInput',
  async (form: IImageInput, thunkAPI) => {
      try {
          const response = postFormData(URL_UPLOAD_IMAGE, form);
          return (await response).data;
      } catch (error) {
          return thunkAPI.rejectWithValue(error);
      }
  }
);

export const addImageProfileInput = createAsyncThunk(
  'user/addImageProfileInput',
  async (form: IImageInput, thunkAPI) => {
      try {
          const response = postFormData(URL_UPLOAD_IMAGE, form);
          return (await response).data;
      } catch (error) {
          return thunkAPI.rejectWithValue(error);
      }
  }
);

export const removeImageInput = createAsyncThunk(
  'user/removeClientImageInput',
  async (form: IRemoveImage, thunkAPI) => {
      try {
          const response = post(URL_REMOVE_IMAGE, form);
          return (await response).data;
      } catch (error) {
          return thunkAPI.rejectWithValue(error);
      }
  }
);

export const removeImageProfileInput = createAsyncThunk(
  'user/removeImageProfileInput',
  async (form: IRemoveImage, thunkAPI) => {
      try {
          const response = post(URL_REMOVE_IMAGE, form);
          return (await response).data;
      } catch (error) {
          return thunkAPI.rejectWithValue(error);
      }
  }
);

  
// to resend verification mail  
export const resentVerificationClientListMail = createAsyncThunk(
  'user/resentVerificationClientListMail',
  async (form: IResentEmail, thunkAPI) => {
    try {
      const response = post(URL_RESENT_MAIL, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

      
// export const addClientAdminSignInput = createAsyncThunk(
//   'user/addClientAdminSignInput',
//   async (form: IImageInput, thunkAPI) => {
//       try {
//           const response = postFormData(URL_UPLOAD_IMAGE, form);
//           return (await response).data;
//       } catch (error) {
//           return thunkAPI.rejectWithValue(error);
//       }
//   }
// );

  
// export const removeClientAdminSignInput = createAsyncThunk(
//   'user/removeClientAdminSignInput',
//   async (form: IRemoveImage, thunkAPI) => {
//       try {
//           const response = post(URL_REMOVE_IMAGE, form);
//           return (await response).data;
//       } catch (error) {
//           return thunkAPI.rejectWithValue(error);
//       }
//   }
// );