import React from 'react';

interface TextFieldProps {
    type?: string;
    className?: string;
    label?: string;
    autoComplete?: string;
    fieldType?: string;
    placeholder?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    name?: string;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    required?: boolean;
    id?: string;
    min?: string;
    max?: string;
    maxLength?: number;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
    ref?: React.Ref<HTMLInputElement>;
}

const TextField: React.FC<TextFieldProps> = React.memo((props) => {

    return <label htmlFor={props.id} className='flex flex-col gap-1 w-full'>

        {/* To show label */}
        {props.label && props.label?.length > 0 &&
            <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px]'>
                {props.label} {props.required && <span className='text-[var(--color-red)]'>*</span>}
            </span>}
        <input
            type={props.type}
            className={`common-input focus:outline-none  ${props.className}`}
            disabled={props.disabled}
            value={props.value}
            name={props.name}
            onKeyDown={props.onKeyDown}
            required={props.required}
            id={props.id}
            min={props.min}
            max={props.max}
            maxLength={props.maxLength}
            onChange={props.onChange}
            onClick={props.onClick}
            placeholder={props.placeholder}
            autoComplete={props.autoComplete}
            ref={props.ref}
        />
    </label>
});

export default TextField;
