import React, { useMemo } from 'react'
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import success from '../../assets/images/successNotificationIcon.svg'


const style = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-52%, -50%)',
};

interface ConfirmationProps {
    type: 'warning' | 'danger'|'success';
    className?: string;
    title: string;
    description: string;
    textType: boolean;
    open: boolean;
    buttonLabelOne?: string;
    buttonLabelTwo?: string;
    buttonOneOnClick?: () => void;
    buttonTwoOnClick?: () => void;
}

const ConfirmationMessage: React.FC<ConfirmationProps> = React.memo((props) => {
   

    const message = useMemo(() => {
        let content;
        if (props.textType) {
            const firstIndex = props.description.indexOf('<<$$>>');
            const secondIndex = props.description.indexOf('<<$$>>', firstIndex + 1);

            // Check if both "<<$$>>" occurrences are found
            if (firstIndex !== -1 && secondIndex !== -1) {
                // Extract the three parts
                const a = props.description.substring(0, firstIndex).trim();
                const b = props.description.substring(firstIndex + 6, secondIndex).trim(); // 6 is the length of "<<$$>>"
                const c = props.description.substring(secondIndex + 6).trim();

                return content = (<p className='text-[var(--color-757575)] text-sm'>{a} <span className='text-[var(--color-424242)] font-medium'>{b} </span>{c}</p>);
            }

        } else {
            content = <p className='text-[var(--color-757575)] text-sm'>{props.description}</p>;
        }
        return <>{content}</>;
    }, [props.description, props.textType]);


    return (
        <Modal
            open={props.open}
            // onClose={props.buttonTwoOnClick}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className='focus:outline-none'>
                <div className='flex flex-col gap-5 p-6  bg-[var(--color-FFF)] shadow-md rounded-lg min-w-[488px]  max-w-[596px]'>
                    <div className='flex gap-3 items-start'>
                    <img src={success} alt="icon" />
                    <div>
                        <h1 className='text-[var(--color-212121)] text-sm mb-3'>{props.title}</h1>
                        {message}
                    </div></div>
                    <div className='w-full flex items-center justify-end gap-4 text-sm'>

                        <button className='rounded-lg px-3 py-2 border border-[var(--color-EEE)]  hover:bg-[var(--color-0000000a)]' onClick={props.buttonTwoOnClick}>{props.buttonLabelTwo}</button>

                         <button 
                         className={`${props.type==='success'? 'border-[var(--color-success)] text-[var(--color-success)] hover:bg-[var(--color-success)] hover:text-[var(--color-white)]':props.type==='warning'? 
                         'border-[var(--color-253179)] text-[var(--color-253179)] hover:bg-[var(--color-253179)] hover:text-[var(--color-white)]':'border-[var(--color-danger)] text-[var(--color-danger)] hover:bg-[var(--color-danger)] hover:text-[var(--color-white)]'} rounded-lg px-3 py-2 border  min-w-[72px]`} 
                         onClick={props.buttonOneOnClick} >
                            {props.buttonLabelOne}
                            </button>

                    </div>
                </div>
            </Box>
        </Modal>
    )
});

export default ConfirmationMessage