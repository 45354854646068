import React, { useCallback, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import flagIcon from '../../assets/images/demoFlag.svg'

interface PhoneInputFieldProps {
  value: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  onChange: (phone: string) => void;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  disabled?: boolean;
  className?: string;
  defaultCountry?: string;
}

const PhoneInputField: React.FC<PhoneInputFieldProps> = (props) => {
  const [showFlag, setshowFlag] = useState(true);

  const handleChange = useCallback((phone: string) => {
    props.onChange(phone);
    setshowFlag(false);
  }, [props]);

  return (
    <label htmlFor="" className='flex flex-col gap-1 w-full' >
      {props.label && props.label?.length > 0 &&
        <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px]'>
          {props.label} {props.required && <span className='text-[var(--color-red)]'>*</span>}
        </span>}
      <div className="relative">
        {(props.value?.length <= 0 && showFlag && (props.defaultCountry?props.defaultCountry?.length === 0:true)) && <img src={flagIcon} alt="" loading='lazy' decoding='async' className=' absolute z-[1] top-[13px] left-[13px] hover:hidden ' />
        }
        <PhoneInput
          inputProps={props.inputProps}
          value={props.value}
          country={props.defaultCountry}
          // onChange={props.onChange}
          onChange={(phone) => handleChange(phone)}
          inputClass={`focus:outline-none phone-input  ${props.className}`}
          placeholder={props.placeholder}
          buttonClass="phone-input-flag"
        />
      </div>
    </label>
  )
}

export default React.memo(PhoneInputField);