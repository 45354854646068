import { createAsyncThunk } from "@reduxjs/toolkit";
import { IProviderData, IProviderState, IProviderStatus } from "../models/providerListInterfaces";
import { get, post, postFormData, put } from "./apiService";
import { URL_ADD_PROVIDER, URL_EDIT_PROVIDER, URL_PROVIDER_LIST, URL_REMOVE_IMAGE, URL_RESENT_MAIL, URL_STATUS_PROVIDER, URL_UPDATE_PROVIDER, URL_UPLOAD_IMAGE } from "../config/apiUrls";
import { IImageInput, IRemoveImage, IResentEmail } from "../models/commonInterfaces";


// to fetch all provider
export const fetchProviderList = createAsyncThunk(
    'user/fetchProviderList',
    async (form: IProviderState, thunkAPI) => {       
      const {  page, ...body } = form; 
      
        try {
            let url = URL_PROVIDER_LIST ;
             if (page !== 0) {
              url = url + '?page=' + page
            }
            const response = post(url, body);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

//to add provider
export const addProviderData = createAsyncThunk(
    'user/addProviderData',
    async (form: IProviderData, thunkAPI) => {
        try {
            const response = post(URL_ADD_PROVIDER, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

//to update provider user
export const updateProviderData = createAsyncThunk(
    'user/updateProviderData',
    async (form: IProviderData, thunkAPI) => {
        try {
            const { uuid, ...body } = form;
            const url = URL_UPDATE_PROVIDER + uuid;
            const response = put(url, body);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// to get singal provider user details
export const editProviderData = createAsyncThunk(
    'user/editProviderData',
    async (form: IProviderState, thunkAPI) => {
      try {
        const {uuid,...body } = form;
        const url = URL_EDIT_PROVIDER + uuid;
        const response = get(url, body);
        return (await response).data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  );

  
export const resentVerificationProviderMail = createAsyncThunk(
  'user/resentVerificationProviderMail',
  async (form: IResentEmail, thunkAPI) => {
    try {
      const response = post(URL_RESENT_MAIL, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//to update status on provider list
  export const updateProviderUserStatus = createAsyncThunk(
    'user/updateProviderUserStatus',
    async (form: IProviderStatus, thunkAPI) => {
      try {
        const response = put(URL_STATUS_PROVIDER, form);
        return (await response).data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  );

  
  export const addProviderImageInput = createAsyncThunk(
    'user/addProviderImageInput',
    async (form: IImageInput, thunkAPI) => {
        try {
            const response = postFormData(URL_UPLOAD_IMAGE, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
  );
  
  export const removeProviderImageInput = createAsyncThunk(
    'user/removeProviderImageInput',
    async (form: IRemoveImage, thunkAPI) => {
        try {
            const response = post(URL_REMOVE_IMAGE, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
  );

        
  export const addProviderSignInput = createAsyncThunk(
    'user/addProviderSignInput',
    async (form: IImageInput, thunkAPI) => {
        try {
            const response = postFormData(URL_UPLOAD_IMAGE, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
  );

    
  export const removeProviderSignInput = createAsyncThunk(
    'user/removeProviderSignInput',
    async (form: IRemoveImage, thunkAPI) => {
        try {
            const response = post(URL_REMOVE_IMAGE, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
  );