import React from 'react';

interface ButtonTypeProps {
  type?: string;
  className?: string;
  icon?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  buttonLabel: string;
  buttonType?: 'submit' | 'button';
}

const ButtonType: React.FC<ButtonTypeProps> = React.memo((props) => {
  const getButtonClassName = () => {
    switch (props.type) {
      case 'login':
        return `${props.className} px-6 py-2 text-[15px] rounded-3xl `;

      case 'webCardbtn':
        return `${props.className}  text-[var(--color-white)] bg-[var(--color-253179)] text-[15px] rounded-[24px] tracking-[0.05px] text-center py-3 px-8 `;

      case 'iconLeft':
        return `${props.className} flex justify-center items-center gap-4 py-3 px-8 text-[var(--color-white)] bg-[var(--color-253179)] text-sm rounded-lg tracking-[0.05px]`;

      default:
        return props.className || '';
    }
  };

  return (
    <button
      className={getButtonClassName()}
      onClick={props.onClick}
      type={props.buttonType || 'button'}
    >
      {props.icon && <img src={props.icon} alt="" loading='lazy' decoding='async' />}
      {props.buttonLabel}
    </button>
  );
});

export default ButtonType;
