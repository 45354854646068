import React, { useMemo } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { ROUTE_LOGIN, ROUTE_LOGIN_PATIENT } from '../../config/routeConfig';
import Navbar from '../../components/navbar/Navbar';
import SideBar from '../../components/sidebar/SideBar';
import { useAppSelector } from '../../helpers/hook';
import { loginPageSlices } from '../../pages/loginPage/loginPageSlice';
import { USER_ROLE_PATIENTS, USER_ROLE_RELATIVES } from '../../config/permissions';
import Footer from '../../components/footer/Footer';

// Replace 'true' with your actual condition

const ProtectedRoutes = () => {
  const { token, unid, loginUserRole } = useAppSelector(loginPageSlices);
  const isAuthenticated = useMemo(() => token?.length > 0 && unid?.length > 0, [token, unid]);

  return isAuthenticated ? (
    <>
      {/* Render components when the user is authenticated */}
      <Navbar />
      <div className='flex w-full '>
        <SideBar />
        <div className="flex flex-col justify-between w-full">
          <Outlet />
          <Footer />
        </div>
      </div>
    </>
  ) : (<>
    {/* // Redirect to the login page when the user is not authenticated */}
    {(loginUserRole === USER_ROLE_PATIENTS || loginUserRole === USER_ROLE_RELATIVES) ?
      <Navigate to={ROUTE_LOGIN_PATIENT} /> : <Navigate to={ROUTE_LOGIN} />}</>
  );
};

export default ProtectedRoutes;


