import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";



interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const Paginations: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  currentPage,
  setCurrentPage,
}) => {


  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };


  return (
    <>
      {pageCount > 1 &&
        <Stack spacing={2} className="items-end pl-12 pr-8 ">
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            siblingCount={0}
            color="primary"
            className="my-8 pagination" />
        </Stack>}</>
  );
};

export default Paginations;
