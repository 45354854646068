import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_FORGET_PWD, URL_LOGIN,  URL_LOGIN_PATIENT, URL_LOGIN_USER_PATIENT, URL_LOGOUT } from "../config/apiUrls";
import { postWithoutAuth, postWithoutToken } from "./apiService";



// FUNCTION FOR VALIDATE USERNAME AND PASSWOR
export const authenticateUser = createAsyncThunk(
  'client/authenticateUser',
  async (apiData: { username: string, password: string }, thunkAPI) => {
    try {
      const response = postWithoutAuth(URL_LOGIN, apiData);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION FOR LOGOUT
export const handleLogoutData = createAsyncThunk(
  'client/handleLogoutData',
  async (form: { unid: string, uuid: string, firebase_token: string, device_type: number }, thunkAPI) => {
    try {
      const response = postWithoutToken(URL_LOGOUT, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);



//FUNCTION TO RESET USERS PASSWORD BEFORE LOGIN 
export const resetUserPasswordNoAuth = createAsyncThunk(
  'account/resetUserPassword',
  async (email: string, thunkAPI) => {
    try {
      const response = postWithoutAuth(URL_FORGET_PWD, { email });
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


//FUNCTION TO RESET USERS VALIDATION
export const patientLoginEmailCheck = createAsyncThunk(
  'account/patientLoginEmailCheck',
  async (email: string, thunkAPI) => {
    try {
      const response = postWithoutAuth(URL_LOGIN_PATIENT, { email });
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// FUNCTION FOR VALIDATE EMAIL AND OTP
export const authenticatePatientUser = createAsyncThunk(
  'client/authenticatePatientUser',
  async (apiData: { username: string, password: string }, thunkAPI) => {
    try {
      const response = postWithoutAuth(URL_LOGIN_USER_PATIENT, apiData);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);