import { createAsyncThunk } from "@reduxjs/toolkit";
import { IHeaderNotification, ISetFirebaseToken } from "../models/notificationListInterfaces";
import { URL_FETCH_NOTIFICATION, URL_FIREBASE_TOKEN, URL_MARK_ALL_NOTIFICATION_READ, URL_MESS_MARK_NOTIFICATION } from "../config/apiUrls";
import { get, post } from "./apiService";
import { ITimesheetState } from "../models/timesheetInterfaces";


export const setFirebaseToken = createAsyncThunk(
  'user/setFirebaseToken',
  async (form: ISetFirebaseToken, thunkAPI) => {
    try {
      const response = post(URL_FIREBASE_TOKEN, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


  
  
  export const fetchNotification = createAsyncThunk(
    'user/fetchNotification',
    async (form: ITimesheetState, thunkAPI) => {
      try {
        const {  page, ...body } = form;
        let url = URL_FETCH_NOTIFICATION;
        if (page !== 0) {
          url = url + '?page=' + page
        }
        const response = get(url, body);
        return (await response).data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
     
  }
);


  
  export const fetchNotificationList = createAsyncThunk(
    'user/fetchNotificationList',
    async (form: ITimesheetState, thunkAPI) => {
      try {
        const {  page, ...body } = form;
        let url = URL_FETCH_NOTIFICATION;
        if (page !== 0) {
          url = url + '?page=' + page
        }
        const response = get(url, body);
        return (await response).data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    
  }
);


export const markNotificationAllRead = createAsyncThunk(  
    'user/markNotificationAllRead',
    async (form: IHeaderNotification, thunkAPI) => {
      try {
        const response = post(URL_MARK_ALL_NOTIFICATION_READ, form);
        return (await response).data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
);



export const handleMarkNotification = createAsyncThunk(
  'notification/handleMarkNotification',
  async (form: { unid: string, token: string, msg_email: string, message_type: number, user_email: string }, thunkAPI) => {
      try {
          const response = post(URL_MESS_MARK_NOTIFICATION, form);
          return (await response).data;
      } catch (error) {
          return thunkAPI.rejectWithValue(error);
      }
  }
)