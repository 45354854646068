import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { fetchFacilityDropdown, fetchFacilityDropdowns, fetchRelationshipData, fetchRelationshipDatas, fetchStateData, fetchWebVersion } from "../../services/commonServices";
import { IDropdownFacilityState, IDropdownState } from "../../models/commonInterfaces";


export interface CommonState {
    loading: 'idle' | 'loading' | 'success' | 'error';
    stateData: IDropdownState[];
    relationshipData: IDropdownState[];
    relationshipDatas: IDropdownState[];
    facilityData: IDropdownFacilityState[];
    facilityDatas: IDropdownFacilityState[];
    navFacility: number[];
    version: string;
}

const initialState: CommonState = {
    loading: 'idle',
    stateData: [],
    relationshipData: [],
    relationshipDatas: [],
    facilityData: [],
    facilityDatas: [],
    navFacility: [],
    version: ''
}

export const commonSlice = createSlice({
    name: 'commonSlice',
    initialState,
    reducers: {
        changeNavFacility: (state, action: PayloadAction<any>) => {
            state.navFacility = action.payload;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(fetchStateData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(fetchStateData.fulfilled, (state, action) => {
            const newState = { name: 'Select State', id: '' };
            state.stateData = [newState, ...action.payload] as IDropdownState[];
            state.loading = 'success';
        });
        builder.addCase(fetchStateData.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(fetchRelationshipData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(fetchRelationshipData.fulfilled, (state, action) => {
            state.relationshipData = action.payload as IDropdownState[];
            state.loading = 'success';
        });
        builder.addCase(fetchRelationshipData.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(fetchRelationshipDatas.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(fetchRelationshipDatas.fulfilled, (state, action) => {
            state.relationshipDatas = action.payload as IDropdownState[];
            state.loading = 'success';
        });
        builder.addCase(fetchRelationshipDatas.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(fetchFacilityDropdown.pending, (state) => {
            state.facilityData = [];
            state.loading = 'loading';
        });
        builder.addCase(fetchFacilityDropdown.fulfilled, (state, action) => {
            const facilityList = action.payload as IDropdownFacilityState[];
            let opts: IDropdownFacilityState[] = [];
            opts = facilityList.map(option => {
                return { name: option.name, id: option.id.toString(), isChecked: option.isChecked };
            });
            // Sort the opts array by the name property
            opts.sort((a, b) => a.name.localeCompare(b.name));

            state.facilityData = opts;
            state.loading = 'success';
        });

        builder.addCase(fetchFacilityDropdown.rejected, (state) => {
            state.loading = 'error';
        });


        builder.addCase(fetchFacilityDropdowns.pending, (state) => {
            state.facilityDatas = [];
            state.loading = 'loading';
        });
        builder.addCase(fetchFacilityDropdowns.fulfilled, (state, action) => {
            const facilityList = action.payload as IDropdownFacilityState[];
            let opts: IDropdownFacilityState[] = [];
            opts = facilityList.map(option => {
                return { name: option.name, id: option.id.toString(), isChecked: option.isChecked };
            });
            // Sort the opts array by the name property
            opts.sort((a, b) => a.name.localeCompare(b.name));

            state.facilityDatas = opts;
            state.loading = 'success';
        });

        builder.addCase(fetchFacilityDropdowns.rejected, (state) => {
            state.loading = 'error';
        });


        builder.addCase(fetchWebVersion.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(fetchWebVersion.fulfilled, (state, action) => {
            state.version = action.payload.web_version;
            state.loading = 'success';
        });
        builder.addCase(fetchWebVersion.rejected, (state) => {
            state.loading = 'error';
        });

    }
});

// Action creators are generated for each case reducer function
export const { changeNavFacility } = commonSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const CommonSlices = (state: RootState) => state.commonData;

export const commonReducer = commonSlice.reducer;