import React, { useEffect, useState } from 'react'
import { Avatar } from '@mui/material';
import { fetchNotificationList } from '../../services/notificationListServices';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { NotificationListSlices, setSelectedEmail } from './notificationSlice';
import { loginPageSlices } from '../loginPage/loginPageSlice';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { ROUTE_DASHBOARD } from '../../config/routeConfig';
import Paginations from '../../components/Pagination';


const NotificationList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { unid, token } = useAppSelector(loginPageSlices);
  const { notificationData,paginationTotalCount } = useAppSelector(NotificationListSlices);

  //Paginaton
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 25;

  useEffect(() => {
    dispatch(fetchNotificationList({ token, unid, page: currentPage }));
  }, []);

  // pagination    
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    dispatch(fetchNotificationList({ token, unid, page: page }));
  };

  return (
    <div className='py-[46px] px-11 w-full'>
      <h1 className='text-2xl text-[var(--color-212121)] mb-8'>Notifications</h1>
      <ul className="flex flex-col gap-[6px]">
        {notificationData.map((item, index) => (
          <>
            <li
              key={index}
              className={`${!item.is_read && " bg-[var(--color-E5EDFB)]"} cursor-pointer px-3 py-[6px]  rounded max-w-[652px]`}
              onClick={() => { dispatch(setSelectedEmail(item.sender_id)); navigate(ROUTE_DASHBOARD) }}
            >
              <div className='flex items-center gap-[14px]'>
                <Avatar src={item.profile_picture} alt={item.name} sx={{ border: "2px solid white" }} />

                <div className="flex items-center justify-between w-full ">
                  <div className='text-sm flex items-center gap-1 '><p className='text-[var(--color-0048D4)] font-medium'>{item.name}</p> <p className='text-[var(--color-757575)]'>{item.notification_string}</p></div>
                  <p className='text-[var(--color-757575)] text-[11px]'>
                    {dayjs(item.created_on).isSame(dayjs(), 'day')
                      ? dayjs(item.created_on).format('hh:mm A')
                      : dayjs(item.created_on).format('MMM D, YYYY, hh:mm A')}</p>
                </div>
              </div>
            </li></>
        ))}
      </ul>
      <Paginations
        totalItems={paginationTotalCount}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={handlePageChange}
      />
    </div>
  )
}

export default NotificationList