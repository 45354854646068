import React, { ChangeEvent, useEffect, useState } from 'react';
import dummyAvatar from '../../assets/images/dummyAvatar.svg';
import dummySquareAvartar from '../../assets/images/dummySquarAvatar.svg';

interface ImageInputProps {
  label?: string;
  profileImage: string;
  squareShape?: boolean;
  handleAddImage: (selectedFile: File) => void; // New prop for add image action
  handleRemoveImage: () => void;
}

// Function to handle the selection of a new image file and update the profile image state
const ImageInput: React.FC<ImageInputProps> = React.memo(({ label, squareShape, profileImage, handleAddImage, handleRemoveImage, }) => {

  const [uploadedImage, setuploadedImage] = useState<string>('');



  useEffect(() => {
    setuploadedImage(profileImage);
  }, [profileImage])


  const selectImage = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    if (selectedFile) {

      handleAddImage(selectedFile);
    }
    e.target.value = '';
  };


  const removeImage = () => {
    handleRemoveImage();
    setuploadedImage('');
  }

  return (
    <div className="w-full">
      {label && (
        <span className="text-[15px] text-[var(--color-212121)] tracking-[0.05px]">
          {label}
        </span>
      )}
      <div className="flex mt-[18px] gap-4 items-center">
        <label htmlFor="" className=" flex gap-6 items-center ">
          {squareShape ?
            (uploadedImage !== '') ?
              <img
                src={uploadedImage}
                alt="dummyAvatar"
                decoding="async"
                loading="lazy"
                className=' h-[143px] w-auto max-w-[240px] rounded'
              /> :
              <div className="flex justify-center items-center bg-[var(--color-D9D9D9)] rounded-xl h-[143px] w-[240px] overflow-hidden object-fit">
                <img
                  src={dummySquareAvartar}
                  alt="dummyAvatar"
                  decoding="async"
                  loading="lazy"
                />
              </div> :
            <img
              src={(uploadedImage !== '') ? uploadedImage : dummyAvatar}
              alt="dummyAvatar"
              decoding="async"
              loading="lazy"
              className="h-[120px] w-[120px] object-cover rounded-full "
            />
          }
          <div>
            <div className="relative text-[var(--color-white)] bg-[var(--color-253179)] text-xs rounded-[24px] tracking-[0.05px] text-center py-2 px-4 overflow-hidden cursor-pointer">
              Upload
              <input
                type="file"
                accept=".png, .jpg, .jpeg ,.tiff"
                className="absolute cursor-pointer z-[1] -top-7 left-0 w-full h-[70px] opacity-0"
                onChange={(e) => selectImage(e)}
              />
            </div>
          </div>
        </label>
        <button type='button' className="text-[var(--color-424242)] bg-[var(--color-white)] text-xs rounded-[24px] tracking-[0.05px] text-center font-medium" onClick={() => {
          removeImage();
        }}>
          Remove
        </button>
      </div>
    </div>
  );
});

export default ImageInput;
