import React, { useState, useEffect, } from 'react'
import TextField from '../../components/inputFeild/TextFeild'
import ButtonType from '../../components/button/ButtonType'
import logo from '../../assets/images/careConnectIcon.png';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { useNavigate } from 'react-router-dom';
import { authenticatePatientUser,  resetUserPasswordNoAuth } from '../../services/loginService';
import { loginPageSlices } from './loginPageSlice';
import { ROUTE_DASHBOARD } from '../../config/routeConfig';
import Footer from '../../components/footer/Footer';
import { Box, Modal } from '@mui/material';
import ConfirmationMessage from '../../components/notification/ConfirmationMessage';
import { message } from '../../utilities/locals/messages';

const PatientLogin = () => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-52%, -50%)',
    };

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    // const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const { loading } = useAppSelector(loginPageSlices);

    // const placeholderUnicode = '\u2217'
    const [userName, setuserName] = useState<string>('');
    const [password, setpassword] = useState<string>('');
    const [showForgetPassword, setShowForgetPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    // const [tab, settab] = useState<boolean>(false);
    // const [otpValues, setOtpValues] = useState<string[]>(['', '', '', '', '', '']); // Array to hold 6 OTP values
    // const [otpValues, setOtpValues] = useState<string>(''); // Array to hold 6 OTP values


    // useEffect(() => {
    //     // Focus the first input when the component mounts
    //     if (inputRefs.current[0]) {
    //         inputRefs.current[0]?.focus();
    //     }

    // }, []);

    //handle navigation to coresponding page if login sucess 
    useEffect(() => {

        if (loading === "loginSuccess") {
            navigate(ROUTE_DASHBOARD);
        }

    }, [loading]);


    // const handleInputChange = (index: number, value: string) => {
    //     // Update the OTP value at the specified index
    //     const updatedOtpValues = [...otpValues];
    //     updatedOtpValues[index] = value;
    //     setOtpValues(updatedOtpValues);

    //     if (inputRefs.current[index + 1] && value !== '') {
    //         inputRefs.current[index + 1]?.focus();
    //     }
    // };

    // const handleKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    //     if (e.key === 'ArrowRight' && inputRefs.current[index + 1] && otpValues[index] !== '') {
    //         inputRefs.current[index + 1]?.focus();
    //     } else if (e.key === 'ArrowLeft' && inputRefs.current[index - 1] && index > 0) {
    //         inputRefs.current[index - 1]?.focus();
    //     }
    // };

    //FUNCTION TO SENT EMAIL TO USER
    // const handleUserEmailCheck = (e: any) => {
    //     e.preventDefault();
    //     dispatch(patientLoginEmailCheck(userName));
    // }

    //FOR LOGIN
    // const handleUserOTPCheck = (e: any) => {
    //     e.preventDefault();
    //     dispatch(authenticatePatientUser({ email: userName, otp: otpValues }));
    //     // dispatch(authenticatePatientUser({ email: userName, otp: otpValues.join('') }));
    // }
    const handleCheckData = (e: any) => {
        e.preventDefault();
        dispatch(authenticatePatientUser({ username: userName, password: password }));
        // dispatch(authenticatePatientUser({ email: userName, otp: otpValues.join('') }));
    }
    const handleConfirmResetPassword = (e: any) => {
        e.preventDefault();
        setShowForgetPassword(false);
        setShowConfirmPassword(true);
    }

    //handle reset password function
    const handleConfirmPassword = () => {
        dispatch(resetUserPasswordNoAuth(userName))
        setShowConfirmPassword(false);
    }

    return (
        <div className="flex w-full flex-col justify-between">
            <div className='min-h-[calc(100vh-49px)] bg-[var(--color-F5F5F5)]'>

                <header >
                    <nav className="flex items-center justify-center w-100 py-[37px] px-[37px] lg:px-[62px] ">
                        <div className="flex items-center justify-between w-full max-w-[1436px] ">
                            <div className="flex items-center gap-3">
                                <img
                                    className="h-[57px] w-auto"
                                    src={logo}
                                    alt=""
                                    loading='lazy'
                                    decoding='async'
                                />
                                <h1 className="text-[var(--color-253179)] text-xl font-bold tracking-[0.05px] ">CFP Care Connect</h1>
                            </div>

                        </div>
                    </nav>
                </header>

                <div className=' bg-[var(--color-F5F5F5)] flex justify-center items-center px-6 lg:px-8'>
                    {/* {!tab ? */}
                    <div className=" bg-[var(--color-white)]  flex min-h-full max-w-[488px] rounded-xl flex-1 flex-col justify-center p-9 gap-[60px]">
                        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                            <h1 className=" text-center text-2xl font-medium tracking-[0.05px] text-[var(--color-212121)]">Login</h1>
                            <h2 className="mt-6 text-center text-base font-medium tracking-[0.05px] text-[var(--color-212121)]">
                                Enter Registered Email Address
                            </h2>
                        </div>

                        <div className="">
                            <form className="" onSubmit={(e) => handleCheckData(e)}>
                                <div>
                                    <label htmlFor="" className='flex flex-col gap-3'>
                                        <span className='text-[15px] text-[var(--color-212121)] tracking-[0.05px] '>Email</span>
                                        <TextField
                                            required={true}
                                            placeholder='Email'
                                            autoComplete="email"
                                            type='email'
                                            value={userName}
                                            onChange={(e) => setuserName(e.target.value)}
                                        />
                                    </label>
                                </div>

                                <div className='flex flex-col gap-4 mt-9'>

                                    <label htmlFor="" className='flex flex-col gap-3'><span className='text-[15px] text-[var(--color-212121)] tracking-[0.05px] '>Password</span>
                                        <TextField
                                            required={true}
                                            placeholder='Password'
                                            type='password'
                                            autoComplete="current-password"
                                            value={password}
                                            onChange={(e) => setpassword(e.target.value)}
                                        />
                                    </label>

                                    <div className="flex  items-center justify-between">
                                        <div></div>
                                        <span onClick={() => setShowForgetPassword(true)} className='cursor-pointer text-[var(--color-0048D4)] tracking-[0.05px] text-sm hover:underline'>Forgot Password</span>
                                    </div>
                                </div>

                                <div>
                                    <ButtonType type="webCardbtn" buttonLabel='Sign In' className='w-full py-4 font-medium text-xl rounded-[30px] mt-12 mb-[60px] ' buttonType='submit'
                                    />
                                </div>
                                <p className="text-[var(--color-757575)] text-center mt-4 text-[15px] tracking-[0.05px]">Don’t have an account? <a href={"/"} className='text-[var(--color-0048D4)] underline tracking-[0.05px]'>Register</a> instead
                                </p>
                            </form>

                        </div>
                    </div>
                    {/* :<div className=" bg-[var(--color-white)]  flex min-h-full max-w-[488px] rounded-xl flex-1 flex-col justify-center p-9 gap-[60px]">
                            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                                <h1 className=" text-center text-2xl font-medium tracking-[0.05px] text-[var(--color-212121)]">Enter OTP</h1>
                                <h2 className="mt-6 text-center text-base  tracking-[0.05px] text-[var(--color-212121)]">
                                    Please check your email for a One-Time Password (OTP) and enter it here.
                                </h2>
                            </div>

                            <div className="">
                                <form className="" onSubmit={(e) => handleUserOTPCheck(e)}>
                                    <div>
                                        <label htmlFor="" className='flex flex-col gap-3'>
                                            <span className='text-[15px] text-[var(--color-212121)] tracking-[0.05px] '>OTP</span>

                                            <div className='flex gap-3 justify-between'>

                                                {otpValues.map((value, index) => (
                                                    <input
                                                        key={index}
                                                        required={true}
                                                        placeholder={placeholderUnicode}
                                                        autoComplete="off"
                                                        type='password'
                                                        maxLength={1}
                                                        value={value}
                                                        className='common-input focus:outline-none h-12 w-12 text-center '
                                                        ref={(el) => (inputRefs.current[index] = el)}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                            handleInputChange(index, e.target.value)
                                                        }
                                                        onKeyDown={handleKeyDown(index)}
                                                    />
                                                ))}
                                                <TextField
                                                    required={true}
                                                    placeholder={`${placeholderUnicode} ${placeholderUnicode} ${placeholderUnicode} ${placeholderUnicode} ${placeholderUnicode} ${placeholderUnicode}`}
                                                    autoComplete="off"
                                                    type='password'
                                                    maxLength={6}
                                                    className=' tracking-widest '
                                                    value={otpValues}
                                                    onChange={(e) => setOtpValues(e.target.value)}
                                                />

                                            </div>
                                        </label>
                                    </div>

                                    <div>
                                        <ButtonType type="webCardbtn" buttonLabel='Next' className='w-full py-4 font-medium text-xl rounded-[30px] mt-12 mb-[60px] ' buttonType='submit'
                                        />
                                    </div>
                                    <p className="text-[var(--color-757575)] text-center mt-4 text-[15px] tracking-[0.05px]">Didn't receive your OTP? Click <span className='text-[var(--color-0048D4)] underline tracking-[0.05px] cursor-pointer ' onClick={() => dispatch(patientLoginEmailCheck(userName))}> Resend OTP</span>
                                    </p>
                                </form>

                            </div>
                        </div>} */}
                </div>

                <Modal
                    open={showForgetPassword}
                    onClose={() => setShowForgetPassword(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} className='focus:outline-none'>
                        <form onSubmit={(e) => handleConfirmResetPassword(e)} typeof='submit' className='flex flex-col gap-5 p-6  bg-[var(--color-FFF)] shadow-md rounded-lg min-w-[488px]  max-w-[596px]'>
                            <div>
                                <label htmlFor="" className='flex flex-col gap-3'>
                                    <span className='text-[15px] text-[var(--color-212121)] tracking-[0.05px] '>Username <span className='text-[var(--color-9E9E9E)]'>(Email)</span> <span className='text-[var(--color-danger)]'>*</span></span>
                                    <TextField
                                        required={true}
                                        placeholder='Email'
                                        autoComplete="email"
                                        type='email'
                                        value={userName}
                                        onChange={(e) => setuserName(e.target.value)}
                                    />
                                </label>
                            </div>

                            <div className='w-full flex items-center justify-end gap-4 text-sm'>

                                <button className='rounded-lg px-3 py-2 border border-[var(--color-EEE)]  hover:bg-[var(--color-0000000a)]' onClick={() => setShowForgetPassword(false)}>Cancel</button>

                                <button
                                    type='submit'
                                    className='rounded-lg px-3 py-2  text-[var(--color-white)] bg-[var(--color-253179)]'
                                >
                                    Reset Password
                                </button>

                            </div>


                        </form>
                    </Box>
                </Modal>

                <ConfirmationMessage
                    title={message.CFPCareConnect}
                    description={`Are you sure you want to reset the password for this account?`}
                    open={showConfirmPassword}
                    textType={false}
                    type={'warning'}
                    buttonTwoOnClick={() => setShowConfirmPassword(false)}
                    buttonOneOnClick={() => handleConfirmPassword()}
                    buttonLabelOne={'Send'}
                    buttonLabelTwo='Cancel'
                />
            </div>
            <div className="w-full bg-[var(--color-F5F5F5)]">
                <Footer />
            </div>
        </div>
    )
}

export default PatientLogin