import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ITimesheetDropdown, ITimesheetListState, ITimesheetNameDropdown } from "../../models/timesheetInterfaces";
import { fetchTimesheetProviderList, fetchTimesheetList, exportTimesheetList, fetchPatientName } from "../../services/timesheetListServices";


export interface timeSheetState {
    loading: 'idle' | 'loading' | 'success' | 'showCurrentNotificationSuccess' | 'error';
    timesheetList: ITimesheetListState[];
    providerList: ITimesheetDropdown[];
    patientList: ITimesheetNameDropdown[];
    paginationTotalCount: number;
    individualTotalTime: string;

}

const initialState: timeSheetState = {
    loading: 'idle',
    timesheetList: [],
    paginationTotalCount: 0,
    providerList: [],
    patientList: [],
    individualTotalTime: "",

}

export const timeSheetSlice = createSlice({
    name: 'timeSheetSlice',
    initialState,
    reducers: {
        changeLoader: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        },
        resetTimesheetList: (state, action: PayloadAction<any>) => {
            state.timesheetList = action.payload;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(fetchTimesheetProviderList.pending, (state) => {
            state.providerList = [];
            state.loading = 'loading';
        });
        builder.addCase(fetchTimesheetProviderList.fulfilled, (state, action) => {
            let opts: any[] = [];
            opts = action.payload.map((option: any) => {
                return { name: option.name, id: option.id.toString(), user_id: option.user_id };
            });
            state.providerList = opts as ITimesheetDropdown[];
            state.loading = 'success';
        });

        builder.addCase(fetchTimesheetProviderList.rejected, (state) => {
            state.loading = 'error';
        });


        builder.addCase(fetchTimesheetList.pending, (state) => {
            state.timesheetList = [];
            state.paginationTotalCount = 0;
            state.loading = 'loading';
            state.individualTotalTime = "";
        });
        builder.addCase(fetchTimesheetList.fulfilled, (state, action) => {
            state.loading = 'success';
            state.timesheetList = action.payload.results as ITimesheetListState[];
            state.individualTotalTime = action.payload.info ? action.payload.info.total_time : '';
            state.paginationTotalCount = action.payload.count ? action.payload.count : 0;
        });

        builder.addCase(fetchTimesheetList.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(exportTimesheetList.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(exportTimesheetList.fulfilled, (state) => {
            state.loading = 'success';
        });
        builder.addCase(exportTimesheetList.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(fetchPatientName.pending, (state) => {
            state.patientList = [];
            state.loading = 'idle';
        });
        builder.addCase(fetchPatientName.fulfilled, (state, action) => {

            const opts = action.payload.map((option: any) => {
                return { patient_first_name: option.patient_first_name, patient_last_name: option.patient_last_name, id: option.id.toString() };
            });
            state.patientList = opts as ITimesheetNameDropdown[];
            state.loading = 'success';
        });
        builder.addCase(fetchPatientName.rejected, (state) => {
            state.loading = 'error';
        });



    }
});

// Action creators are generated for each case reducer function
export const { changeLoader, resetTimesheetList } = timeSheetSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const TimeSheetSlices = (state: RootState) => state.timeSheetData;

export const timeSheetReducer = timeSheetSlice.reducer;