import React from 'react'
import WebNavbar from '../components/navbar/WebNavbar'
import ButtonType from '../components/button/ButtonType'
import patientImg from '../assets/images/patientImg.png'
import familyMemberImg from '../assets/images/familyMemberImg.png'
import nursingStaffImg from '../assets/images/nursingStaffImg.png'
import { ROUTE_FAMILY_REG, ROUTE_NURSING_STAFF_REG, ROUTE_PATIENT_REG } from '../config/routeConfig'
import { useNavigate } from 'react-router-dom'
import WebFooter from '../components/footer/WebFooter'

const WebHomePage = () => {
  const navigate = useNavigate()
  const cardContent = [
    { bgColor: '0096881a', title: 'Patient', img: patientImg, description: "Join us as a patient to take control of your healthcare journey. Access and collaborate with your health care team and facility. Providing you direct access to your medical providers.", buttonLabel: "Register as a Patient", path: ROUTE_PATIENT_REG },
    { bgColor: 'ed93481a', title: 'Family/POA', img: familyMemberImg, description: "Loved one cognitively impaired? Stay connected with the health care team. Join to collaborate and receive updates with your healthcare team and facility. Providing you direct access to your loved one’s medical providers.", buttonLabel: "Register as a Member", path: ROUTE_FAMILY_REG },
    { bgColor: '2196f31a', title: 'Facility Staff', img: nursingStaffImg, description: "Connect with our team for updates/requests/orders to make providing healthcare to your community a seamless process.", buttonLabel: "Register as Staff", path: ROUTE_NURSING_STAFF_REG },
  ]
  return (
    <>
      <WebNavbar />

      <div className='mt-[179px] flex flex-col items-center px-4 '>
        <h2 className='text-center text-5xl font-medium tracking-[.05px] text-[var(--color-212121)]'>Stay Happy. Live Healthy.</h2>
        <h2 className='text-center text-3xl font-medium tracking-[.05px] text-[var(--color-212121)] mt-6'>Choose Your Role and Get Started</h2>
        {/* <p className='max-w-[754px] text-center text-xl tracking-[.05px] text-[var(--color-424242)] mt-9 '>Select the role that best fits you – whether you're a patient, a family member, or staff. Your choice will shape your unique journey within our healthcare community. Let's begin the next step together</p> */}
        <div className='flex justify-center flex-wrap gap-14 mt-[70px] mb-[40px]'>
          {cardContent.map((item, index) => <>

            <div className={`${index === 0 ? 'bg-[var(--color-0096881a)]' : index === 1 ? 'bg-[var(--color-ed93481a)]' : 'bg-[var(--color-2196f31a)]'} web-card flex flex-col justify-between mx-auto md:mx-0`}>
              <div className="w-full">
                <h3 className='text-center text-xl font-medium tracking-[.05px] text-[var(--color-212121)]'>{item.title}</h3>
                <img src={item.img} alt={item.title} loading='lazy' decoding='async'
                  className={'mt-[32px] rounded'}
                //  className={index === 1 ? 'mt-[59px]' : 'mt-[49px]'}
                />
                <p className='text-center text-[15px] mt-8 mb-11 tracking-[.05px] text-[var(--color-424242)]'>{item.description}</p>
              </div>
              <ButtonType
                type='webCardbtn'
                className='w-full'
                buttonLabel={item.buttonLabel}
                onClick={() => navigate(item.path)} />
            </div>
          </>)}

        </div>
      </div>
      <WebFooter />
    </>
  )
}

export default WebHomePage