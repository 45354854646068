import { createAsyncThunk } from "@reduxjs/toolkit";
import {   postFormDataWithoutAuth, postWithoutAuth } from "./apiService";
import { IImageInputWithoutToken, IRemoveImageWithoutToken } from "../models/commonInterfaces";
import { IFamilyRegData } from "../models/FamilyRegInterface";
import { URL_FAMILY_REG, URL_FAMILY_REG_REMOVE_IMG, URL_FAMILY_REG_UPLOAD_IMG } from "../config/apiUrls";



// FUNCTION FOR VALIDATE USERNAME AND PASSWOR
export const addFamilyReg = createAsyncThunk(
  'client/addFamilyReg',
  async (form: IFamilyRegData, thunkAPI) => {
    try {
      const response = postWithoutAuth(URL_FAMILY_REG, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

  
export const addFamilyRegImage = createAsyncThunk(
    'user/addFamilyRegImage',
    async (form: IImageInputWithoutToken, thunkAPI) => {
        try {
            const response = postFormDataWithoutAuth(URL_FAMILY_REG_UPLOAD_IMG, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
  );
  
  export const removeFamilyRegImage = createAsyncThunk(
    'user/removeFamilyRegImage',
    async (form: IRemoveImageWithoutToken, thunkAPI) => {
        try {
            const response = postWithoutAuth(URL_FAMILY_REG_REMOVE_IMG, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
  );


  





