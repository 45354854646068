import React from 'react'
import WebFooter from '../components/footer/WebFooter'
import WebNavbar from '../components/navbar/WebNavbar'

const PrivacyPolicy = () => {
    return (
        <>
            <WebNavbar />

            <div className='mt-[100px] flex flex-col py-[37px] px-[37px] lg:px-[64px] min-h-[70vh]'>
                <h2 className='text-start text-5xl font-medium tracking-[.05px] text-[var(--color-212121)]'>Privacy Policy</h2>
                <br />
                <br />
                
                <h3 className='text-xl font-medium tracking-[.05px] text-[var(--color-212121)]'>Introduction</h3>
                <p className='text-[15px]tracking-[.05px] text-[var(--color-424242)] mt-2'>CFP Care Connect is committed to protecting the data collected pertaining to my users. This policy details the collection, use, and protection of personal information we receive from application users and visitors. By entering our application you consent to our use of cookies as pertaining to the terms of this policy.</p>
                <br />

                <h3 className='text-xl font-medium tracking-[.05px] text-[var(--color-212121)]'>Data Collection</h3>
                <p className='text-[15px]tracking-[.05px] text-[var(--color-424242)] mt-2'>CFP Care Connect collects the minimum amount of personally identifiable information necessary to improve our application and the service it provides. Data we collect includes any or all of the information you provide while using the application to its full capability.</p><br />

                <h3 className='text-xl font-medium tracking-[.05px] text-[var(--color-212121)]'>Use of Information</h3>
                <p className='text-[15px]tracking-[.05px] text-[var(--color-424242)] mt-2'>CFP Care Connect may use the personal information collected through the application specifically for the purposes detailed in this policy. Personal information that we have processed for any reason won’t be kept longer than is necessary for those purposes. We will take reasonable measures to prevent the loss or misuse of your personal information.</p><br />

              
            </div>
            <WebFooter />
        </>
    )
}

export default PrivacyPolicy