import React, { useState, useEffect, useRef, ChangeEvent } from 'react';

interface MultiSelectFieldOption {
  id: string;
  name: string;
  isChecked: boolean;
}

interface MultiSelectFieldProps {
  type?: "inputType";
  name?: string;
  id?: string;
  className?: string;
  value?: number[];
  onChange?: (values: number[]) => void;
  disabled?: boolean;
  required?: boolean;
  labelLeft?: boolean;
  selectAllLabel: {
    id: string,
    name: string,
    isChecked: boolean,
  };
  options: MultiSelectFieldOption[];
  placeHolder: string;
  label?: string;
}

const MultiSelectField: React.FC<MultiSelectFieldProps> = React.memo((props) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [selectedOption, setSelectedOption] = useState(props.options && props.options[0]?.name);

  const [selectedOptions, setSelectedOptions] = useState<{ name: string; id: string; isChecked: boolean; }[]>(props.options ? props.options : []);
  const [selectedLabel, setSelectedLabel] = useState(props.placeHolder);

  const selectRef = useRef<HTMLSelectElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  // Effect to close the select dropdown when clicking outside of it
  useEffect(() => {
    const closeSelects = (e: MouseEvent) => {
      if (!e.target || !(e.target instanceof Node)) return;
      if (!wrapperRef.current?.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', closeSelects);

    return () => {
      document.removeEventListener('mousedown', closeSelects);
    };
  }, []);

  useEffect(() => {
    if (props.value && props.value.length > 0) {

      const updatedOptions = props.options.map((option) => {
        if (props.value?.includes(parseInt(option.id, 10))) {
          return { ...option, isChecked: true };
        }
        return { ...option, isChecked: false };
      });

      setSelectedOptions(updatedOptions);

      const selectedData = updatedOptions.filter((item) => item.isChecked);
      const selectedNames = selectedData[0]?.name;
      const count = selectedData.length;
      if (updatedOptions.some((item) => item.isChecked !== true)) {
        setSelectedLabel(count > 0 ? `${selectedNames} ${count > 1 ? `( +${count - 1} )` : ""}` : props.placeHolder);
      } else {
        if (props.options.length > 1) {
          setSelectedLabel(props.selectAllLabel.name);
        } else {
          setSelectedLabel(props.options[0]?.name);
        }
      }
    } else {
      setSelectedLabel(props.placeHolder);
      const updatedOpt = props.options.map((option) => {
        return { ...option, isChecked: false };
      });

      setSelectedOptions(updatedOpt);
    }

  }, [props.value])


  //Setting all options
  useEffect(() => { setSelectedOptions(props.options) }, [props.options])




  const handleSelectClick = () => {
    setIsOpen(!isOpen);
  };

  // Function to handle click on an option in the dropdown
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target;

    if (id === props.selectAllLabel.id) {
      const updatedOptions = props.options.map((option) => ({ ...option, isChecked: checked }));
      setSelectedOptions(updatedOptions);
      setSelectedLabel(checked ? props.selectAllLabel.name : props.placeHolder);
      const selectedIds = updatedOptions
        .filter((option) => option.isChecked)
        .map((option) => parseInt(option.id, 10));

      // Call the onChange callback with the array of selected IDs
      props.onChange?.(selectedIds);
    } else {
      const updatedOptions = selectedOptions.map((option) =>
        option.id === id ? { ...option, isChecked: checked } : option
      );

      setSelectedOptions(updatedOptions);

      const selectedData = updatedOptions.filter((item) => item.isChecked);
      const selectedNames = selectedData[0]?.name;
      const count = selectedData.length;
      const selectedIds = updatedOptions
        .filter((option) => option.isChecked)
        .map((option) => parseInt(option.id, 10));

      // Call the onChange callback with the array of selected IDs
      props.onChange?.(selectedIds);
      if (updatedOptions.some((item) => item.isChecked !== true)) {
        setSelectedLabel(count > 0 ? `${selectedNames} ${count > 1 ? `( +${count - 1} )` : ""}` : props.placeHolder);
      } else {
        setSelectedLabel(props.selectAllLabel.name);

      }
    }


  };

  return (
    <div className={`${props.labelLeft ? ' gap-3 items-center' : ' flex-col gap-1 '} flex  w-full`}>
      {props.label && props.label?.length > 0 && <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px] '>
        {props.label} {props.required && <span className='text-[var(--color-red)]'>*</span>}
      </span>}

      <div className="relative" style={{ width: '100%' }} ref={wrapperRef}>
        <div className={(props.className) + ` p-3  select-selected  ${(props.placeHolder === selectedLabel ? ' text-[var(--color-9E9E9E)] ' : isOpen ? 'select-arrow-active' : ' text-[var(--color-212121)] ')}`} onClick={() => handleSelectClick()}>
          {selectedLabel}
        </div>




        <div className={`select-items custom-scrollBar ${isOpen ? '' : ' hidden '}`}>

          {selectedOptions.length > 1 && <div
            key={props.selectAllLabel.id}

            className={(!selectedOptions.some((item) => item.isChecked !== true)) ? 'same-as-selected' : (' text-[var(--color-212121)] ')}
          >
            <label htmlFor={props.selectAllLabel.id} className='flex items-center gap-2 justify-start'>
              <input
                type="checkbox"
                name={props.selectAllLabel.id}
                checked={(!selectedOptions.some((item) => item.isChecked !== true))}
                onChange={(e) => handleChange(e)}
                id={props.selectAllLabel.id}
                disabled={props.disabled}
              />
              {props.selectAllLabel.name}
            </label>
          </div>
          }
          {selectedOptions?.map((option) => (
            <>
              <div
                key={option.id}

                className={option.isChecked ? 'same-as-selected' : (' text-[var(--color-212121)] ')}
              >
                <label className='flex items-center justify-start gap-3'>
                  <input type="checkbox"
                    disabled={props.disabled} name={option.name} id={option.id} checked={option.isChecked} onClick={(e: any) => handleChange(e)} />  {option.name}
                </label>
              </div></>
          ))}
        </div>



        <select
          className='absolute bottom-0 left-1 -z-10 min-w-full opacity-0 !improtant'
          ref={selectRef}
          name={props.name}
          id={props.id}
          value={(props.value && props.value.length > 0) ? props.value[0] : ""}
          // onChange={() => props.onChange}
          disabled={props.disabled}
          required={props.required}
        >

          <option value="">{props.placeHolder}</option>
          {props.selectAllLabel && <option value={props.selectAllLabel.id}>{props.selectAllLabel.name}</option>}
          {
            selectedOptions.map(item => <>
              <option value={item.id}>{item.name}</option></>)
          }
        </select>
      </div>
    </div>
  );
});

export default MultiSelectField;
