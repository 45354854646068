import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { addFacilityData, addFacilityImageInput, editFacilityData, fetchFacilityList, removeFacilityImageInput, updateFacilityData, updateFacilityStatus } from "../../services/facilityListServices";
import { RootState } from "../../store";
import { setNotification } from "../../components/notification/ToastNotification";
import { message } from "../../utilities/locals/messages";
import { IFacilitySingleData, IFacilityListData } from "../../models/facilityListInterfaces";
import { MOBILE_ALREADY_EXIST, NAME_EXIST } from "../../config/permissions";

export interface FacilityListState {
    loading: 'idle' | 'loading' | 'success' | 'fetchSuccessfully' | 'logoUploaded' | 'error';
    facilityListData: IFacilityListData[]
    facilityData: IFacilitySingleData[];
    paginationTotalCount: number;
    uploadedImg: string;
    imageId: number | null;
}

const initialState: FacilityListState = {
    loading: 'idle',
    facilityListData: [],
    paginationTotalCount: 0,
    facilityData: [],
    uploadedImg: '',
    imageId: null,
}

export const facilityListSlice = createSlice({
    name: "facilityListSlice",
    initialState,
    reducers: {
        changeFacilityLoading: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(fetchFacilityList.pending, (state) => {
            state.paginationTotalCount = 0;
            state.facilityListData = [];
            state.loading = 'loading';
        });
        builder.addCase(fetchFacilityList.fulfilled, (state, action) => {
            state.paginationTotalCount = action.payload.count;
            state.facilityListData = action.payload.results as IFacilityListData[];
            state.loading = 'fetchSuccessfully';
        });
        builder.addCase(fetchFacilityList.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(addFacilityData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(addFacilityData.fulfilled, (state) => {
            state.loading = 'success';
            setNotification(message.CFPCareConnect, message.facilityAddedSuccessfully, false, 'success', 'addFacilityData');

        });
        builder.addCase(addFacilityData.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            if (payload.response.data.error_message === MOBILE_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.mobileNumberExist, false, 'failed', 'addClientData');
            } else if (payload.response.data.error_message === NAME_EXIST) {
                setNotification(message.CFPCareConnect, message.facilityAlreadyExists, false, 'failed', 'addClientData');
            } else {
                setNotification(message.CFPCareConnect, message.facilityAddedfailed, false, 'failed', 'addClientData');
            }

        });

        builder.addCase(updateFacilityData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(updateFacilityData.fulfilled, (state) => {
            state.loading = 'success';
            setNotification(message.CFPCareConnect, message.facilityUpdatedSuccessfully, false, 'success', 'updateFacilityData');

        });
        builder.addCase(updateFacilityData.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            if (payload.response.data.error_message === MOBILE_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.mobileNumberExist, false, 'failed', 'updateFacilityData');
            } else if (payload.response.data.error_message === NAME_EXIST) {
                setNotification(message.CFPCareConnect, message.facilityAlreadyExists, false, 'failed', 'updateFacilityData');
            } else {
                setNotification(message.CFPCareConnect, message.facilityUpdatedfailed, false, 'failed', 'updateFacilityData');
            }

        });

        builder.addCase(editFacilityData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(editFacilityData.fulfilled, (state, action) => {
            state.loading = 'fetchSuccessfully';
            state.facilityData = [action.payload] as IFacilitySingleData[];
        });
        builder.addCase(editFacilityData.rejected, (state, ) => {
            state.loading = 'error';
        });

        builder.addCase(updateFacilityStatus.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(updateFacilityStatus.fulfilled, (state, action) => {
            state.loading = 'success';
            const m = action.payload.message;
            setNotification(message.CFPCareConnect, m, false, 'success', 'updateFacilityStatus');
        });
        builder.addCase(updateFacilityStatus.rejected, (state, ) => {
            state.loading = 'error';
            setNotification(message.CFPCareConnect, message.updationFailed, false, 'failed', 'updateFacilityStatus');

        });


        builder.addCase(addFacilityImageInput.pending, (state, ) => {
            state.uploadedImg = '';
            state.imageId = null;
            state.loading = 'idle';
        });

        builder.addCase(addFacilityImageInput.fulfilled, (state, action) => {
            state.uploadedImg = action.payload.logo;
            state.imageId = action.payload.id;
            state.loading = 'logoUploaded';
        });
        builder.addCase(addFacilityImageInput.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(removeFacilityImageInput.pending, (state) => {
            state.loading = 'idle';
        });

        builder.addCase(removeFacilityImageInput.fulfilled, (state) => {
            state.uploadedImg = '';
            state.imageId = null;
            state.loading = 'logoUploaded';
        });
        builder.addCase(removeFacilityImageInput.rejected, (state) => {
            state.loading = 'error';
        });


    }
});

export const { changeFacilityLoading } = facilityListSlice.actions;
export const FacilityListSlices = (state: RootState) => state.facilityListData;
export const facilityListReducer = facilityListSlice.reducer;