import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {  addClientData, addImageInput, addImageProfileInput, editClientData, fetchClientList,  removeImageInput, removeImageProfileInput, resentVerificationClientListMail, updateClientData, updateStatus } from '../../services/clientListServices';
import { IEditClientData, IClientListData } from '../../models/clientListInterfaces';
import { setNotification } from '../../components/notification/ToastNotification';
import { message } from '../../utilities/locals/messages';
import { CLIENT_NAME_EXIST, EMAIL_ALREADY_EXIST, MOBILE_EXIST } from '../../config/permissions';


export interface ClientListState {
    loading: 'idle' | 'loading' | 'success' | 'fetchSuccessfully' | 'logoUploaded' | 'error';
    clientListData: IClientListData[]
    clientData: IEditClientData[];
    uploadedImg: string;
    uploadedProfileImg: string;
    paginationTotalCount: number;
    imageId: number | null;
    imgProfileId: number | null;
    uploadedSignImg: string;
    imageSignId: number | null;
}

const initialState: ClientListState = {
    loading: 'idle',
    clientListData: [],
    paginationTotalCount: 0,
    clientData: [],
    uploadedImg: '',
    imageId: null,
    uploadedProfileImg: '',
    imgProfileId: null,
    uploadedSignImg: '',
    imageSignId: null,
}

export const clientListSlice = createSlice({
    name: 'clientListSlice',
    initialState,
    reducers: {
        changeLoading: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(fetchClientList.pending, (state) => {
            state.paginationTotalCount = 0;
            state.clientListData = [];
            state.loading = 'loading';
        });
        builder.addCase(fetchClientList.fulfilled, (state, action) => {
            state.paginationTotalCount = action.payload.count;
            state.clientListData = action.payload.results as IClientListData[];
            state.loading = 'fetchSuccessfully';
        });
        builder.addCase(fetchClientList.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(addClientData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(addClientData.fulfilled, (state) => {
            state.loading = 'success';
            setNotification(message.CFPCareConnect, message.clientAddedSuccessfully, false, 'success', 'addClientData');

        });
        builder.addCase(addClientData.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            if (payload.response.data.error_message === EMAIL_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.emailExist, false, 'failed', 'addClientData');
            } else if (payload.response.data.error_message === MOBILE_EXIST) {
                setNotification(message.CFPCareConnect, message.mobileNumberExist, false, 'failed', 'addClientData');
            } else if (payload.response.data.error_message === CLIENT_NAME_EXIST) {
                setNotification(message.CFPCareConnect, message.clientAlreadyExists, false, 'failed', 'addClientData');
            } else {
                setNotification(message.CFPCareConnect, message.clientAddedfailed, false, 'failed', 'addClientData');
            }

        });

        builder.addCase(updateClientData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(updateClientData.fulfilled, (state) => {
            state.loading = 'success';
            setNotification(message.CFPCareConnect, message.clientUpdatedSuccessfully, false, 'success', 'updateClientData');

        });
        builder.addCase(updateClientData.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            if (payload.response.data.error_message === EMAIL_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.emailExist, false, 'failed', 'updateClientData');
            } else {
                setNotification(message.CFPCareConnect, message.clientUpdatedfailed, false, 'failed', 'updateClientData');
            }
        });

        builder.addCase(editClientData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(editClientData.fulfilled, (state, action) => {
            state.loading = 'fetchSuccessfully';
            state.clientData = [action.payload] as IEditClientData[];

        });
        builder.addCase(editClientData.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(updateStatus.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(updateStatus.fulfilled, (state, action) => {
            state.loading = 'success';
            const m = action.payload.message;
            setNotification(message.CFPCareConnect, m, false, 'success', 'updateStatus');
        });
        builder.addCase(updateStatus.rejected, (state) => {
            state.loading = 'error';
            setNotification(message.CFPCareConnect, message.updationFailed, false, 'failed', 'updateClientData');

        });


        builder.addCase(addImageInput.pending, (state) => {
            state.uploadedImg = '';
            state.imageId = null;
            state.loading = 'idle';
        });

        builder.addCase(addImageInput.fulfilled, (state, action) => {
            state.uploadedImg = action.payload.logo;
            state.imageId = action.payload.id;
            state.loading = 'logoUploaded';
        });
        builder.addCase(addImageInput.rejected, (state) => {
            state.loading = 'error';
        });


        builder.addCase(addImageProfileInput.pending, (state) => {
            state.uploadedProfileImg = '';
            state.imgProfileId = null;
            state.loading = 'idle';
        });

        builder.addCase(addImageProfileInput.fulfilled, (state, action) => {
            state.uploadedProfileImg = action.payload.profile_pic;
            state.imgProfileId = action.payload.id;
            state.loading = 'logoUploaded';
        });
        builder.addCase(addImageProfileInput.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(removeImageInput.pending, (state) => {
            state.loading = 'idle';
        });

        builder.addCase(removeImageInput.fulfilled, (state) => {
            state.uploadedImg = '';
            state.imageId = null;
            state.loading = 'logoUploaded';
        });
        builder.addCase(removeImageInput.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(removeImageProfileInput.pending, (state) => {
            state.loading = 'idle';
        });

        builder.addCase(removeImageProfileInput.fulfilled, (state) => {
            state.uploadedProfileImg = '';
            state.imgProfileId = null;
            state.loading = 'logoUploaded';
        });
        builder.addCase(removeImageProfileInput.rejected, (state) => {
            state.loading = 'error';
        });



        builder.addCase(resentVerificationClientListMail.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(resentVerificationClientListMail.fulfilled, (state) => {
            state.loading = 'fetchSuccessfully';
            setNotification(message.CFPCareConnect, message.verificationEmailSuccessfully, false, 'success', 'resentVerificationClientListMail');
        });
        builder.addCase(resentVerificationClientListMail.rejected, (state) => {
            state.loading = 'error';
            setNotification(message.CFPCareConnect, message.verificationEmailFailed, false, 'failed', 'resentVerificationClientListMail');

        });


        // builder.addCase(addClientAdminSignInput.pending, (state) => {
        //     state.uploadedSignImg = '';
        //     state.imageSignId = null;
        //     state.loading = 'idle';
        // });

        // builder.addCase(addClientAdminSignInput.fulfilled, (state, action) => {
        //     state.uploadedSignImg = action.payload.sign_pic;
        //     state.imageSignId = action.payload.id;
        //     state.loading = 'logoUploaded';
        // });
        // builder.addCase(addClientAdminSignInput.rejected, (state) => {
        //     state.loading = 'error';
        // });


        // builder.addCase(removeClientAdminSignInput.pending, (state) => {
        //     state.loading = 'idle';
        // });

        // builder.addCase(removeClientAdminSignInput.fulfilled, (state) => {
        //     state.uploadedSignImg = '';
        //     state.imageSignId = null;
        //     state.loading = 'logoUploaded';
        // });
        // builder.addCase(removeClientAdminSignInput.rejected, (state) => {
        //     state.loading = 'error';
        // });


    }
});

// Action creators are generated for each case reducer function
export const { changeLoading } = clientListSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const ClientListSlices = (state: RootState) => state.clientListData;

export const clientListReducer = clientListSlice.reducer;