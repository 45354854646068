
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { setNotification } from "../../components/notification/ToastNotification";
import { message } from "../../utilities/locals/messages";
import { INursingStaffListData, INursingStaffSingleData } from "../../models/nursingStaffListInterfaces";
import { addNursingStaffData, addNursingStaffImageInput, editNursingStaffData, fetchNursingStaffList, nursingStaffUserApprove, removeNursingStaffImageInput, resentVerificationNursingStaffMail, updateNursingStaffData, updateNursingStaffUserStatus } from "../../services/nursingStaffListServices";
import { EMAIL_ALREADY_EXIST, MOBILE_ALREADY_EXIST } from "../../config/permissions";

export interface NursingStaffListState {
    loading: 'idle' | 'loading' | 'success' | 'fetchSuccessfully' | 'logoUploaded' | 'error';
    nursingStaffListData: INursingStaffListData[]
    nursingStaff: INursingStaffSingleData[];
    paginationTotalCount: number;
    uploadedImg: string;
    imageId: number | null;
}

const initialState: NursingStaffListState = {
    loading: 'idle',
    nursingStaffListData: [],
    paginationTotalCount: 0,
    nursingStaff: [],
    uploadedImg: '',
    imageId: null,
}

export const nursingStaffSlice = createSlice({
    name: "nursingStaffSlice",
    initialState,
    reducers: {
        changeNursingStaffLoading: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(fetchNursingStaffList.pending, (state) => {
            state.paginationTotalCount = 0;
            state.nursingStaffListData = [];
            state.loading = 'loading';
        });
        builder.addCase(fetchNursingStaffList.fulfilled, (state, action) => {
            state.paginationTotalCount = action.payload.count;
            state.nursingStaffListData = action.payload.results as INursingStaffListData[];
            state.loading = 'fetchSuccessfully';
        });
        builder.addCase(fetchNursingStaffList.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(addNursingStaffData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(addNursingStaffData.fulfilled, (state) => {
            state.loading = 'success';
            setNotification(message.CFPCareConnect, message.nursingStaffAddedSuccessfully, false, 'success', 'addNursingStaffData');

        });
        builder.addCase(addNursingStaffData.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            if (payload.response.data.error_message === EMAIL_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.emailExist, false, 'failed', 'addNursingStaffData');
            } else if (payload.response.data.error_message === MOBILE_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.mobileNumberExist, false, 'failed', 'addNursingStaffData');
            } else {
                setNotification(message.CFPCareConnect, message.nursingStaffAddedfailed, false, 'failed', 'addNursingStaffData');
            }
        });

        builder.addCase(updateNursingStaffData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(updateNursingStaffData.fulfilled, (state) => {
            state.loading = 'success';
            setNotification(message.CFPCareConnect, message.nursingStaffUpdatedSuccessfully, false, 'success', 'updateNursingStaffData');

        });
        builder.addCase(updateNursingStaffData.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            if (payload.response.data.error_message === EMAIL_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.emailExist, false, 'failed', 'updateNursingStaffData');
            } else {
                setNotification(message.CFPCareConnect, message.nursingStaffUpdatedfailed, false, 'failed', 'updateNursingStaffData');
            }
        });

        builder.addCase(editNursingStaffData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(editNursingStaffData.fulfilled, (state, action) => {
            state.loading = 'fetchSuccessfully';
            state.nursingStaff = [action.payload] as INursingStaffSingleData[];
        });
        builder.addCase(editNursingStaffData.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(updateNursingStaffUserStatus.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(updateNursingStaffUserStatus.fulfilled, (state, action) => {
            state.loading = 'success';
            const m = action.payload.message;
            setNotification(message.CFPCareConnect, m, false, 'success', 'updateNursingStaffUserStatus');
        });
        builder.addCase(updateNursingStaffUserStatus.rejected, (state) => {
            state.loading = 'error';
            setNotification(message.CFPCareConnect, message.updationFailed, false, 'failed', 'updateNursingStaffUserStatus');

        });

        builder.addCase(nursingStaffUserApprove.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(nursingStaffUserApprove.fulfilled, (state) => {
            state.loading = 'success';
            setNotification(message.CFPCareConnect, message.approvedSuccessfully, false, 'success', 'nursingStaffUserApprove');
        });
        builder.addCase(nursingStaffUserApprove.rejected, (state) => {
            state.loading = 'error';
            setNotification(message.CFPCareConnect, message.updationFailed, false, 'failed', 'nursingStaffUserApprove');

        });



        builder.addCase(addNursingStaffImageInput.pending, (state) => {
            state.uploadedImg = '';
            state.imageId = null;
            state.loading = 'idle';
        });

        builder.addCase(addNursingStaffImageInput.fulfilled, (state, action) => {
            state.uploadedImg = action.payload.profile_pic;
            state.imageId = action.payload.id;
            state.loading = 'logoUploaded';
        });
        builder.addCase(addNursingStaffImageInput.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(removeNursingStaffImageInput.pending, (state) => {
            state.loading = 'idle';
        });

        builder.addCase(removeNursingStaffImageInput.fulfilled, (state) => {
            state.uploadedImg = '';
            state.imageId = null;
            state.loading = 'logoUploaded';
        });
        builder.addCase(removeNursingStaffImageInput.rejected, (state) => {
            state.loading = 'error';
        });



        builder.addCase(resentVerificationNursingStaffMail.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(resentVerificationNursingStaffMail.fulfilled, (state) => {
            state.loading = 'fetchSuccessfully';
            setNotification(message.CFPCareConnect, message.verificationEmailSuccessfully, false, 'success', 'resentVerificationNursingStaffMail');
        });
        builder.addCase(resentVerificationNursingStaffMail.rejected, (state) => {
            state.loading = 'error';
            setNotification(message.CFPCareConnect, message.verificationEmailFailed, false, 'failed', 'resentVerificationNursingStaffMail');

        });


    }
});

export const { changeNursingStaffLoading } = nursingStaffSlice.actions;
export const NursingStaffSlices = (state: RootState) => state.nursingStaffData
export const nursingStaffReducer = nursingStaffSlice.reducer;