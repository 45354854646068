
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { setNotification } from "../../components/notification/ToastNotification";
import { message } from "../../utilities/locals/messages";
import { addPatientReg, addPatientRegImage,  removePatientRegImage } from "../../services/patientRegServices";
import { EMAIL_ALREADY_EXIST, MOBILE_ALREADY_EXIST } from "../../config/permissions";

export interface PatientRegistrationState {
    loading: 'idle' | 'loading' | 'success' | 'error'|'updatedSuccess';
    uploadedImg: string;
    imageId: number | null;
}

const initialState: PatientRegistrationState = {
    loading: 'idle',
    uploadedImg: '',
    imageId: null,
}

export const patientRegistrationSlice = createSlice({
    name: "patientRegistrationSlice",
    initialState,
    reducers: {
        changeLoading: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        },
        resetImage: (state) => {
            state.imageId=null;
            state.uploadedImg='';
        },
    },
    extraReducers: (builder) => {

        builder.addCase(addPatientRegImage.pending, (state) => {
            state.uploadedImg = '';
            state.imageId = null;
            state.loading = 'idle';
        });

        builder.addCase(addPatientRegImage.fulfilled, (state, action) => {
            state.uploadedImg = action.payload.profile_pic;
            state.imageId = action.payload.id;
            state.loading = 'updatedSuccess';
        });
        builder.addCase(addPatientRegImage.rejected, (state) => {
            state.loading = 'error';
        });


        
        builder.addCase(removePatientRegImage.pending, (state) => {
            state.loading = 'idle';
        });

        builder.addCase(removePatientRegImage.fulfilled, (state) => {
            state.uploadedImg = '';
            state.imageId = null;
            state.loading = 'updatedSuccess';
        });
        builder.addCase(removePatientRegImage.rejected, (state) => {
            state.loading = 'error';
        });

        
        builder.addCase(addPatientReg.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(addPatientReg.fulfilled, (state) => {
            state.loading = 'success';
            // setNotification(message.CFPCareConnect, message.userAddedSuccessfully, false, 'success', 'addPatientReg');

        });
        builder.addCase(addPatientReg.rejected, (state, { payload }: any) => {
            state.loading = 'error';

            if (payload.response.data.error_message === EMAIL_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.emailExist, false, 'failed', 'addPatientReg');
            } else if (payload.response.data.error_message === MOBILE_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.mobileNumberExist, false, 'failed', 'addPatientReg');
            } else {
                setNotification(message.CFPCareConnect, message.userAddedfailed, false, 'failed', 'addPatientReg');
            }

        });


    }
});

export const { changeLoading ,resetImage} = patientRegistrationSlice.actions;
export const PatientRegistrationSlices = (state: RootState) => state.patientRegistrationData
export const patientRegistrationReducer = patientRegistrationSlice.reducer;