import React, { useEffect, useRef, useState } from 'react'
import Table from '../../components/table'
import { PatientProvideSummaryHeader, timesheetHeader, timesheetSummaryHeader } from '../../components/table/tableHeaders'
import TextField from '../../components/inputFeild/TextFeild'
import SelectField from '../../components/inputFeild/SelectField'
import { ROLE_REPORTS, USER_ROLE_PROVIDER } from '../../config/permissions'
import ButtonType from '../../components/button/ButtonType'
import { useAppDispatch, useAppSelector } from '../../helpers/hook'
import { loginPageSlices } from '../loginPage/loginPageSlice'
import { CommonSlices } from '../common/commonSlice'
import { TimeSheetSlices, resetTimesheetList } from './timeSheetSlice'
import { exportTimesheetList, fetchPatientName, fetchTimesheetList, fetchTimesheetProviderList } from '../../services/timesheetListServices'
import { ITimesheetFetchListExport, ITimesheetFetchListState, ITimesheetSearch } from '../../models/timesheetInterfaces'
import { setNotification } from '../../components/notification/ToastNotification'
import { message } from '../../utilities/locals/messages'
import Paginations from '../../components/Pagination'


const TimeSheet = () => {
    const dispatch = useAppDispatch();
    const dropdownFirstNameRef = useRef<HTMLDivElement>(null);
    const dropdownLastNameRef = useRef<HTMLDivElement>(null);
    const { token, unid, clientName, client, loginUserRole, logedUserID } = useAppSelector(loginPageSlices);
    const { navFacility } = useAppSelector(CommonSlices);
    const { providerList, patientList, timesheetList, paginationTotalCount, individualTotalTime } = useAppSelector(TimeSheetSlices);

    // Assuming timesheetList is an array of objects with a 'time_taken' property
    // const calculateTotalTime = () => {
    //     let totalMinutes = 0;
    //     let totalSeconds = 0;

    //     // Iterate through each data object and calculate total minutes and seconds
    //     timesheetList.forEach(item => {
    //         const [minutes, seconds] = item.time.split(':').map(Number);
    //         totalMinutes += minutes;
    //         totalSeconds += seconds;
    //     });

    //     // Adjust totalSeconds if it exceeds 59
    //     totalMinutes += Math.floor(totalSeconds / 60);
    //     totalSeconds %= 60;

    //     return { minutes: totalMinutes, seconds: totalSeconds };
    // };

    // const formattedTotalTime = calculateTotalTime();

    const loginProviderListID: any = providerList.find(item => Number(item.user_id) === Number(logedUserID));
    const loginProviderName: string | undefined = providerList.find(item => Number(item.user_id) === Number(logedUserID))?.name;

    const todayDate = new Date().toISOString().split('T')[0];
    const timestamp = new Date().toISOString();

    const [sortField, setSortField] = useState("full_name");
    const [firstName, setfirstName] = useState<string>('');
    const [lastName, setlastName] = useState<string>('');
    const [provider, setprovider] = useState<string>('');
    // const [timeTaken, setTimeTaken] = useState<string>('');
    const [fromDate, setFromDate] = useState<string>('');
    const [toDate, setToDate] = useState<string>('');
    // const [validation, setValidation] = useState<boolean>(false);
    const [firstNameSearch, setfirstNameSearch] = useState<boolean>(false);
    const [tableType, setTableType] = useState<number>(1);
    const [showFirstName, setshowFirstName] = useState<boolean>(false);
    const [showLastName, setshowLastName] = useState<boolean>(false);


    //Paginaton
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25;

    useEffect(() => {
        if (navFacility && navFacility.length > 0) {
            dispatch(fetchTimesheetProviderList({ unid, token, client, facility: navFacility }));
        }
    }, [navFacility]);

    useEffect(() => {
        dispatch(resetTimesheetList([]));
    }, [])

    //to hide to message dropdown while clicking outside of dashboard
    useEffect(() => {
        const closeSelects = (e: MouseEvent) => {
            if (!e.target || !(e.target instanceof Node)) return;
            if (!dropdownFirstNameRef.current?.contains(e.target as Node)) {
                setshowFirstName(false);
            }
            if (!dropdownLastNameRef.current?.contains(e.target as Node)) {
                setshowLastName(false);
            }
        };

        document.addEventListener('mousedown', closeSelects);

        return () => {
            document.removeEventListener('mousedown', closeSelects);
        };
    }, []);

    const handleGenerateTimesheet = (e: any, export_type: number) => {
        setCurrentPage(1);
        e.preventDefault();
        setTableType(export_type);
        if (firstName === "" && lastName === "" && provider === "" && fromDate === "" && toDate === "") {
            setNotification(message.CFPCareConnect, message.atleastOneFields, false, 'failed', 'handleGenerateTimesheet');
            // setValidation(true);

        } else {
            const req: ITimesheetFetchListState = {
                token,
                unid,
                first_name: firstName,
                last_name: lastName,
                provider: (loginUserRole !== USER_ROLE_PROVIDER) ? Number(provider) : Number(loginProviderListID.id),
                // duration:Number(timeTaken),
                date_from: fromDate,
                date_to: toDate,
                facility: navFacility,
                client,
                export_type,
                page: 1,
            };
            dispatch(fetchTimesheetList(req));
        }

    }

    const handleResetFilter = () => {
        setfirstName('');
        setlastName('');
        setprovider('');
        //  setTimeTaken('');
        setFromDate('');
        setToDate('');
        // setValidation(false);

    }


    // pagination    
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        const req: ITimesheetFetchListState = {
            token,
            unid,
            first_name: firstName,
            last_name: lastName,
            provider: (loginUserRole !== USER_ROLE_PROVIDER) ? Number(provider) : Number(loginProviderListID.id),
            // duration:Number(timeTaken),
            date_from: fromDate,
            date_to: toDate,
            facility: navFacility,
            client,
            export_type: tableType,
            page: page,
        };
        dispatch(fetchTimesheetList(req));
    };


    //Export function
    const handleExportTimesheet = () => {
        const req: ITimesheetFetchListExport = {
            token,
            unid,
            first_name: firstName,
            last_name: lastName,
            provider: (loginUserRole !== USER_ROLE_PROVIDER) ? Number(provider) : Number(loginProviderListID.id),
            providerName: (loginUserRole !== USER_ROLE_PROVIDER) ? providerList.find(item => Number(item.id) === Number(provider)) ? providerList.find(item => Number(item.id) === Number(provider))?.name : '' : loginProviderName ? loginProviderName?.toString() : '',
            // duration:Number(timeTaken),
            date_from: fromDate,
            date_to: toDate,
            export_type: tableType,
            facility: navFacility.join(','),
            client_name: clientName,
            client, timestamp,
        };
        dispatch(exportTimesheetList(req));
    }

    const handleSearch = (value: string, field: string) => {
        if (field === 'firstName') {
            setfirstName(value);
            // setValidation(false);
            setfirstNameSearch(true);
        } else if (field === 'lastName') {
            setlastName(value);
            // setValidation(false);
            setfirstNameSearch(false);
        }
        const req: ITimesheetSearch = {
            token,
            unid,
            first_name: (field === 'firstName') ? value : "",
            last_name: (field === 'lastName') ? value : "",
            facility: navFacility,
            client,
            user_id: logedUserID,
        };
        dispatch(fetchPatientName(req));
    }

    return (
        <div className='w-full'>
            <div className="flex justify-between items-center  pt-9 pl-12 pr-8">
                <div>
                    <h1 className='text-2xl text-[var(--color-212121)] '>Timesheet</h1>
                </div>
                <div>
                </div>
            </div>
            <form className='pl-12 pr-8' >
                <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                    <div className='common-dropdown w-full'>
                        <div className='extra-space '></div>


                        <TextField
                            className={`${false && 'border-[var(--color-danger)]'} z-[1]`}
                            value={firstName}
                            onClick={() => { setfirstNameSearch(true); setshowFirstName(true); }}
                            onChange={(e) => handleSearch(e.target.value, 'firstName')}
                            label='Patient First Name'
                            placeholder='Patient First Name' />


                        <div className={`${(firstNameSearch && patientList.length > 0 && firstName.length > 0 && showFirstName) ? 'block dropdown-list min-w-[250px] top-[75px] p-4' : 'hidden'}  `} ref={dropdownFirstNameRef}>
                            <ul className="custom-scrollBar max-h-[250px]">
                                {patientList.map(option => (
                                    <li
                                        key={option.id}
                                        className={`text-[var(--color-757575)]  text-base font-medium tracking-[0.05px] hover:text-[var(--color-0048D4)] my-[10px] cursor-pointer me-6`}
                                        onClick={() => {
                                            if (option.patient_first_name) {
                                                setfirstName(option.patient_first_name);
                                                setshowFirstName(false);
                                            }
                                        }}
                                    >
                                        <label htmlFor={option.id} className='flex items-center gap-2 justify-start'>
                                            {option.patient_first_name}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className='common-dropdown w-full'>
                        <div className='extra-space '></div>

                        <TextField
                            className={`${false && 'border-[var(--color-danger)]'} z-[1]`}
                            value={lastName}
                            onClick={() => { setfirstNameSearch(false); setshowLastName(true); }}
                            onChange={(e) => handleSearch(e.target.value, 'lastName')}
                            label='Patient Last Name'
                            placeholder='Patient Last Name' />


                        <div className={`${(!firstNameSearch && patientList.length > 0 && lastName.length > 0 && showLastName) ? ' block dropdown-list min-w-[250px] top-[75px] p-4' : 'hidden'}  `} ref={dropdownLastNameRef}>
                            <ul className="custom-scrollBar max-h-[250px]">
                                {patientList.map(option => (
                                    <li
                                        key={option.id}
                                        className={`text-[var(--color-757575)]  text-base font-medium tracking-[0.05px] hover:text-[var(--color-0048D4)] my-[10px] cursor-pointer me-6`}
                                        onClick={() => {
                                            if (option.patient_last_name) {
                                                setlastName(option.patient_last_name);
                                                setshowLastName(false);
                                            }
                                        }}
                                    >
                                        <label htmlFor={option.id} className='flex items-center gap-2 justify-start'>
                                            {option.patient_last_name}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                </div>
                {(loginUserRole !== USER_ROLE_PROVIDER) && <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                    <SelectField
                        className={`${false && 'border-[var(--color-danger)]'}`}
                        label='Associate/Provider Name'
                        value={provider}
                        onChange={(e) => {
                            setprovider(e.target.value);
                            // setValidation(false);
                        }}
                        placeHolder={true}
                        options={[{ id: '', name: 'Select Associate/Provider ' }, ...providerList]}
                    />
                    <div className='w-full'></div>
                </div>}
                <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                    {/* <SelectField

                        // value={timeTaken}
                        // onChange={(e) => setTimeTaken(e.target.value)}
                        label='Duration'
                        placeHolder={false}
                        disabled={true}
                        options={[{ name: 'Between', id: '1' }]}
                    // options={[{ name: 'Select Duration', id: '' }, { name: '10 min', id: '10' }, { name: '15 min', id: '15' }, { name: '20 min', id: '20' }]}
                    /> */}

                    <TextField

                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        label='Duration From'
                        type='date'
                        max={todayDate}
                        placeholder='From' />
                    <TextField

                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        label='To'
                        min={fromDate}
                        // max={todayDate}
                        type='date'
                        placeholder='To' />
                </div>

                <div className='w-full mt-9 flex gap-4 items-center justify-end'>
                    <ButtonType
                        type='webCardbtn'
                        buttonLabel='Clear'
                        onClick={() => handleResetFilter()}
                        className='px-12 !important bg-[var(--color-white)] !important imp-text-424242 border  hover:bg-[var(--color-0000000a)]' />



                    <ButtonType
                        type='webCardbtn'
                        buttonLabel='Individual Timesheet'
                        onClick={(e) => handleGenerateTimesheet(e, 1)}
                        className='px-12 bg-[var(--color-0048D4)]' />

                    {(loginUserRole !== USER_ROLE_PROVIDER && provider === "") && <ButtonType
                        type='webCardbtn'
                        buttonLabel='Patient Provider Summary'
                        onClick={(e) => handleGenerateTimesheet(e, 2)}
                        className='px-12 bg-[var(--color-0048D4)]' />}

                    <ButtonType
                        type='webCardbtn'
                        buttonLabel='Timesheet Summary'
                        onClick={(e) => handleGenerateTimesheet(e, 3)}
                        className='px-12 bg-[var(--color-0048D4)]' />


                </div>
            </form>

            <div className='pl-12 pr-8 mt-9'>

                {/* {tableType === 2 ? <Table
                    parentPage={ROLE_REPORTS}
                    setPermission={false}
                    titles={timesheetSummaryHeader}
                    data={timesheetList}
                    sortedFieldName={sortField}
                    currentPage={1}
                    numRowsPerPage={250}
                    handleSort={(sortField: string) => setSortField(sortField)}
                // className={Math.ceil(paginationTotalCount / itemsPerPage) > 1 ? '' : '  '}
                // onClickView={onClickView}
                // onClickTableData={onClickTableData}
                /> : */}
                <Table
                    parentPage={ROLE_REPORTS}
                    setPermission={false}
                    // titles={tableType === 1 ? timesheetHeader : timesheetSummaryHeader}
                    titles={tableType === 3 ? timesheetSummaryHeader : tableType === 2 ? PatientProvideSummaryHeader : timesheetHeader}
                    data={timesheetList}
                    sortedFieldName={sortField}
                    currentPage={currentPage}
                    numRowsPerPage={25}
                    handleSort={(sortField: string) => setSortField(sortField)}
                // className={Math.ceil(paginationTotalCount / itemsPerPage) > 1 ? '' : '  '}
                // onClickView={onClickView}
                // onClickTableData={onClickTableData}
                />
                {/* } */}
            </div>

            <Paginations
                totalItems={paginationTotalCount}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
            />
            {timesheetList.length > 0 && <>
                <div className={`w-full pl-12 pr-8 flex items-center justify-between ${paginationTotalCount > itemsPerPage ? ' mb-9 ' : 'my-9 '}`}>
                    <p>Total Time Taken: {individualTotalTime}</p>
                    {/* <p>Total Time Taken: {tableType !== 1 ? <> {`${formattedTotalTime.minutes} minutes, ${formattedTotalTime.seconds} seconds`}</> : <>{individualTotalTime}</>} </p> */}
                    <div></div>
                    <ButtonType
                        type='webCardbtn'
                        buttonLabel='Export'
                        onClick={() => handleExportTimesheet()}
                        className='px-12 bg-[var(--color-0048D4)]' />
                </div></>}
        </div>
    )
}

export default TimeSheet