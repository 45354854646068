import { createAsyncThunk } from "@reduxjs/toolkit";
import {   postFormDataWithoutAuth, postWithoutAuth } from "./apiService";
import {   URL_PATIENT_REG, URL_PATIENT_REG_REMOVE_IMG, URL_PATIENT_REG_UPLOAD_IMG } from "../config/apiUrls";
import { IImageInputWithoutToken, IRemoveImageWithoutToken } from "../models/commonInterfaces";
import { IPatientRegData } from "../models/patientRegInterface";



// FUNCTION FOR VALIDATE USERNAME AND PASSWOR
export const addPatientReg = createAsyncThunk(
  'client/addPatientReg',
  async (form: IPatientRegData, thunkAPI) => {
    try {
      const response = postWithoutAuth(URL_PATIENT_REG, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

  
export const addPatientRegImage = createAsyncThunk(
    'user/addPatientRegImage',
    async (form: IImageInputWithoutToken, thunkAPI) => {
        try {
            const response = postFormDataWithoutAuth(URL_PATIENT_REG_UPLOAD_IMG, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
  );
  
  export const removePatientRegImage = createAsyncThunk(
    'user/removePatientRegImage',
    async (form: IRemoveImageWithoutToken, thunkAPI) => {
        try {
            const response = postWithoutAuth(URL_PATIENT_REG_REMOVE_IMG, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
  );


  





