import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { fetchRoleList, fetchSingleRoleData, handleChangeRole } from "../../services/rolesAndPermissions";
import { IRolesListData, IRolesSingleData } from "../../models/rolesAndPermissionInterfaces";
import { setNotification } from "../../components/notification/ToastNotification";
import { message } from "../../utilities/locals/messages";

export interface RolesListState {
    loading: 'idle' | 'loading' | 'success' | 'fetchSuccessfully' |'logoUploaded'| 'error';
    rolesListData: IRolesListData[];
    rolesSingleData: IRolesSingleData;
    viewRolePermission: IRolesSingleData;
    disabledPermission: IRolesSingleData;
    roleName:string;
}

const initialState: RolesListState = {
    loading: 'idle',
    rolesListData: [],
    rolesSingleData: {},
    viewRolePermission: {},
    disabledPermission: {},
    roleName:"",
}

export const rolesListSlice = createSlice({
    name: "rolesListSlice",
    initialState,
    reducers: {
        changerolesLoading: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(fetchRoleList.pending, (state) => {
            state.rolesListData = [];
            state.loading = 'loading';
        });
        builder.addCase(fetchRoleList.fulfilled, (state, action) => {
            state.rolesListData = action.payload as IRolesListData[];
            state.loading = 'fetchSuccessfully';
        });
        builder.addCase(fetchRoleList.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(handleChangeRole.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(handleChangeRole.fulfilled, (state) => {
            state.loading = 'success';
            setNotification(message.CFPCareConnect, message.updationSuccessfully, false, 'success', 'handleChangeRole');

        });
        builder.addCase(handleChangeRole.rejected, (state) => {
            state.loading = 'error';
            setNotification(message.CFPCareConnect, message.updationFailed, false, 'failed', 'handleChangeRole');

        });

        builder.addCase(fetchSingleRoleData.pending, (state) => {
            state.rolesSingleData = {};
            state.rolesSingleData = {};
            state.disabledPermission = {};
            state.roleName = "";
            state.loading = 'loading';
        });
        builder.addCase(fetchSingleRoleData.fulfilled, (state, action) => {
            state.rolesSingleData = action.payload.role_permission as IRolesSingleData;
            state.viewRolePermission = action.payload.permissible_permissions as IRolesSingleData;
            state.disabledPermission = action.payload.permanent_permissions as IRolesSingleData;            
            state.roleName = action.payload.role_name;
            state.loading = 'fetchSuccessfully';
        });
        builder.addCase(fetchSingleRoleData.rejected, (state) => {
            state.loading = 'error';
        });

    }
});

export const { changerolesLoading } = rolesListSlice.actions;
export const RolesListSlices = (state: RootState) => state.rolesListData;
export const rolesListReducer = rolesListSlice.reducer;