import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material'

interface ModalHeaderProps {
    title: string;
    onClick: () => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = React.memo((props) => {
    const bgColor =  'bg-[var(--color-253179)]';
    const textColor =  'text-[var(--color-white)]' ;
    return (
        <div className={"flex justify-between items-center  px-9 py-5  border-b border-[var(--color-0048d433)] " + bgColor} >
            <h2 className={'text-xl font-medium tracking-[0.5px] ' + textColor}>{props.title}</h2>
            <IconButton onClick={props.onClick}> <CloseIcon className={textColor}/></IconButton>
        </div>
    )
});

export default ModalHeader