import React, { useState, useEffect } from 'react'
import TextField from '../../components/inputFeild/TextFeild'
import ButtonType from '../../components/button/ButtonType'
// import logoIcon from '../../assets/images/loginIcon.svg'
import logo from '../../assets/images/careConnectIcon.png';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { useLocation, useNavigate } from 'react-router-dom';
import { ResetPasswordSlices } from './resetPasswordSlice';
import { IResetPassword } from '../../models/resetPasswordInterfaces';
import { resetPasswordAction } from '../../services/resetPasswordServices';
import { setNotification } from '../../components/notification/ToastNotification';
import { message } from '../../utilities/locals/messages';
import Footer from '../../components/footer/Footer';
import { USER_ROLE_PATIENTS, USER_ROLE_RELATIVES } from '../../config/permissions';
import { ROUTE_LOGIN, ROUTE_LOGIN_PATIENT } from '../../config/routeConfig';



const ResetPassword = () => {

    const navigate = useNavigate();
    const search = useLocation().search;
    const dispatch = useAppDispatch();

    const f_token = new URLSearchParams(search).get("f_token");
    const token = new URLSearchParams(search).get("token");
    const user = new URLSearchParams(search).get("user");
    const expiryTime = new URLSearchParams(search).get("expiry_time");
    // const expiry_time = new URLSearchParams(search).get("expiry_time");

    const { loading, userRole } = useAppSelector(ResetPasswordSlices);

    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [password, setpassword] = useState<string>('');

    //navigate to login page when password is reseted
    useEffect(() => {
        if (loading === "success") {
            if (userRole === USER_ROLE_PATIENTS || userRole === USER_ROLE_RELATIVES) {
                navigate(ROUTE_LOGIN_PATIENT);
            } else {
                navigate(ROUTE_LOGIN);
            }
        }
    }, [loading]);

    //handle reset password
    const handleSave = (e: any) => {
        e.preventDefault();
        if (password === confirmPassword) {
            const req: IResetPassword = {
                fToken: f_token,
                token: token,
                userId: user,
                new_password: password,
                expiryTime: expiryTime,
            };
            dispatch(resetPasswordAction(req));
        } else {
            setNotification(message.CFPCareConnect, message.passwordMismatches, false, "failed", "authenticateUserfailed");

        }
    }

    return (
        <>


            <div className="flex w-full flex-col justify-between">
                <div className='min-h-[calc(100vh-49px)] bg-[var(--color-F5F5F5)]'>

                    <header className=" bg-[var(--color-F5F5F5)]">
                        <nav className="flex items-center justify-center w-100 py-[37px] px-[37px] lg:px-[62px] ">
                            <div className="flex items-center justify-between w-full max-w-[1436px] ">
                                <div className="flex items-center gap-3">
                                    <img
                                        className="h-[57px] w-auto"
                                        src={logo}
                                        alt=""
                                        loading='lazy'
                                        decoding='async'
                                    />
                                    <h1 className="text-[var(--color-253179)] text-xl font-bold tracking-[0.05px] ">CFP Care Connect</h1>
                                </div>

                            </div>
                        </nav>
                    </header>


                    <div className=' bg-[var(--color-F5F5F5)] flex justify-center items-center px-6 py-[42px] lg:px-8'>
                        <div className=" bg-[var(--color-white)] flex min-h-full max-w-[488px] rounded-xl flex-1 flex-col justify-center p-9 gap-12">
                            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                                {/* <img
                                    className="mx-auto h-[60px] w-auto"
                                    src={logoIcon}
                                    alt="Your Company"
                                    loading='lazy'
                                    decoding='async'
                                /> */}
                                <h2 className="mt-6 text-center text-3xl font-medium tracking-[0.05px] text-[var(--color-212121)]">
                                    Reset your password
                                </h2>
                            </div>

                            <div className="">
                                <form className="" onSubmit={(e) => handleSave(e)}>
                                    <div>
                                        <label htmlFor="" className='flex flex-col gap-3'>
                                            <span className='text-[15px] text-[var(--color-212121)] tracking-[0.05px] '>Password</span>
                                            <TextField
                                                required={true}
                                                placeholder='Password'
                                                type='password'
                                                autoComplete="current-password"
                                                value={password}
                                                onChange={(e) => setpassword(e.target.value)}
                                            />
                                        </label>
                                    </div>


                                    <div className='flex flex-col gap-4 mt-9'>

                                        <label htmlFor="" className='flex flex-col gap-3'><span className='text-[15px] text-[var(--color-212121)] tracking-[0.05px] '>Confirm password</span>

                                            <TextField
                                                required={true}
                                                placeholder='Confirm password'
                                                autoComplete="password"
                                                type='password'
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                        </label>


                                    </div>

                                    <div>
                                        <ButtonType type="webCardbtn" buttonLabel='Save' className='w-full py-4 font-medium text-xl rounded-[30px] mt-8 ' buttonType='submit'
                                        />
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full bg-[var(--color-F5F5F5)]">
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default ResetPassword;