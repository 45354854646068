import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_ADD_FACILITY, URL_EDIT_FACILITY, URL_FACILITY_LIST, URL_REMOVE_IMAGE, URL_STATUS_FACILITY, URL_UPDATE_FACILITY, URL_UPLOAD_IMAGE } from "../config/apiUrls";
import { get, post, postFormData, put } from "./apiService";
import { IFacilityData, IFacilityState, IFacilityStatus } from "../models/facilityListInterfaces";
import { IImageInput, IRemoveImage } from "../models/commonInterfaces";

// to fetch all facility
export const fetchFacilityList = createAsyncThunk(
    'user/fetchFacilityList',
    async ({ token, unid, client, page, sort, status_filter }: IFacilityState, thunkAPI) => {
        const headerData = { token, unid };
        try {
            let url = URL_FACILITY_LIST + '?client=' + client;
            if (page !== 0) {
                url = url + '&page=' + page
            }
            if (sort && sort !== '') {
                url = url + '&sort=' + sort
            }
            if (status_filter && status_filter !== 'all') {
                url = url + '&filter=' + status_filter
            }
            const response = get(url, headerData);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

//to add facility
export const addFacilityData = createAsyncThunk(
    'user/addFacilityData',
    async (form: IFacilityData, thunkAPI) => {
        try {
            const response = post(URL_ADD_FACILITY, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

//to update facility
export const updateFacilityData = createAsyncThunk(
    'user/updateFacilityData',
    async (form: IFacilityData, thunkAPI) => {
        try {
            const { id, ...body } = form;
            const url = URL_UPDATE_FACILITY + id;
            const response = put(url, body);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// to get singal facility details
export const editFacilityData = createAsyncThunk(
    'user/editFacilityData',
    async ({ token, unid, client }: IFacilityState, thunkAPI) => {
        const headerData = { token, unid };
        try {
            const url = URL_EDIT_FACILITY + client;
            const response = get(url, headerData);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

//to update status on facility list
export const updateFacilityStatus = createAsyncThunk(
    'user/updateFacilityStatus',
    async (form: IFacilityStatus, thunkAPI) => {
        try {
            const response = put(URL_STATUS_FACILITY, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const addFacilityImageInput = createAsyncThunk(
    'user/addFacilityImageInput',
    async (form: IImageInput, thunkAPI) => {
        try {
            const response = postFormData(URL_UPLOAD_IMAGE, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const removeFacilityImageInput = createAsyncThunk(
    'user/removeFacilityImageInput',
    async (form: IRemoveImage, thunkAPI) => {
        try {
            const response = post(URL_REMOVE_IMAGE, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);