import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFile, post } from "./apiService";
import { IHeaderTimesheet, ITimesheetFetchListExport, ITimesheetFetchListState, ITimesheetSearch } from "../models/timesheetInterfaces";
import { URL_EXPORT_TIMESHEET, URL_TIMESHEET_LIST, URL_TIMESHEET_PATIENT, URL_TIMESHEET_PROVIDER } from "../config/apiUrls";


export const fetchTimesheetProviderList = createAsyncThunk(
  'userTimesheet/fetchTimesheetProviderList',
  async (form: IHeaderTimesheet, thunkAPI) => {
    try {
      const response = post(URL_TIMESHEET_PROVIDER, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);



export const fetchTimesheetList = createAsyncThunk(
  'user/fetchTimesheetList',
  async (form: ITimesheetFetchListState, thunkAPI) => {
    try {

      const { page, ...body } = form;
      let url = URL_TIMESHEET_LIST;
      if (page !== 0) {
        url = url + '?page=' + page
      }
      const response = post(url, body);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const fetchPatientName = createAsyncThunk(
  'user/fetchPatientName',
  async (form: ITimesheetSearch, thunkAPI) => {
    try {
      const response = post(URL_TIMESHEET_PATIENT, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const exportTimesheetList = createAsyncThunk(
  'user/exportTimesheetList',
  async (form: ITimesheetFetchListExport, thunkAPI) => {
    try {
      const { token, client_name, unid, timestamp, providerName, ...body } = form;
      getFile(URL_EXPORT_TIMESHEET,`${client_name} ${timestamp} ${body.export_type === 3 ? providerName : ''}`, { token, unid }, body);
      // return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

