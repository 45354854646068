import React, { useMemo } from 'react';
import success from '../../assets/images/successNotificationIcon.svg'
import failed from '../../assets/images/failedNotificationIcon.svg'
import { Store } from "react-notifications-component";

const NotificationComponent: React.FC<{
  title: string;
  description: string;
  textType: boolean;
  boldContent?: string;
  type: string;
}> = ({ title, description, textType, type,boldContent }) => {
  const message = useMemo(() => {
    let content;
    if (textType) {
      const firstIndex = description.indexOf('<<$$>>');
      const secondIndex = description.indexOf('<<$$>>', firstIndex + 1);

      // Check if both "<<$$>>" occurrences are found
      if (firstIndex !== -1 && secondIndex !== -1) {
        // Extract the three parts
        const a = description.substring(0, firstIndex).trim();
        // const b = description.substring(firstIndex + 6, secondIndex).trim(); // 6 is the length of "<<$$>>"
        const c = description.substring(secondIndex + 6).trim(); 

        return content = (<p className='text-[var(--color-757575)] text-sm'>{a} <span className='text-[var(--color-424242)] font-medium'> {boldContent} </span>{c}</p>);
      }

    } else {
      content = <p className='text-[var(--color-757575)] text-sm'>{description}</p>;
    }
    return <>{content}</>;
  }, [description, textType]);


  return (
    <div className='flex gap-3 p-6 items-start bg-[var(--color-FFF)] shadow-[0px_4px_10px_0px_rgba(0,0,0,0.20)] rounded-lg min-w-[488px]  max-w-[596px]'>
      <img src={type === "success" ? success : failed} alt="icon" />
      <div>
        <h1 className='text-[var(--color-212121)] text-sm mb-3'>{title}</h1>
        {message}
      </div>
    </div>
  );
};

export const setNotification = (title: string, description: string, textType: boolean, type: string, id: string,boldContent?: string) => {
  return Store.addNotification({
    title: title,
    content: (
      <NotificationComponent
        title={title}
        description={description}
        textType={textType}
        boldContent={boldContent}
        type={type}
      />
    ),
    type: "default",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 2000,
      // onScreen: true, 
      pauseOnHover: true,
    },
  });
};
