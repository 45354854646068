import React, { useEffect, useState } from 'react'
import ButtonType from '../../components/button/ButtonType'
import plusIcon from '../../assets/images/plusIcon.svg'
import Table from '../../components/table'
import { nursingStaffHeader } from '../../components/table/tableHeaders'
import { Box, Modal } from '@mui/material'
import TextField from '../../components/inputFeild/TextFeild'
import PhoneInputFeild from '../../components/inputFeild/PhoneInputFeild'
import SelectField from '../../components/inputFeild/SelectField'
import { useAppDispatch, useAppSelector } from '../../helpers/hook'
import { loginPageSlices } from '../loginPage/loginPageSlice'
import { CommonSlices } from '../common/commonSlice'
import { INursingStaffData, INursingStaffState, INursingStaffStatus } from '../../models/nursingStaffListInterfaces'
import { addNursingStaffData, addNursingStaffImageInput, editNursingStaffData, fetchNursingStaffList, nursingStaffUserApprove, removeNursingStaffImageInput, resentVerificationNursingStaffMail, updateNursingStaffData, updateNursingStaffUserStatus } from '../../services/nursingStaffListServices'
import { NursingStaffSlices } from './nursingStaffListSlice'
import ModalHeader from '../../components/modal/ModalHeader'
import { setNotification } from '../../components/notification/ToastNotification'
import { message } from '../../utilities/locals/messages'
import ConfirmationMessage from '../../components/notification/ConfirmationMessage'
import MultiSelectField from '../../components/inputFeild/MultiSelectField'
import ImageInput from '../../components/inputFeild/ImageInput'
import { DEFAULT_COUNTRY_US, LOGO_UPLOAD_OTHERS, LOGO_UPLOAD_PROFILE_PIC, NURSING_STAFF, P_ACT_ADD_EDIT, ROLE_NURSING_STAFF, USER_ROLE_CLIENT_ADMIN, USER_ROLE_CLIENT_ASSOCIATE, useHasActionPermission, useHasViewPermission } from '../../config/permissions'
import { IImageInput, IRemoveImage, IResentEmail } from '../../models/commonInterfaces'
import Paginations from '../../components/Pagination'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: '12px',
    overflow: 'hidden',
    boxShadow: 24,
};

const NursingStaffList = () => {
    const { token, unid, client, logedUsername, loginUserRole } = useAppSelector(loginPageSlices);
    const { navFacility, facilityData } = useAppSelector(CommonSlices);
    const { nursingStaffListData, paginationTotalCount, nursingStaff, loading, imageId, uploadedImg } = useAppSelector(NursingStaffSlices);
    const dispatch = useAppDispatch();
    //PERMISSION
    const checkViewPermission = useHasViewPermission(ROLE_NURSING_STAFF);
    const hasResendEmailPermission = (loginUserRole === USER_ROLE_CLIENT_ADMIN || loginUserRole === USER_ROLE_CLIENT_ASSOCIATE);

    //Paginaton
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25;

    const [firstName, setFirstName] = useState<string>('');
    const [middleName, setMiddleName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [profileImage, setProfileImage] = useState<string>('');
    const [imageProId, setImageProId] = useState<number | null>(null);
    const [gender, setGender] = useState<string>('');
    const [facility, setFacility] = useState<number[]>([]);
    const [email, setEmail] = useState<string>('');
    const [resendEmail, setresendEmail] = useState<boolean>(false);
    const [userEmailId, setUserEmailId] = useState<number>();
    const [mobile, setMobile] = useState('');
    const [alternativeMobile, setAlternativeMobile] = useState('');
    const [emailNotification, setEmailNotification] = useState<string>('2');

    const [sortField, setSortField] = useState("full_name");
    const [openCreateStaff, setOpenCreateStaff] = useState<boolean>(false);
    const [viewOnly, setviewOnly] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>('');
    const [showStatusConfirmation, setShowStatusConfirmation] = useState<boolean>(false);
    const [storeStatus, setStoreStatus] = useState<boolean>(false);
    const [storeStatusData, setStoreStatusData] = useState<any>();
    const [addNursingStaff, setAddNursingStaff] = useState<boolean>(true);
    const [showApproveConfirmation, setShowApproveConfirmation] = useState<boolean>(false);
    const [storeApproveData, setStoreApproveData] = useState<any>();

    //initial fetching the data to display item on table
    useEffect(() => {
        if (checkViewPermission && (navFacility && navFacility.length > 0)) {
            const req: INursingStaffState = {
                unid, token, facility: navFacility, client: client, user: logedUsername, group: loginUserRole, user_type: NURSING_STAFF, page: currentPage
            };
            dispatch(fetchNursingStaffList(req));
        }
    }, [navFacility])

    //use to reload the table data when dispatch oru other functionality is applied
    useEffect(() => {
        if (loading === 'success' && checkViewPermission) {
            const req: INursingStaffState = {
                unid, token, facility: navFacility, client: client, user: logedUsername, group: loginUserRole, user_type: NURSING_STAFF, page: currentPage
            };
            dispatch(fetchNursingStaffList(req));

            if (openCreateStaff) {
                handleNursingStaffReset();
            }
        }
    }, [loading]);

    //to handle edit nursingstafflist 
    useEffect(() => {
        if (nursingStaff.length > 0) {
            const userDetails = nursingStaff[0]
            setAddNursingStaff(false);
            setFirstName(userDetails.first_name);
            setMiddleName(userDetails.middle_name);
            setLastName(userDetails.last_name);
            setGender(userDetails.gender.toString());
            setFacility(userDetails.facility);
            setEmail(userDetails.email);
            setresendEmail(userDetails.email_verified);
            setUserEmailId(userDetails.user_id);
            setMobile(userDetails.mobile);
            setAlternativeMobile(userDetails.alternate_mobile);
            setEmailNotification(userDetails.notifications ? userDetails.notifications.toString() : '2');
            setProfileImage(userDetails.profile_pic_url);
            setImageProId(userDetails.profile_pic)
        }
    }, [nursingStaff]);

    useEffect(() => {
        setImageProId(imageId);
        setProfileImage(uploadedImg);
    }, [imageId, uploadedImg])


    //save button function on modal
    const handleSaveNursingStaffData = (e: any) => {
        e.preventDefault();
        if (firstName.length > 0 && lastName.length > 0 && facility.length > 0 && email.length) {
            if (mobile.length < 2 || mobile.length > 6) {

                const req: INursingStaffData = {
                    unid,
                    token,
                    uuid: userId,
                    client: client,
                    facility: facility,
                    gender: Number(gender),
                    profile_pic: imageProId,
                    sign: null,
                    sign_base: '',
                    notifications: Number(emailNotification),
                    user_info: {
                        first_name: firstName,
                        middle_name: middleName,
                        last_name: lastName,
                        email: email,
                        mobile: mobile,
                        alternate_mobile: alternativeMobile
                    },
                }
                if (addNursingStaff) {
                    //for add client
                    dispatch(addNursingStaffData(req));
                } else {
                    //for update client
                    dispatch(updateNursingStaffData(req));
                }
            } else {
                setNotification(message.CFPCareConnect, message.invalidMobile, false, 'failed', 'handleSaveNursingStaffData');
            }
        } else {
            setNotification(message.CFPCareConnect, message.fillOutAllMandatoryFields, false, 'failed', 'handleSaveNursingStaffData');
        }

    }

    //edit click on table
    const handleClickEdit = (data: any) => {
        const uuid = data.uuid;
        setUserId(uuid);
        const req: INursingStaffState = {
            unid, token, uuid
        };
        dispatch(editNursingStaffData(req));
        setOpenCreateStaff(true);
    }

    //View click on table
    const handleClickView = (data: any) => {
        setviewOnly(true);
        handleClickEdit(data);
    }
    //function handles Deactivate and Activation of client 
    const handleConfiramation = () => {
        const uuid = storeStatusData.uuid
        const req: INursingStaffStatus = {
            unid, token, uuid, action: storeStatusData.active ? 1 : 2
        };
        dispatch(updateNursingStaffUserStatus(req));
        setShowStatusConfirmation(false);
    }

    //confirmation popup 
    const handleClickStatus = (data: any) => {
        setShowStatusConfirmation(true);
        setStoreStatusData(data);
        setStoreStatus(data.active)
    }

    //confirmation approve popup 
    const handleClickApprove = (data: any) => {
        setShowApproveConfirmation(true);
        setStoreApproveData(data);
    }
    //function handles Approve 
    const handleConfiramationApprove = () => {
        const uuid = storeApproveData.uuid
        const req: INursingStaffStatus = {
            unid, token, uuid
        };
        dispatch(nursingStaffUserApprove(req));
        setShowApproveConfirmation(false);
    }
    //for reset all value in modal
    const handleNursingStaffReset = () => {
        setFirstName('');
        setMiddleName('');
        setLastName('');
        setGender('');
        setFacility([]);
        setEmail('');
        setresendEmail(false);
        setMobile('');
        setEmailNotification('2');
        setAlternativeMobile('');
        setProfileImage("");
        setOpenCreateStaff(!openCreateStaff);
        setviewOnly(false);
        setAddNursingStaff(true);
        setUserId('');
    }


    //for add profile image
    const handleAddImage = (selectedFile: File) => {
        const req: IImageInput = {
            token, unid, image: selectedFile, type: LOGO_UPLOAD_OTHERS, image_type: LOGO_UPLOAD_PROFILE_PIC
        }
        dispatch(addNursingStaffImageInput(req));
    }
    //remove profile image
    const handleRemoveImage = () => {
        if (imageProId && imageProId !== null) {

            const req: IRemoveImage = {
                token, unid, fid: imageProId
            }
            dispatch(removeNursingStaffImageInput(req));
        }
    }


    //Resent email 
    const onResendEmail = (data: any) => {
        const req: IResentEmail = {
            unid, token, user_id: data.user_id, user_email: data.email

        };
        dispatch(resentVerificationNursingStaffMail(req));
    }

    //Filter and sorting
    const handleNursingStaffList = (value: any, field: string) => {
        const req: INursingStaffState = {
            unid,
            token,
            facility: navFacility,
            client: client, user: logedUsername,
            group: loginUserRole, user_type: NURSING_STAFF,
            page: (field === 'pagination') ? value : currentPage,
            sort: (field === 'sort_nursingStaff') ? value : sortField,
        };
        dispatch(fetchNursingStaffList(req));
    }


    // pagination    
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        if (checkViewPermission) {
            handleNursingStaffList(page, 'pagination');
            // const req: INursingStaffState = {
            //     unid, token, facility: navFacility, client: client, user: logedUsername, group: loginUserRole, user_type: NURSING_STAFF, page: page
            // };
            // dispatch(fetchNursingStaffList(req));
        }
    };
    return (
        <div className='w-full'>
            <div className="flex justify-between items-center  pt-4 pb-[45px] pl-12 pr-8">
                <div>
                    <h1 className='text-2xl text-[var(--color-212121)] '>Facility Staff
                        {/* <span className='text-[var(--color-9E9E9E)]'>(6)</span> */}
                    </h1>
                </div>
                <div>
                    {useHasActionPermission(ROLE_NURSING_STAFF, P_ACT_ADD_EDIT) && <ButtonType type='iconLeft' buttonLabel='Add Facility Staff' icon={plusIcon} onClick={() => { handleNursingStaffReset(); }} />}
                </div>
            </div>
            <div className='pl-12 pr-8 '>
                <Table
                    parentPage={ROLE_NURSING_STAFF}
                    setPermission={true}
                    titles={nursingStaffHeader}
                    data={nursingStaffListData}
                    currentPage={currentPage}
                    numRowsPerPage={itemsPerPage}
                    hasResendEmailPermission={hasResendEmailPermission}
                    sortedFieldName={sortField}
                    handleSort={(sortField: string) => {
                        setSortField(sortField);
                        handleNursingStaffList(sortField, 'sort_nursingStaff');
                    }}
                    onClickEdit={(uuid: any) => handleClickEdit(uuid)}
                    onClickView={(uuid: any) => handleClickView(uuid)}
                    onClickStatus={(obj: any) => handleClickStatus(obj)}
                    onClickApprove={(obj: any) => handleClickApprove(obj)}
                    onResendEmail={(obj: any) => onResendEmail(obj)}
                    className={Math.ceil(paginationTotalCount / itemsPerPage) > 1 ? '' : ' mb-28 '}
                />
            </div>
            <Paginations
                totalItems={paginationTotalCount}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
            />
            <Modal
                open={openCreateStaff}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='focus:outline-none w-full max-w-[936px]'>
                    <ModalHeader title={addNursingStaff ? 'Staff Information' : viewOnly ? 'Staff Information' : 'Update Staff Information'} onClick={() => handleNursingStaffReset()} />

                    <form className='max-h-[calc(100vh-200px)] custom-scrollBar' typeof='submit' onSubmit={(e) => handleSaveNursingStaffData(e)}>
                        <div className="px-9">

                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <div className="flex flex-col gap-9 w-full">
                                    <TextField
                                        label=' First Name'
                                        required={true}
                                        value={firstName}
                                        disabled={viewOnly}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        placeholder=' First Name' />
                                    <TextField
                                        label=' Middle Name'
                                        value={middleName}
                                        disabled={viewOnly}
                                        onChange={(e) => setMiddleName(e.target.value)}
                                        placeholder=' Middle Name' />
                                </div>
                                <ImageInput label='Profile Picture' profileImage={profileImage} handleAddImage={handleAddImage} handleRemoveImage={handleRemoveImage} />

                            </div>

                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <TextField
                                    label=' Last Name'
                                    required={true}
                                    value={lastName}
                                    disabled={viewOnly}
                                    onChange={(e) => setLastName(e.target.value)}
                                    placeholder=' Last Name' />
                                <SelectField
                                    // className={`${facility === "0" && 'border-red-500 !important'}`}
                                    // onChange={(e) => setFacility(e.target.value)}
                                    label='Select Gender'
                                    placeHolder={true}
                                    value={gender}
                                    disabled={viewOnly}
                                    onChange={(e) => setGender(e.target.value)}
                                    options={[{ name: 'Select Gender', id: '' }, { name: 'Male', id: '1' }, { name: 'Female', id: '2' }, { name: 'Other', id: '3' }]}
                                />
                            </div>
                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <MultiSelectField
                                    // className={`${facility === "0" && 'text-[var(--color-9E9E9E)] !important'}`}
                                    onChange={(values) => setFacility(values)}
                                    value={facility}
                                    disabled={viewOnly}
                                    placeHolder={'Select Facility'}
                                    required={true}
                                    label='Select Facility'
                                    selectAllLabel={{ name: 'All Facility', id: 'All', isChecked: false }}
                                    options={facilityData}
                                />
                                <div className="w-full"> &nbsp;</div>
                            </div>
                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">

                                <div className="w-full flex flex-col gap-1">
                                    <TextField
                                        type='email'
                                        label='Email'
                                        value={email}
                                        disabled={viewOnly}
                                        onChange={e => setEmail(e.target.value)}
                                        required={true}
                                        placeholder='Email' />
                                    {(!addNursingStaff && !resendEmail && hasResendEmailPermission) && <div className='w-full text-right'>
                                        <sup
                                            className="cursor-pointer text-[var(--color-0048D4)] hover:underline"
                                            onClick={() => onResendEmail({ user_id: userEmailId, email: email })}
                                        >
                                            Resend email
                                        </sup>
                                    </div>}
                                </div>
                                <PhoneInputFeild
                                    inputProps={{
                                        name: `mobile`,
                                        autoFocus: false,
                                        disabled: viewOnly,
                                    }}
                                    value={mobile}
                                    defaultCountry={DEFAULT_COUNTRY_US}
                                    label='Mobile'
                                    placeholder='Mobile Number'
                                    onChange={phone => setMobile(phone)} />
                            </div>


                            <div className="flex gap-8 w-full my-9 flex-wrap md:flex-nowrap">
                                <div className="w-full">
                                    <div className="flex items-center justify-between mb-2">
                                        <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px] w-[141px]'>
                                            Email Notifications
                                        </span>
                                        <div className="flex gap-4">
                                            <input type="radio" id="Yes" name="email_notification" value="1" disabled={viewOnly} checked={emailNotification === '1'} onClick={() => setEmailNotification('1')} /><label htmlFor="Yes">Yes</label></div>
                                        <div className="flex gap-4">
                                            <input type="radio" id="No" name="email_notification" value="2" disabled={viewOnly} checked={emailNotification === '2'} onClick={() => setEmailNotification('2')} /><label htmlFor="No">No</label></div>

                                    </div>

                                </div>
                                <div className="w-full"> &nbsp;</div>

                            </div>
                            {!viewOnly && <>
                                <hr className='border-[var(--color-E0E0E0)] ' />
                                <div className="flex gap-4 justify-end items-end my-9">
                                    <ButtonType
                                        type='webCardbtn'
                                        buttonLabel='Cancel'
                                        onClick={() => handleNursingStaffReset()}
                                        className='px-12 !important bg-[var(--color-white)] !important imp-text-424242  hover:bg-[var(--color-0000000a)]' />
                                    <ButtonType
                                        type='webCardbtn'
                                        buttonLabel='Save'
                                        buttonType='submit'
                                        className='px-12 bg-[var(--color-0048D4)]' />
                                </div>
                            </>}
                        </div>
                    </form>
                </Box>
            </Modal>
            <ConfirmationMessage
                title={message.CFPCareConnect}
                description={`Are you sure you want to ${storeStatus ? 'deactivate' : 'activate'} this Facility Staff?`}
                open={showStatusConfirmation}
                textType={false}
                type={storeStatus ? 'danger' : 'success'}
                buttonTwoOnClick={() => setShowStatusConfirmation(false)}
                buttonOneOnClick={() => handleConfiramation()}
                buttonLabelOne={storeStatus ? 'Deactivate' : 'Activate'}
                buttonLabelTwo='Cancel'
            />

            <ConfirmationMessage
                title={message.CFPCareConnect}
                description={message.confirmUserApprove}
                open={showApproveConfirmation}
                textType={false}
                type={'warning'}
                buttonTwoOnClick={() => setShowApproveConfirmation(false)}
                buttonOneOnClick={() => handleConfiramationApprove()}
                buttonLabelOne={'Approve'}
                buttonLabelTwo='Cancel'
            />
        </div>
    )
}

export default NursingStaffList