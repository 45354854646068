import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { setNotification } from "../../components/notification/ToastNotification";
import { message } from "../../utilities/locals/messages";
import { addAssociateData, addAssociateImageInput, addAssociateSignInput, editAssociateData, fetchAssociateList, removeAssociateImageInput, removeAssociateSignInput, resentVerificationAssociateMail, updateAssociateData, updateAssociateUserStatus } from "../../services/associateServices";
import { IAssociateListData, IAssociateSingleData } from "../../models/associateListInterfaces";
import { EMAIL_ALREADY_EXIST, MOBILE_ALREADY_EXIST } from "../../config/permissions";

export interface AssociateListState {
    loading: 'idle' | 'loading' | 'success' | 'fetchSuccessfully' | 'logoUploaded' | 'error';
    associateUserListData: IAssociateListData[]
    associateUser: IAssociateSingleData[];
    paginationTotalCount:number; 
    uploadedImg: string;
    imageId: number | null;
    uploadedSignImg: string;
    imageSignId: number | null;
}

const initialState: AssociateListState = {
    loading: 'idle',
    associateUserListData: [],
    associateUser: [],
    paginationTotalCount:0,
    uploadedImg: '',
    imageId: null,
    uploadedSignImg: '',
    imageSignId: null,
}

export const associateListSlice = createSlice({
    name: "associateListSlice",
    initialState,
    reducers: {
        changeAssociateLoading: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(fetchAssociateList.pending, (state) => {
            state.associateUserListData = [];
            state.paginationTotalCount = 0;
            state.loading = 'loading';
        });
        builder.addCase(fetchAssociateList.fulfilled, (state, action) => {
            state.paginationTotalCount = action.payload.count ;
            state.associateUserListData = action.payload.results as IAssociateListData[];
            state.loading = 'fetchSuccessfully';
        });
        builder.addCase(fetchAssociateList.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(addAssociateData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(addAssociateData.fulfilled, (state) => {
            state.loading = 'success';
            setNotification(message.CFPCareConnect, message.associateAddedSuccessfully, false, 'success', 'addAssociateData');

        });
        builder.addCase(addAssociateData.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            if (payload.response.data.error_message === EMAIL_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.emailExist, false, 'failed', 'addAssociateData');
            } else if (payload.response.data.error_message === MOBILE_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.mobileNumberExist, false, 'failed', 'addAssociateData');
            } else {
                setNotification(message.CFPCareConnect, message.associateAddedfailed, false, 'failed', 'addAssociateData');
            }

        });

        builder.addCase(updateAssociateData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(updateAssociateData.fulfilled, (state) => {
            state.loading = 'success';
            setNotification(message.CFPCareConnect, message.associateUpdatedSuccessfully, false, 'success', 'updateAssociateData');

        });
        builder.addCase(updateAssociateData.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            if (payload.response.data.error_message === EMAIL_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.emailExist, false, 'failed', 'updateAssociateData');
            } else {
                setNotification(message.CFPCareConnect, message.associateUpdatedfailed, false, 'failed', 'updateAssociateData');
            }

        });

        builder.addCase(editAssociateData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(editAssociateData.fulfilled, (state, action) => {
            state.loading = 'fetchSuccessfully';
            state.associateUser = [action.payload] as IAssociateSingleData[];
        });
        builder.addCase(editAssociateData.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(updateAssociateUserStatus.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(updateAssociateUserStatus.fulfilled, (state, action) => {
            state.loading = 'success';
            const m = action.payload.message;
            setNotification(message.CFPCareConnect, m, false, 'success', 'updateAssociateUserStatus');
        });
        builder.addCase(updateAssociateUserStatus.rejected, (state) => {
            state.loading = 'error';
            setNotification(message.CFPCareConnect, message.updationFailed, false, 'failed', 'updateAssociateUserStatus');

        });
        builder.addCase(addAssociateImageInput.pending, (state) => {
            state.loading = 'idle';
            state.uploadedImg = '';
            state.imageId = null;
        });

        builder.addCase(addAssociateImageInput.fulfilled, (state, action) => {
            state.uploadedImg = action.payload.profile_pic;
            state.imageId = action.payload.id;
            state.loading = 'logoUploaded';
        });
        builder.addCase(addAssociateImageInput.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(removeAssociateImageInput.pending, (state) => {
            state.loading = 'idle';
        });

        builder.addCase(removeAssociateImageInput.fulfilled, (state) => {
            state.uploadedImg = '';
            state.imageId = null;
            state.loading = 'logoUploaded';
        });
        builder.addCase(removeAssociateImageInput.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(addAssociateSignInput.pending, (state) => {
            state.uploadedSignImg = '';
            state.imageSignId = null;
            state.loading = 'idle';
        });

        builder.addCase(addAssociateSignInput.fulfilled, (state, action) => {
            state.uploadedSignImg = action.payload.sign_pic;
            state.imageSignId = action.payload.id;
            state.loading = 'logoUploaded';
        });
        builder.addCase(addAssociateSignInput.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(removeAssociateSignInput.pending, (state) => {
            state.loading = 'idle';
        });

        builder.addCase(removeAssociateSignInput.fulfilled, (state) => {
            state.uploadedImg = '';
            state.imageSignId = null;
            state.loading = 'logoUploaded';
        });
        builder.addCase(removeAssociateSignInput.rejected, (state) => {
            state.loading = 'error';
        });

        
        
        builder.addCase(resentVerificationAssociateMail.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(resentVerificationAssociateMail.fulfilled, (state) => {
            state.loading = 'fetchSuccessfully';
            setNotification(message.CFPCareConnect, message.verificationEmailSuccessfully, false, 'success', 'resentVerificationAssociateMail');
        });
        builder.addCase(resentVerificationAssociateMail.rejected, (state) => {
            state.loading = 'error';
            setNotification(message.CFPCareConnect, message.verificationEmailFailed, false, 'failed', 'resentVerificationAssociateMail');

        });


    }
});

export const { changeAssociateLoading } = associateListSlice.actions;
export const AssociateListSlices = (state: RootState) => state.associateListData;
export const associateListReducer = associateListSlice.reducer;