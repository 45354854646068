import React from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import logo from '../../assets/images/careConnectIcon.png';

interface RegistrationNavbarProps {
  onClick: () => void;
}

//Notification after registration is completed.
const RegistrationNavbar: React.FC<RegistrationNavbarProps> = ({ onClick }) => {
  return (
    <>
      <header className="absolute inset-x-0 top-0 z-50 bg-[var(--color-white)]">
        <nav className="flex items-center justify-center w-100 py-[37px] px-[37px] lg:px-[62px] ">
          <div className="flex items-center justify-between w-full max-w-[1436px] ">
          <div onClick={onClick} className="flex items-center gap-4" >
              
              <img
                className="h-[57px] w-auto"
                src={logo}
                alt=""
                loading='lazy'
                decoding='async'
              />
              <h1 className="text-[var(--color-040E25)] text-xl font-medium tracking-[0.05px] ">CFP Care Connect</h1>
            </div>

            <button
              type="button"
              className="text-gray-700"
              onClick={onClick}
            >
              <CloseRoundedIcon />
            </button>
          </div>
        </nav>
      </header>
    </>
  );
};

export default React.memo(RegistrationNavbar);
