import React from 'react'
import error from '../../assets/images/404Image.svg'

const UrlNotFound = () => {
  return (
    <div className='h-[75vh] flex flex-col items-center justify-center bg-[var()]'>
        <img src={error} alt="404" />
        <h1 className='text-center text-[var(--color-757575)] text-2xl  mb-3'>Oops, Page Not Found!</h1>
        <p className='text-center text-[var(--color-757575)] text-sm'>It seems you've stumbled upon a page that doesn't exist. Don't worry; we can help you get back on track.</p>

    </div>
  )
}

export default UrlNotFound