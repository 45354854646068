import React, { useEffect, useState } from 'react'
import RegistrationNavbar from '../../components/navbar/RegistrationNavbar'
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom'
import TextField from '../../components/inputFeild/TextFeild'
import ButtonType from '../../components/button/ButtonType'
import RegistrationAside from '../../components/sidebar/RegistrationAside'
import RegistrationSuccess from '../../components/notification/RegistrationSuccess'
import PhoneInputFeild from '../../components/inputFeild/PhoneInputFeild'
import SelectField from '../../components/inputFeild/SelectField'
import ImageInput from '../../components/inputFeild/ImageInput'
import { DEFAULT_COUNTRY_US, LOGO_UPLOAD_OTHERS, LOGO_UPLOAD_PROFILE_PIC, PATIENTS } from '../../config/permissions'
import { IImageInputWithoutToken, IRemoveImageWithoutToken } from '../../models/commonInterfaces'
import { useAppDispatch, useAppSelector } from '../../helpers/hook'
import { addPatientReg, addPatientRegImage, removePatientRegImage } from '../../services/patientRegServices'
import { PatientRegistrationSlices, resetImage } from './patientRegistrationSlice'
import { message } from '../../utilities/locals/messages'
import { setNotification } from '../../components/notification/ToastNotification'
import { IPatientRegData } from '../../models/patientRegInterface'
import { ROUTE_LOGIN_PATIENT } from '../../config/routeConfig'
import { fetchFacilityDropdowns } from '../../services/commonServices'
import { CommonSlices } from '../common/commonSlice'
import { REACT_APP_RECAPTCHA } from '../../config/urlConfig';
import WebFooter from '../../components/footer/WebFooter';

const PatientRegistration = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const recaptcha: string | undefined = REACT_APP_RECAPTCHA;
  const todayDate = new Date().toISOString().split('T')[0];
  const { imageId, uploadedImg, loading } = useAppSelector(PatientRegistrationSlices);
  const { facilityDatas } = useAppSelector(CommonSlices);

  interface FormData {
    firstName: string;
    middleName: string;
    lastName: string;
    patientDOB: string;
    patientGender: string;
    facility: string;
    isAlertOpen: boolean;
    email: string;
    mobile: string;
    alternativeMobile: string;
    emailNotification: string;
    termsAndC: boolean;
    profileImage: string;
    imageId: number | null;
  }

  const initialFormData: FormData = {
    firstName: '',
    middleName: '',
    lastName: '',
    patientDOB: '',
    patientGender: '',
    facility: '',
    isAlertOpen: false,
    email: '',
    mobile: '',
    alternativeMobile: '',
    termsAndC: false,
    profileImage: '',
    emailNotification: '2',
    imageId: null,
  };

  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [cathpcha, setcathpcha] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchFacilityDropdowns());
    handleResetData();
  }, [])

  useEffect(() => {
    if (loading === 'success') {
      setFormData({
        ...formData,
        isAlertOpen: true,
      });
    }
  }, [loading])

  useEffect(() => {
    setFormData({
      ...formData,
      imageId: imageId,
      profileImage: uploadedImg,
    });
  }, [imageId, uploadedImg])



  const handlePatientRegistration = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.firstName.length > 0 && formData.lastName.length > 0 && formData.facility.length > 0) {
      if (formData.mobile.length < 2 || formData.mobile.length > 6) {
        if (formData.patientDOB < todayDate) {

          const req: IPatientRegData = {
            type: 1,
            facility: Number(formData.facility),
            first_name: formData.firstName,
            middle_name: formData.middleName,
            last_name: formData.lastName,
            email: formData.email,
            gender: Number(formData.patientGender),
            mobile: formData.mobile,
            alternate_mobile: formData.alternativeMobile,
            notes: "",
            notification: Number(formData.emailNotification),
            patient_dob: formData.patientDOB,
            relationship: 1,
            patient_first_name: "",
            patient_gender: 0,
            patient_last_name: "",
            patient_middle_name: "",
            profile_pic: formData.imageId,
            sign: null,
            allergy: "",
          }
          //for add client
          dispatch(addPatientReg(req));
        } else {
          setNotification(message.CFPCareConnect, message.invalidDOB, false, 'failed', 'handleSavePatientData');
        }
      } else {
        setNotification(message.CFPCareConnect, message.invalidMobile, false, 'failed', 'handleSavePatientData');
      }
    } else {
      setNotification(message.CFPCareConnect, message.fillOutAllMandatoryFields, false, 'failed', 'handleSavePatientData');
    }
  };

  //for add profile image
  const handleAddImage = (selectedFile: File) => {
    const req: IImageInputWithoutToken = {
      image: selectedFile, type: LOGO_UPLOAD_OTHERS, image_type: LOGO_UPLOAD_PROFILE_PIC
    }
    dispatch(addPatientRegImage(req));
  }

  //remove profile image
  const handleRemoveImage = () => {
    if (formData.imageId && formData.imageId !== null) {

      const req: IRemoveImageWithoutToken = {
        fid: formData.imageId
      }
      dispatch(removePatientRegImage(req));
    }
  }

  //reset data
  const handleResetData = () => {
    setFormData({
      firstName: '',
      middleName: '',
      lastName: '',
      patientDOB: '',
      patientGender: '',
      facility: '',
      isAlertOpen: false,
      email: '',
      mobile: '',
      alternativeMobile: '',
      termsAndC: false,
      profileImage: '',
      imageId: null,
      emailNotification: '2',
    });
    dispatch(resetImage());
  }

  function onChangeCapthcha(value: any) {
    if (value !== null && value.length > 0) {
      setcathpcha(true)
    }
  }

  return (
    <>
      <RegistrationNavbar onClick={() => { handleResetData(); navigate('/') }} />
      <section className='flex flex-col justify-center items-center w-full px-[37px] lg:px-[62px] '>
        <div className='mt-[154px] flex w-full max-w-[1436px]  gap-8'>
          <RegistrationAside parent='patient' />
          <form className='w-full ' onSubmit={(e) => handlePatientRegistration(e)}>
            <h2 className='text-xl font-medium text-[var(--color-424242)] tracking-[.05px]'>Patient Information</h2>
            <hr className='border-[var(--color-E0E0E0)] mt-1' />
            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
              <TextField
                label='Patient First Name'
                required={true}
                value={formData.firstName}
                onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                placeholder='Patient First Name' />
              <TextField
                label='Patient Middle Name'
                value={formData.middleName}
                onChange={(e) => setFormData({ ...formData, middleName: e.target.value })}
                placeholder='Patient Middle Name' />
            </div>
            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
              <TextField
                label='Patient Last Name'
                required={true}
                value={formData.lastName}
                onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                placeholder='Patient Last Name' />
              <SelectField
                onChange={(e) => setFormData({ ...formData, patientGender: e.target.value })}
                value={formData.patientGender}
                label='Patient Gender'
                placeHolder={true}
                options={[{ name: 'Select Gender', id: '' }, { name: 'Male', id: '1' }, { name: 'Female', id: '2' }, { name: 'Other', id: '3' }]}
              />
            </div>
            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
              <div className="flex flex-col gap-9 w-full">
                <TextField
                  label='Patient DOB '
                  required={true}
                  max={todayDate}
                  type='date'
                  value={formData.patientDOB}
                  onChange={(e) => setFormData({ ...formData, patientDOB: e.target.value })}
                  placeholder='Patient DOB ' />

                <SelectField
                  label='Select Facility'
                  search={true}
                  placeHolder={true}
                  required={true}
                  onChange={(e) => setFormData({ ...formData, facility: e.target.value })}
                  value={formData.facility}
                  options={[
                    { name: 'Select Facility', id: '', isChecked: false },
                    ...facilityDatas
                  ]}
                />
              </div>
              <ImageInput label='Profile Picture' profileImage={formData.profileImage} handleAddImage={handleAddImage} handleRemoveImage={handleRemoveImage} />

            </div>



            <h2 className='text-xl font-medium text-[var(--color-424242)] tracking-[.05px] mt-20'>Contact Details</h2>
            <hr className='border-[var(--color-E0E0E0)] mt-1' />
            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
              <TextField
                label='Email'
                value={formData.email}
                type='email'
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                placeholder='Email' />
              <div className="w-full"></div>
            </div>
            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">

              <PhoneInputFeild
                inputProps={{
                  name: `mobile`,
                  autoFocus: false,
                }}
                value={formData.mobile}
                defaultCountry={DEFAULT_COUNTRY_US}
                label='Mobile'
                placeholder='Mobile Number'
                onChange={phone => setFormData({ ...formData, mobile: phone })} />
              <PhoneInputFeild
                inputProps={{
                  name: `mobile`,
                  autoFocus: false,
                }}
                value={formData.alternativeMobile}
                defaultCountry={DEFAULT_COUNTRY_US}
                placeholder='Alternative Phone'
                label='Alternative Phone'
                onChange={phone => setFormData({ ...formData, alternativeMobile: phone })} />

            </div>
            {/* {(formData.email && formData.email.length > 2) &&
              <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                <div className="w-full">
                  <div className="flex items-center justify-between mb-2">
                    <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px] w-[141px]'>
                      Email Notifications
                    </span>
                    <div className="flex gap-4">
                      <input type="radio" id="Yes" name="email_notification" value="1" checked={formData.emailNotification === '1'} onClick={() => setFormData({ ...formData, emailNotification: '1' })} /><label htmlFor="Yes">Yes</label></div>
                    <div className="flex gap-4">
                      <input type="radio" id="No" name="email_notification" value="2" checked={formData.emailNotification === '2'} onClick={() => setFormData({ ...formData, emailNotification: '2' })} /><label htmlFor="No">No</label></div>

                  </div>

                </div>
                <div className="w-full"> &nbsp;</div>

              </div>
            } */}
            <div className="flex gap-4 mt-9 items-center">
              <input type="checkbox" name="" id="" checked={formData.termsAndC} onChange={(e) => setFormData({ ...formData, termsAndC: e.target.checked })} />
              I agree the terms & conditions of the use of this website
            </div>
            <hr className='border-[var(--color-E0E0E0)] mt-20' />
            <div className="flex justify-between items-center ">
              <ReCAPTCHA
                sitekey={recaptcha ? recaptcha : ''}
                onChange={onChangeCapthcha}
              />
              <div className="flex gap-4 justify-end items-end mt-9 mb-[52px]">
                <ButtonType
                  type='webCardbtn'
                  buttonLabel='Cancel'
                  onClick={handleResetData}
                  className='px-12 !important bg-[var(--color-white)] !important imp-text-424242  hover:bg-[var(--color-0000000a)]' />
                {(formData.termsAndC && cathpcha) &&
                  <ButtonType
                    type='webCardbtn'
                    buttonLabel='Next'
                    buttonType='submit'
                    className='px-12 !important' />}
              </div>
            </div>
          </form>

        </div>
        <RegistrationSuccess
          parent={PATIENTS}
          type="registrationSuccess"
          buttonLabelOne="Done"
          open={formData.isAlertOpen}
          onClickOne={() => { navigate(ROUTE_LOGIN_PATIENT); handleResetData(); }}
          onClose={() => setFormData({ ...formData, isAlertOpen: false })}
        />
      </section>
      <WebFooter />
    </>
  )
}

export default PatientRegistration