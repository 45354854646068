import { createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "./apiService";
import { IRoleState, IRolesAndPermissionsData } from "../models/rolesAndPermissionInterfaces";
import { URL_CHANGE_ROLES, URL_LIST_ROLES, URL_SINGLE_ROLES } from "../config/apiUrls";


// to fetch all roles
export const fetchRoleList = createAsyncThunk(
    'user/fetchRoleList',
    async (form: IRoleState, thunkAPI) => {        
        try {
            const response = post(URL_LIST_ROLES, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
// to fetch all provider
export const handleChangeRole = createAsyncThunk(
    'user/handleChangeRole',
    async (form: IRolesAndPermissionsData, thunkAPI) => {        
        try {
            const response = post(URL_CHANGE_ROLES, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// to fetch sigle roles
export const fetchSingleRoleData = createAsyncThunk(
    'user/fetchSingleRoleData',
    async (form: IRoleState, thunkAPI) => {        
        try {
            const response = post(URL_SINGLE_ROLES, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);