import React, { useEffect, useState } from 'react'
import RegistrationNavbar from '../../components/navbar/RegistrationNavbar'
import { useNavigate } from 'react-router-dom'
import TextField from '../../components/inputFeild/TextFeild'
import ButtonType from '../../components/button/ButtonType'
import RegistrationAside from '../../components/sidebar/RegistrationAside'
import RegistrationSuccess from '../../components/notification/RegistrationSuccess'
import PhoneInputFeild from '../../components/inputFeild/PhoneInputFeild'
import ImageInput from '../../components/inputFeild/ImageInput'
import { DEFAULT_COUNTRY_US, LOGO_UPLOAD_OTHERS, LOGO_UPLOAD_PROFILE_PIC, NURSING_STAFF } from '../../config/permissions'
import MultiSelectField from '../../components/inputFeild/MultiSelectField'
import { fetchFacilityDropdowns } from '../../services/commonServices'
import { useAppDispatch, useAppSelector } from '../../helpers/hook'
import { CommonSlices } from '../common/commonSlice'
import { NursingStaffRegistrationSlices, resetImage } from './nursingStaffRegSlice'
import { INursingStaffRegData } from '../../models/nursingStaffRegInterface'
import { addNursingStaffReg, addNursingStaffRegImage, removeNursingStaffRegImage } from '../../services/nursingStaffRegServices'
import { setNotification } from '../../components/notification/ToastNotification'
import { message } from '../../utilities/locals/messages'
import { IImageInputWithoutToken, IRemoveImageWithoutToken } from '../../models/commonInterfaces'
import { ROUTE_LOGIN } from '../../config/routeConfig'
import SelectField from '../../components/inputFeild/SelectField'
import ReCAPTCHA from 'react-google-recaptcha'
import { REACT_APP_RECAPTCHA } from '../../config/urlConfig'
import WebFooter from '../../components/footer/WebFooter'

interface UserState {
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  email: string;
  facility: number[];
  isAlertOpen: boolean;
  mobile: string;
  emailNotification: string;
  alternativeMobile: string;
  profileImage: string;
  imageId: number | null;
  termsAndC: boolean;
}
const NursingStaffRegistration = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const recaptcha: string | undefined = REACT_APP_RECAPTCHA;
  const { imageId, uploadedImg, loading } = useAppSelector(NursingStaffRegistrationSlices);
  const { facilityDatas } = useAppSelector(CommonSlices);

  const initialFormData: UserState = {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    facility: [],
    isAlertOpen: false,
    mobile: '',
    alternativeMobile: '',
    gender: '',
    profileImage: '',
    emailNotification: '2',
    imageId: null,
    termsAndC: false,
  }
  const [formData, setFormData] = useState<UserState>(initialFormData);
  const [cathpcha, setcathpcha] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchFacilityDropdowns());
    handleResetData();
  }, [])

  useEffect(() => {
    if (loading === 'success') {
      setFormData({
        ...formData,
        isAlertOpen: true,
      });
    }
  }, [loading])

  useEffect(() => {
    setFormData({
      ...formData,
      imageId: imageId,
      profileImage: uploadedImg,
    });
  }, [imageId, uploadedImg])



  const handleNursingStaffRegistration = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.firstName.length > 0 && formData.lastName.length > 0 && formData.facility.length > 0 && formData.email.length) {
      if (formData.mobile.length < 2 || formData.mobile.length > 6) {

        const req: INursingStaffRegData = {
          facility: formData.facility,
          gender: Number(formData.gender),
          profile_pic: Number(formData.imageId),
          notifications: Number(formData.emailNotification),
          sign: null,
          sign_base: '',
          user_info: {
            first_name: formData.firstName,
            middle_name: formData.middleName,
            last_name: formData.lastName,
            email: formData.email,
            mobile: formData.mobile,
            alternate_mobile: formData.alternativeMobile
          }
        }
        //for add client
        dispatch(addNursingStaffReg(req));
      } else {
        setNotification(message.CFPCareConnect, message.invalidMobile, false, 'failed', 'handleNursingStaffRegistration');
      }
    } else {
      setNotification(message.CFPCareConnect, message.fillOutAllMandatoryFields, false, 'failed', 'handleNursingStaffRegistration');
    }
  };

  //for add profile image
  const handleAddImage = (selectedFile: File) => {
    const req: IImageInputWithoutToken = {
      image: selectedFile, type: LOGO_UPLOAD_OTHERS, image_type: LOGO_UPLOAD_PROFILE_PIC
    }
    dispatch(addNursingStaffRegImage(req));
  }

  //remove profile image
  const handleRemoveImage = () => {
    if (formData.imageId && formData.imageId !== null) {

      const req: IRemoveImageWithoutToken = {
        fid: formData.imageId
      }
      dispatch(removeNursingStaffRegImage(req));
    }
  }

  //reset data
  const handleResetData = () => {
    setFormData({
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      gender: '',
      facility: [],
      isAlertOpen: false,
      mobile: '',
      alternativeMobile: '',
      profileImage: '',
      imageId: null,
      termsAndC: false,
      emailNotification: '2',
    });
    dispatch(resetImage());
  }

  function onChangeCapthcha(value: any) {
    if (value !== null && value.length > 0) {
      setcathpcha(true)
    }
  }
  return (
    <>
      <RegistrationNavbar onClick={() => { handleResetData(); navigate('/') }} />
      <section className='flex flex-col justify-center items-center w-full px-[37px] lg:px-[62px] '>
        <div className='mt-[154px] flex w-full max-w-[1436px]  gap-8'>
          <RegistrationAside parent='nursing' />
          <form className='w-full ' onSubmit={(e) => handleNursingStaffRegistration(e)}>
            <h2 className='text-xl font-medium text-[var(--color-424242)] tracking-[.05px]'>Staff  Information</h2>
            <hr className='border-[var(--color-E0E0E0)] mt-1' />
            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
              <div className="flex flex-col gap-9 w-full">
                <TextField
                  label=' First Name'
                  required={true}
                  value={formData.firstName}
                  onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                  placeholder=' First Name' />
                <TextField
                  label=' Middle Name'
                  value={formData.middleName}
                  onChange={(e) => setFormData({ ...formData, middleName: e.target.value })}
                  placeholder=' Middle Name' />
              </div>
              <ImageInput label='Profile Picture' profileImage={formData.profileImage} handleAddImage={handleAddImage} handleRemoveImage={handleRemoveImage} />

            </div>
            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
              <TextField
                label=' Last Name'
                required={true}
                value={formData.lastName}
                onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                placeholder=' Last Name' />
              <SelectField
                label='Select Gender'
                placeHolder={true}
                value={formData.gender}
                onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                options={[{ name: 'Select Gender', id: '' }, { name: 'Male', id: '1' }, { name: 'Female', id: '2' }, { name: 'Other', id: '3' }]}
              />
            </div>
            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
              <MultiSelectField
                // className={`${facility === "0" && 'text-[var(--color-9E9E9E)] !important'}`}
                onChange={(values) => setFormData({ ...formData, facility: values })}
                value={formData.facility}
                placeHolder={'Select Facility'}
                required={true}
                label='Select Facility'
                selectAllLabel={{ name: 'All Facility', id: 'All', isChecked: false }}
                options={facilityDatas}
              />
              <div className="w-full"></div>
            </div>
            <h2 className='text-xl font-medium text-[var(--color-424242)] tracking-[.05px] mt-20'>Contact Details</h2>
            <hr className='border-[var(--color-E0E0E0)] mt-1' />
            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
              <TextField
                type='email'
                label='Email'
                value={formData.email}
                onChange={e => setFormData({ ...formData, email: e.target.value })}
                required={true}
                placeholder='Email' />
              <div className="w-full"></div>
            </div>
            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">

              <PhoneInputFeild
                inputProps={{
                  name: `mobile`,
                  autoFocus: false,
                }}
                value={formData.mobile}
                defaultCountry={DEFAULT_COUNTRY_US}
                label='Mobile'
                placeholder='Mobile Number'
                onChange={phone => setFormData({ ...formData, mobile: phone })} />
              <div className="w-full"></div>

            </div>
            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
              <div className="w-full">
                <div className="flex items-center justify-between mb-2">
                  <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px] w-[141px]'>
                    Email Notifications
                  </span>
                  <div className="flex gap-4">
                    <input type="radio" id="Yes" name="email_notification" value="1" checked={formData.emailNotification === '1'} onClick={() => setFormData({ ...formData, emailNotification: '1' })} /><label htmlFor="Yes">Yes</label></div>
                  <div className="flex gap-4">
                    <input type="radio" id="No" name="email_notification" value="2" checked={formData.emailNotification === '2'} onClick={() => setFormData({ ...formData, emailNotification: '2' })} /><label htmlFor="No">No</label></div>

                </div>

              </div>
              <div className="w-full"> &nbsp;</div>

            </div>

            <div className="flex gap-4 mt-9 items-center">
              <input type="checkbox" name="" id="" checked={formData.termsAndC} onClick={() => setFormData({ ...formData, termsAndC: !formData.termsAndC })} />
              I agree the terms & conditions of the use of this website
            </div>
            <hr className='border-[var(--color-E0E0E0)] mt-20' />
            <div className="flex justify-between items-center ">
              <ReCAPTCHA
                sitekey={recaptcha ? recaptcha : ''}
                onChange={onChangeCapthcha}
              />
              <div className="flex gap-4 justify-end items-end mt-9 mb-[52px]">
                <ButtonType
                  type='webCardbtn'
                  buttonLabel='Cancel'
                  onClick={handleResetData}
                  className='px-12 !important bg-[var(--color-white)] !important imp-text-424242  hover:bg-[var(--color-0000000a)]' />
                {(formData.termsAndC && cathpcha) && <ButtonType
                  type='webCardbtn'
                  buttonLabel='Next'
                  buttonType='submit'
                  className='px-12 !important' />}
              </div>
            </div>
          </form>

        </div>
        <RegistrationSuccess
          parent={NURSING_STAFF}
          type="registrationSuccess"
          buttonLabelOne="Done"
          open={formData.isAlertOpen}
          onClickOne={() => { navigate(ROUTE_LOGIN); handleResetData(); }}
          onClose={() => setFormData({ ...formData, isAlertOpen: false })}
        />
      </section>
      <WebFooter />
    </>
  )
}

export default NursingStaffRegistration