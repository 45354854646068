import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_ADD_ASSOCIATE, URL_ASSOCIATE_LIST, URL_EDIT_ASSOCIATE, URL_REMOVE_IMAGE, URL_RESENT_MAIL, URL_STATUS_ASSOCIATE, URL_UPDATE_ASSOCIATE, URL_UPLOAD_IMAGE } from "../config/apiUrls";
import { get, post, postFormData, put } from "./apiService";
import { IAssociateData, IAssociateState, IAssociateStatus } from "../models/associateListInterfaces";
import { IImageInput, IRemoveImage, IResentEmail } from "../models/commonInterfaces";

// to fetch all associate
export const fetchAssociateList = createAsyncThunk(
  'user/fetchAssociateList',
  async (form: IAssociateState, thunkAPI) => {
    try {
      const { page, ...body } = form;
      let url = URL_ASSOCIATE_LIST
      if (page !== 0) {
        url = url + '?page=' + page
      }
      const response = post(url, body);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//to add associate
export const addAssociateData = createAsyncThunk(
  'user/addAssociateData',
  async (form: IAssociateData, thunkAPI) => {
    try {
      const response = post(URL_ADD_ASSOCIATE, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//to update associate user
export const updateAssociateData = createAsyncThunk(
  'user/updateAssociateData',
  async (form: IAssociateData, thunkAPI) => {
    try {
      const { uuid, ...body } = form;
      const url = URL_UPDATE_ASSOCIATE + uuid;
      const response = put(url, body);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// to get singal associate user details
export const editAssociateData = createAsyncThunk(
  'user/editAssociateData',
  async (form: IAssociateState, thunkAPI) => {
    try {
      const {  uuid, ...body } = form;
      const url = URL_EDIT_ASSOCIATE + uuid;
      const response = get(url, body);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//to update status on facility list
export const updateAssociateUserStatus = createAsyncThunk(
  'user/updateAssociateUserStatus',
  async (form: IAssociateStatus, thunkAPI) => {
    try {
      const response = put(URL_STATUS_ASSOCIATE, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const addAssociateImageInput = createAsyncThunk(
  'user/addAssociateImageInput',
  async (form: IImageInput, thunkAPI) => {
    try {
      const response = postFormData(URL_UPLOAD_IMAGE, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const removeAssociateImageInput = createAsyncThunk(
  'user/removeAssociateImageInput',
  async (form: IRemoveImage, thunkAPI) => {
    try {
      const response = post(URL_REMOVE_IMAGE, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const addAssociateSignInput = createAsyncThunk(
  'user/addAssociateSignInput',
  async (form: IImageInput, thunkAPI) => {
    try {
      const response = postFormData(URL_UPLOAD_IMAGE, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const removeAssociateSignInput = createAsyncThunk(
  'user/removeAssociateSignInput',
  async (form: IRemoveImage, thunkAPI) => {
    try {
      const response = post(URL_REMOVE_IMAGE, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


// to resend verification mail  
export const resentVerificationAssociateMail = createAsyncThunk(
  'user/resentVerificationAssociateMail',
  async (form: IResentEmail, thunkAPI) => {
    try {
      const response = post(URL_RESENT_MAIL, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);