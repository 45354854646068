declare global {
  interface Window {
    getEnv: (variableKey: string) => string;
  }
}

export const API_BASE_URL = window.getEnv('REACT_APP_API_URL');
export const REACT_APP_SITE_URL = window.getEnv('REACT_APP_SITE_URL');
export const REACT_APP_RECAPTCHA = window.getEnv('REACT_APP_RECAPTCHA');

// FIREBASE KEYS
export const REACT_FIREBASE_API_KEY = window.getEnv('REACT_FIREBASE_API_KEY');
export const REACT_FIREBASE_AUTH_DOMAIN = window.getEnv('REACT_FIREBASE_AUTH_DOMAIN');
export const REACT_FIREBASE_PROJECT_ID = window.getEnv('REACT_FIREBASE_PROJECT_ID');
export const REACT_FIREBASE_STORAGE_BUCKET = window.getEnv('REACT_FIREBASE_STORAGE_BUCKET');
export const REACT_FIREBASE_MESSAGING_SENDER_ID = window.getEnv('REACT_FIREBASE_MESSAGING_SENDER_ID');
export const REACT_FIREBASE_APP_ID = window.getEnv('REACT_FIREBASE_APP_ID');
export const REACT_FIREBASE_MEASUREMENT_ID = window.getEnv('REACT_FIREBASE_MEASUREMENT_ID');
export const REACT_FIREBASE_REACT_FIREBASE_VAPIKEY = window.getEnv('REACT_FIREBASE_REACT_FIREBASE_VAPIKEY');