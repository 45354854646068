import React, { useState, useEffect } from 'react'
import TextField from '../../components/inputFeild/TextFeild'
import ButtonType from '../../components/button/ButtonType'
// import logoIcon from '../../assets/images/loginIcon.svg'
import logo from '../../assets/images/careConnectIcon.png';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { loginPageSlices, storeCredentials } from './loginPageSlice';
import { authenticateUser, resetUserPasswordNoAuth } from '../../services/loginService';
import { useNavigate } from 'react-router-dom';
import { ROUTE_CLIENT_LIST, ROUTE_DASHBOARD } from '../../config/routeConfig';
import { USER_ROLE_SUPERADMIN } from '../../config/permissions';
import { Box, Modal } from '@mui/material';
import ConfirmationMessage from '../../components/notification/ConfirmationMessage';
import { message } from '../../utilities/locals/messages';
import Footer from '../../components/footer/Footer';



const LoginPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { storedPassword, storedUsername, storedrememberLogin, loading, loginUserRole } = useAppSelector(loginPageSlices);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-52%, -50%)',
    };

    const [userName, setuserName] = useState<string>(storedUsername);
    const [password, setpassword] = useState<string>(storedPassword);
    const [rememberLogin, setrememberLogin] = useState<boolean>(storedrememberLogin);
    const [showForgetPassword, setShowForgetPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    //handle navigation to coresponding page if login sucess 
    useEffect(() => {
        if (loading === "loginSuccess") {
            if (loginUserRole === USER_ROLE_SUPERADMIN) {
                navigate(ROUTE_CLIENT_LIST);
            } else {
                navigate(ROUTE_DASHBOARD);
            }
        }
    }, [loading]);

    //handling login form submit
    const handleSave = (e: any) => {
        e.preventDefault();
        //to store username and password if rememberlogin checked true
        if (rememberLogin) {
            dispatch(storeCredentials({ password, userName, rememberLogin }));
        } else { dispatch(storeCredentials({ password: '', userName: '', rememberLogin: false })); }


        dispatch(authenticateUser({ username: userName, password: password }));
    }

    //confirmation message for rreset password
    const handleConfirmResetPassword = (e: any) => {
        e.preventDefault();
        setShowForgetPassword(false);
        setShowConfirmPassword(true);
    }

    //handle reset password function
    const handleConfirmPassword = () => {
        dispatch(resetUserPasswordNoAuth(userName))
        setShowConfirmPassword(false);
    }



    return (
        <>
            <header className=" bg-[var(--color-F5F5F5)]">
                <nav className="flex items-center justify-center w-100 py-[37px] px-[37px] lg:px-[62px] ">
                    <div className="flex items-center justify-between w-full max-w-[1436px] ">
                        <div className="flex items-center gap-3">
                            <img
                                className="h-[57px] w-auto"
                                src={logo}
                                alt=""
                                loading='lazy'
                                decoding='async'
                            />
                            <h1 className="text-[var(--color-253179)] text-xl font-bold tracking-[0.05px] ">CFP Care Connect</h1>
                        </div>

                    </div>
                </nav>
            </header>
            <div className='flex flex-col justify-between bg-[var(--color-F5F5F5)] w-full min-h-[calc(100vh-132px)] '>
                <div className=' bg-[var(--color-F5F5F5)] flex justify-center items-center px-6 py-[42px] lg:px-8'>
                    <div className=" bg-[var(--color-white)] flex min-h-full max-w-[488px] rounded-xl flex-1 flex-col justify-center p-9 gap-12">
                        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                            {/* <img
                                className="mx-auto h-[60px] w-auto"
                                src={logoIcon}
                                alt="Your Company"
                                loading='lazy'
                                decoding='async'
                            /> */}
                            <h2 className="mt-6 text-center text-3xl font-medium tracking-[0.05px] text-[var(--color-212121)]">
                                Sign in to your account
                            </h2>
                        </div>

                        <div className="">
                            <form className="" onSubmit={(e) => handleSave(e)}>
                                <div>
                                    <label htmlFor="" className='flex flex-col gap-3'>
                                        <span className='text-[15px] text-[var(--color-212121)] tracking-[0.05px] '>Username <span className='text-[var(--color-9E9E9E)]'>(Email)</span></span>
                                        <TextField
                                            required={true}
                                            placeholder='Email'
                                            autoComplete="email"
                                            type='email'
                                            value={userName}
                                            onChange={(e) => setuserName(e.target.value)}
                                        />
                                    </label>
                                </div>


                                <div className='flex flex-col gap-4 mt-9'>

                                    <label htmlFor="" className='flex flex-col gap-3'><span className='text-[15px] text-[var(--color-212121)] tracking-[0.05px] '>Password</span>
                                        <TextField
                                            required={true}
                                            placeholder='Password'
                                            type='password'
                                            autoComplete="current-password"
                                            value={password}
                                            onChange={(e) => setpassword(e.target.value)}
                                        />
                                    </label>

                                    <div className="flex  items-center justify-between">

                                        <label className='text-sm text-[var(--color-212121)] tracking-[0.05px] gap-4 flex items-center'>
                                            <input type="checkbox" name="" id="" checked={rememberLogin} onChange={(e) => setrememberLogin(e.target.checked)} />
                                            <span>Remember Login</span></label>
                                        <span onClick={() => setShowForgetPassword(true)} className='cursor-pointer text-[var(--color-0048D4)] tracking-[0.05px] text-sm hover:underline'>Forgot Password</span>
                                    </div>
                                </div>

                                <div>
                                    <ButtonType type="webCardbtn" buttonLabel='Sign In' className='w-full py-4 font-medium text-xl rounded-[30px] mt-8 ' buttonType='submit'
                                    />
                                </div>
                                <p className="text-[var(--color-757575)] text-center mt-4 text-[15px] tracking-[0.05px]">Don’t have an account? <a href={"/"} className='text-[var(--color-0048D4)] underline tracking-[0.05px]'>Register</a> instead
                                </p>
                            </form>

                        </div>
                    </div>
                </div>

                <Modal
                    open={showForgetPassword}
                    onClose={() => setShowForgetPassword(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} className='focus:outline-none'>
                        <form onSubmit={(e) => handleConfirmResetPassword(e)} typeof='submit' className='flex flex-col gap-5 p-6  bg-[var(--color-FFF)] shadow-md rounded-lg min-w-[488px]  max-w-[596px]'>
                            <div>
                                <label htmlFor="" className='flex flex-col gap-3'>
                                    <span className='text-[15px] text-[var(--color-212121)] tracking-[0.05px] '>Username <span className='text-[var(--color-9E9E9E)]'>(Email)</span> <span className='text-[var(--color-danger)]'>*</span></span>
                                    <TextField
                                        required={true}
                                        placeholder='Email'
                                        autoComplete="email"
                                        type='email'
                                        value={userName}
                                        onChange={(e) => setuserName(e.target.value)}
                                    />
                                </label>
                            </div>

                            <div className='w-full flex items-center justify-end gap-4 text-sm'>

                                <button className='rounded-lg px-3 py-2 border border-[var(--color-EEE)]  hover:bg-[var(--color-0000000a)]' onClick={() => setShowForgetPassword(false)}>Cancel</button>

                                <button
                                    type='submit'
                                    className='rounded-lg px-3 py-2  text-[var(--color-white)] bg-[var(--color-253179)]'
                                >
                                    Reset Password
                                </button>

                            </div>


                        </form>
                    </Box>
                </Modal>

                <ConfirmationMessage
                    title={message.CFPCareConnect}
                    description={`Are you sure you want to reset the password for this account?`}
                    open={showConfirmPassword}
                    textType={false}
                    type={'warning'}
                    buttonTwoOnClick={() => setShowConfirmPassword(false)}
                    buttonOneOnClick={() => handleConfirmPassword()}
                    buttonLabelOne={'Send'}
                    buttonLabelTwo='Cancel'
                />
                <div className="w-full     bg-[var(--color-F5F5F5)]">
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default LoginPage;