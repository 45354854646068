import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getMessaging, onMessage } from 'firebase/messaging';



const firebaseConfig = {
  apiKey: 'AIzaSyAi94d9Pe9_FBE6YSVrE5g0gCEWJ4Fr260',
  authDomain: "cfp-care-connect-1703159337590.firebaseapp.com",
  projectId: "cfp-care-connect-1703159337590",
  storageBucket: "cfp-care-connect-1703159337590.appspot.com",
  messagingSenderId: "960000064168",
  appId: "1:960000064168:web:e502c4dc6b64992685e09d",
  measurementId: "G-D3JWJ7KGKV"
};

firebase.initializeApp(firebaseConfig);

const messaging = getMessaging();

export const onMessageListener = () =>
  new Promise<any>((resolve) => {
    onMessage(messaging, (payload: any) => {
      showNotification(payload);
      resolve(payload);
    });
  });

const showNotification = (payload: any) => {
  // Extract relevant information from the FCM payload

  const notificationTitle = 'New Message';
  const notificationOptions = {
    body: payload?.notification?.body,
    // body: "You have a new message from" + " " + (payload as any)?.data?.name,
    // body: (payload as any)?.data?.message || 'You have a new message',
    icon: '/logo192.png'
  };

  // Check if notifications are supported and permission is granted
  if (Notification.permission === 'granted') {
    // Show the notification
    const notification = new Notification(notificationTitle, notificationOptions);

    // Add click event listener to redirect to dashboard
    notification.onclick = () => {
      redirectToDashboard();
      notification.close(); // Close the notification
    };
  }
};

const redirectToDashboard = () => {
  // Replace with the actual URL of your dashboard
  const dashboardURL = '/dashboard';

  // Redirect to the dashboard
  window.location.href = dashboardURL;
};