import React, { useEffect } from 'react'
import { PRIVACY_POLICY } from '../../config/routeConfig'
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { CommonSlices } from '../../pages/common/commonSlice';
import { fetchWebVersion } from '../../services/commonServices';

const WebFooter = () => {
    const dispatch = useAppDispatch();
    const { version } = useAppSelector(CommonSlices);

    useEffect(() => {
        dispatch(fetchWebVersion());
    }, []);

    return (
        <>
            <footer className=' text-[var(--color-212121)] border-t border-[var(--color-e9e3e3)] text-[15px]  flex items-center justify-center w-full py-[37px] px-[37px] lg:px-[64px] '>
                <div className='pr-6  py-2 border-r border-[var(--color-BDBDBD)]'>© 2024 CFP Care Connect. All rights reserved. 
                Ver {version}
                </div>
                <div className='pl-6 py-2 hover:underline hover:text-[var(--color-0048D4)]'><a href={PRIVACY_POLICY}>Privacy Policy</a></div>
            </footer>
        </>
    )
}

export default React.memo(WebFooter);