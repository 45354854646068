import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post, postFormData, put } from "./apiService";
import { URL_ADD_NURSING, URL_APPROVE_NURSING, URL_EDIT_NURSING, URL_NURSING_LIST, URL_REMOVE_IMAGE, URL_RESENT_MAIL, URL_STATUS_NURSING, URL_UPDATE_NURSING, URL_UPLOAD_IMAGE } from "../config/apiUrls";
import { INursingStaffData, INursingStaffState, INursingStaffStatus } from "../models/nursingStaffListInterfaces";
import { IImageInput, IRemoveImage, IResentEmail } from "../models/commonInterfaces";


// to fetch all Facility staff
export const fetchNursingStaffList = createAsyncThunk(
  'user/fetchNursingStaffList',
  async (form: INursingStaffState, thunkAPI) => {
    try {
      const {  page, ...body } = form;
      let url = URL_NURSING_LIST;
      if (page !== 0) {
        url = url + '?page=' + page
      }
      const response = post(url, body);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// to resend verification mail  
export const resentVerificationNursingStaffMail = createAsyncThunk(
  'user/resentVerificationNursingStaffMail',
  async (form: IResentEmail, thunkAPI) => {
    try {
      const response = post(URL_RESENT_MAIL, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//to add Facility staff
export const addNursingStaffData = createAsyncThunk(
  'user/addNursingStaffData',
  async (form: INursingStaffData, thunkAPI) => {
    try {
      const response = post(URL_ADD_NURSING, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//to update Facility staff user
export const updateNursingStaffData = createAsyncThunk(
  'user/updateNursingStaffData',
  async (form: INursingStaffData, thunkAPI) => {
    try {
      const { uuid, ...body } = form;
      const url = URL_UPDATE_NURSING + uuid;
      const response = put(url, body);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// to get singal Facility staff user details
export const editNursingStaffData = createAsyncThunk(
  'user/editNursingStaffData',
  async (form: INursingStaffState, thunkAPI) => {
    try {
      const {  uuid,  ...body } = form;
      const url = URL_EDIT_NURSING + uuid;
      const response = get(url, body);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//to update status on NursingStaff list
export const updateNursingStaffUserStatus = createAsyncThunk(
  'user/updateNursingStaffUserStatus',
  async (form: INursingStaffStatus, thunkAPI) => {
    try {
      const response = put(URL_STATUS_NURSING, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//to Approvestatus on NursingStaff user
export const nursingStaffUserApprove = createAsyncThunk(
  'user/nursingStaffUserApprove',
  async (form: INursingStaffStatus, thunkAPI) => {
    try {
      const response = put(URL_APPROVE_NURSING, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const addNursingStaffImageInput = createAsyncThunk(
  'user/addnursingStaffImageInput',
  async (form: IImageInput, thunkAPI) => {
    try {
      const response = postFormData(URL_UPLOAD_IMAGE, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const removeNursingStaffImageInput = createAsyncThunk(
  'user/removenursingStaffImageInput',
  async (form: IRemoveImage, thunkAPI) => {
    try {
      const response = post(URL_REMOVE_IMAGE, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);