import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { setNotification } from "../../components/notification/ToastNotification";
import { message } from "../../utilities/locals/messages";
import { addProviderData, addProviderImageInput, addProviderSignInput, editProviderData, fetchProviderList, removeProviderImageInput, removeProviderSignInput, resentVerificationProviderMail, updateProviderData, updateProviderUserStatus } from "../../services/providerServices";
import { IProviderListData, IProviderSingleData } from "../../models/providerListInterfaces";
import { EMAIL_ALREADY_EXIST, MOBILE_ALREADY_EXIST } from "../../config/permissions";

export interface ProviderListState {
    loading: 'idle' | 'loading' | 'success' | 'fetchSuccessfully' |'logoUploaded'| 'error';
    providerUserListData: IProviderListData[]
    providerUser: IProviderSingleData[];
    paginationTotalCount:number; 
    uploadedImg: string;
    imageId: number | null;
    uploadedSignImg: string;
    imageSignId: number | null;
}

const initialState: ProviderListState = {
    loading: 'idle',
    providerUserListData: [],
    providerUser: [],    
    paginationTotalCount:0,
    uploadedImg:'',
    imageId:null,    
    uploadedSignImg: '',
    imageSignId: null,
}

export const providerListSlice = createSlice({
    name: "providerListSlice",
    initialState,
    reducers: {
        changeProviderLoading: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(fetchProviderList.pending, (state) => {
            state.paginationTotalCount = 0;
            state.providerUserListData = [];
            state.loading = 'loading';
        });
        builder.addCase(fetchProviderList.fulfilled, (state, action) => {
            state.paginationTotalCount = action.payload.count ;
            state.providerUserListData = action.payload.results as IProviderListData[];
            state.loading = 'fetchSuccessfully';
        });
        builder.addCase(fetchProviderList.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(addProviderData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(addProviderData.fulfilled, (state) => {
            state.loading = 'success';
            setNotification(message.CFPCareConnect, message.providerAddedSuccessfully, false, 'success', 'addProviderData');

        });
        builder.addCase(addProviderData.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            if (payload.response.data.error_message === EMAIL_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.emailExist, false, 'failed', 'addProviderData');
            } else if (payload.response.data.error_message === MOBILE_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.mobileNumberExist, false, 'failed', 'addProviderData');
            } else {
                setNotification(message.CFPCareConnect, message.providerAddedfailed, false, 'failed', 'addProviderData');
            }

        });

        builder.addCase(updateProviderData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(updateProviderData.fulfilled, (state) => {
            state.loading = 'success';
            setNotification(message.CFPCareConnect, message.providerUpdatedSuccessfully, false, 'success', 'updateProviderData');

        });
        builder.addCase(updateProviderData.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            if (payload.response.data.error_message === EMAIL_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.emailExist, false, 'failed', 'updateProviderData');
            } else{
                setNotification(message.CFPCareConnect, message.providerUpdatedfailed, false, 'failed', 'updateProviderData');
            }

        });

        builder.addCase(editProviderData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(editProviderData.fulfilled, (state, action) => {
            state.loading = 'fetchSuccessfully';
            state.providerUser = [action.payload] as IProviderSingleData[];
        });
        builder.addCase(editProviderData.rejected, (state) => {
            state.loading = 'error';
        });

        
        builder.addCase(resentVerificationProviderMail.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(resentVerificationProviderMail.fulfilled, (state) => {
            state.loading = 'fetchSuccessfully';
            setNotification(message.CFPCareConnect, message.verificationEmailSuccessfully, false, 'success', 'resentVerificationProviderMail');
        });
        builder.addCase(resentVerificationProviderMail.rejected, (state) => {
            state.loading = 'error';
            setNotification(message.CFPCareConnect, message.verificationEmailFailed, false, 'failed', 'resentVerificationProviderMail');

        });

        builder.addCase(updateProviderUserStatus.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(updateProviderUserStatus.fulfilled, (state, action) => {
            state.loading = 'success';
            const m = action.payload.message;
            setNotification(message.CFPCareConnect, m, false, 'success', 'updateProviderUserStatus');
        });
        builder.addCase(updateProviderUserStatus.rejected, (state) => {
            state.loading = 'error';
            setNotification(message.CFPCareConnect, message.updationFailed, false, 'failed', 'updateProviderUserStatus');

        });


        builder.addCase(addProviderImageInput.pending, (state) => {
            state.uploadedImg = '';
            state.imageId = null;
            state.loading = 'idle';
        });

        builder.addCase(addProviderImageInput.fulfilled, (state, action) => {
            state.uploadedImg = action.payload.profile_pic;
            state.imageId = action.payload.id;
            state.loading = 'logoUploaded';
        });
        builder.addCase(addProviderImageInput.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(removeProviderImageInput.pending, (state) => {
            state.loading = 'idle';
        });

        builder.addCase(removeProviderImageInput.fulfilled, (state) => {
            state.uploadedImg = '';
            state.imageId = null;
            state.loading = 'logoUploaded';
        });
        builder.addCase(removeProviderImageInput.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(addProviderSignInput.pending, (state) => {
            state.uploadedSignImg = '';
            state.imageSignId = null;
            state.loading = 'idle';
        });

        builder.addCase(addProviderSignInput.fulfilled, (state, action) => {
            state.uploadedSignImg = action.payload.sign_pic;
            state.imageSignId = action.payload.id;
            state.loading = 'logoUploaded';
        });
        builder.addCase(addProviderSignInput.rejected, (state) => {
            state.loading = 'error';
        });

        
        builder.addCase(removeProviderSignInput.pending, (state) => {
            state.loading = 'idle';
        });

        builder.addCase(removeProviderSignInput.fulfilled, (state) => {
            state.uploadedSignImg = '';
            state.imageSignId = null;
            state.loading = 'logoUploaded';
        });
        builder.addCase(removeProviderSignInput.rejected, (state) => {
            state.loading = 'error';
        });
    }
});

export const { changeProviderLoading } = providerListSlice.actions;
export const ProviderListSlices = (state: RootState) => state.providerListData;
export const providerListReducer = providerListSlice.reducer;