import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { INotificationState } from "../../models/notificationListInterfaces";
import { fetchNotification, fetchNotificationList, handleMarkNotification, markNotificationAllRead, setFirebaseToken } from "../../services/notificationListServices";


export interface  notificationListState {
    loading: 'idle' | 'loading' | 'success'|'fetchSuccessfully'|'listLoading' |'showCurrentNotificationSuccess'| 'error';
    notificationData: INotificationState[];
    selectedmail:number;
    paginationTotalCount:number;
    newNotification:boolean;
    markAsRead: number;
}

const initialState:  notificationListState = {
    loading: 'idle',
    notificationData: [],
    selectedmail:0,
    paginationTotalCount:0,
    markAsRead: 0,
    newNotification:false,
}

export const notificationListSlice = createSlice({
    name: 'notificationListSlice',
    initialState,
    reducers: {
        changeLoader: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        },
        setSelectedEmail: (state, action: PayloadAction<any>) => {
            state.selectedmail = action.payload;
        },
        setNewNotification: (state, action: PayloadAction<any>) => {
            state.newNotification = action.payload;
        }
    },
    extraReducers: (builder) => {


        builder.addCase(setFirebaseToken.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(setFirebaseToken.fulfilled, (state) => {
            state.loading = 'success';
        });

        builder.addCase(setFirebaseToken.rejected, (state) => {
            state.loading = 'error';
        });


        builder.addCase(fetchNotification.pending, (state) => {
            state.loading = 'success';
        });
        builder.addCase(fetchNotification.fulfilled, (state, action) => {
            state.notificationData = action.payload.results as INotificationState[];
            state.loading = 'success';
        });

        builder.addCase(fetchNotification.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(fetchNotificationList.pending, (state) => {
            state.paginationTotalCount = 0;
            state.loading = 'listLoading';
        });
        builder.addCase(fetchNotificationList.fulfilled, (state, action) => {
            state.paginationTotalCount = action.payload.count ;
            state.notificationData = action.payload.results as INotificationState[];
            state.loading = 'success';
        });

        builder.addCase(fetchNotificationList.rejected, (state) => {
            state.loading = 'error';
        });



        builder.addCase(markNotificationAllRead.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(markNotificationAllRead.fulfilled, (state) => {
            state.loading = 'showCurrentNotificationSuccess';
        });

        builder.addCase(markNotificationAllRead.rejected, (state) => {
            state.loading = 'error';
        });


        builder.addCase(handleMarkNotification.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(handleMarkNotification.fulfilled, (state) => {
            state.markAsRead = state.markAsRead + 1;
            state.loading = 'fetchSuccessfully';
        });
        builder.addCase(handleMarkNotification.rejected, (state) => {
            state.loading = 'error';
        });
    }
});

// Action creators are generated for each case reducer function
export const { changeLoader,setSelectedEmail ,setNewNotification} = notificationListSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const NotificationListSlices = (state: RootState) => state.notificationListData;

export const  notificationListReducer = notificationListSlice.reducer;