import React, { useState, useEffect, useRef, ChangeEvent } from 'react';

interface SelectFieldOption {
  id: string;
  name: string;
}

interface SelectFieldProps {
  type?: "inputType";
  name?: string;
  id?: string;
  className?: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  onClick?: (event: ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
  required?: boolean;
  search?: boolean;
  labelLeft?: boolean;
  options?: SelectFieldOption[];
  placeHolder?: boolean;
  label?: string;
  selLength?: number; 
}

const SelectField: React.FC<SelectFieldProps> = React.memo((props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState(props.options && props.options[0]?.name);
  const selectRef = useRef<HTMLSelectElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  // Effect to close the select dropdown when clicking outside of it
  useEffect(() => {
    const closeSelects = (e: MouseEvent) => {
      if (!e.target || !(e.target instanceof Node)) return;
      if (!wrapperRef.current?.contains(e.target as Node)) {
        setIsOpen(false);
        setSearch('');
      }
    };

    document.addEventListener('mousedown', closeSelects);

    return () => {
      document.removeEventListener('mousedown', closeSelects);
    };
  }, []);

  useEffect(() => {
    if (props.value) {
      props.options?.find(item => Number(item.id) === Number(props.value) && setSelectedOption(item.name));
    } else if (props.value === "") {
      setSelectedOption(props.options && props.options[0]?.name)
    }
  }, [props.value])



  const handleSelectClick = (e:any) => {
    if (props.onClick) {
      props.onClick(e);
    }
    setIsOpen(!isOpen);
  };

  // Function to handle click on an option in the dropdown
  const handleOptionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setSearch('');

    const clickedOption = e.currentTarget.textContent || (props.options && props.options[0].name);
    setSelectedOption(clickedOption);
    setIsOpen(false);
    if (selectRef.current) {
      const options = Array.from(selectRef.current.options);
      const index = options.findIndex((option) => option.textContent === clickedOption);
      if (index >= 0) {
        selectRef.current.selectedIndex = index;
      }
    }
    if (props.onChange) {
      const event = new Event('change', { bubbles: true });
      selectRef.current?.dispatchEvent(event);
    }
  };


  return (
    <div className={`${props.labelLeft ? ' gap-3 items-center' : ' flex-col gap-1 '} flex  w-full`}>
      {props.label && props.label?.length > 0 && <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px] '>
        {props.label} {props.required && <span className='text-[var(--color-red)]'>*</span>}
      </span>}
      <div className="relative" style={{ width: '100%' }} ref={wrapperRef}>
        <div className={(props.className) + ` z-2  p-3  select-selected flex  ${(props.placeHolder && selectedOption === (props.options && props.options[0]?.name) ? ' text-[var(--color-9E9E9E)] ' : isOpen ? 'select-arrow-active' : ' text-[var(--color-212121)] ')}`} onClick={(e) => handleSelectClick(e)}>
          {props.search && search.length > 0 ? 
            search : 
            (selectedOption && props?.selLength && props.selLength > 0 && selectedOption.length > props.selLength  ? 
              (selectedOption.substring(0, props.selLength)+" ...") : 
              selectedOption)
          }
          {(props.search) &&
            <input type="text"
              name="" id=""
              value={search}
              disabled={props.disabled}
              onChange={(e) => {
                setSearch(e.target.value); if (((e.target.value).length > 0) && props.search) {
                  setIsOpen(true)
                }
              }}
              className='focus:outline-none focus:bg-[var(--color-white)] bg-transparent text-[var(--color-212121)] absolute  w-5/6' />
          }
        </div>
        {!props.disabled &&
          <div className={`select-items custom-scrollBar ${isOpen ? '' : ' hidden '}`}>
            {props.options?.filter(option =>
              option.name.toLowerCase().includes(search.toLowerCase())
            ).map((option, index) => (
              <div
                key={option.id}
                onClick={handleOptionClick}
                className={selectedOption === option.name ? 'same-as-selected' : ((props.placeHolder && index === 0 && option.id === '') ? ' text-[var(--color-9E9E9E)] ' : ' text-[var(--color-212121)] ')}
              >
                {option.name}
              </div>
            ))}
          </div>}
        <select
          required={props.required}
          // style={{ display: 'none' }}
          className='absolute bottom-0 left-1 -z-10 min-w-full opacity-0 !improtant'
          ref={selectRef}
          name={props.name}
          id={props.id}
          value={props.value}
          // defaultValue={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
        >
          {props.placeHolder && <option value=""
            className={(props.placeHolder && selectedOption === (props.options && props.options[0]?.name) ? ' text-[var(--color-9E9E9E)] opacity-0 !improtant ' : ' text-[var(--color-212121)] opacity-0 !improtant ')}>{props.options && props.options[0]?.name}</option>}
          {props.options?.map((option) => (
            <option key={option.id} value={option.id} className='opacity-0 !improtant'>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
});

export default SelectField;
