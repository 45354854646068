import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_PWD_RESET_SUBMIT } from "../config/apiUrls";
import { IResetPassword } from "../models/resetPasswordInterfaces";
import {  putWithoutAuth } from "./apiService";

//FUNCTION TO RESET PASSWORD WITHOUT AUTHENTICATION
  export const resetPasswordAction = createAsyncThunk(
    'client/resetPasswordAction',
    async (form: IResetPassword, thunkAPI) => {
      try {
        const response = putWithoutAuth(URL_PWD_RESET_SUBMIT, form);
        return (await response).data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  );