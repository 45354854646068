
// API SERVICE FUNCTIONS 


// FUNCTION TO RETRIEVE HEADER WITH ACCESS TOKEN FOR API 
export const getHeaderInfo = async function (hearderData: any) {
 
  return {
    headers: {
      'Content-Type': 'application/json',
      unid: hearderData.unid,
      Authorization: `Bearer ${hearderData.token}`,
    },
  };
};

// FUNCTION TO RETRIEVE HEADER WITH UNID FOR API 
export const getHeaderInfoWithOutToken = async function (unid: string) {  
  return {
    headers: {
      'Content-Type': 'application/json',
      unid: unid,
    },
  };
};

// FUNCTION TO RETRIEVE HEADER WITH ACCESS TOKEN FOR API 
export const getFormDataHeaderWithOutToken = async function () {
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
};

// FUNCTION TO RETRIEVE HEADER WITH ACCESS TOKEN FOR API 
export const getFormDataHeader = async function (hearderData: any) {
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      unid: hearderData.unid,
      Authorization: `Bearer ${hearderData.token}`,
    },
  };
};


